import {
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';
import React from 'react';
import { FeatherIcon } from '../../../components/FeatherIcon';

export type ActionMenuItem = MenuItemProps & { key: string };

export const ActionMenu = (props: {
  open: boolean;
  setOpen: (newValue: boolean) => void;
  items: ActionMenuItem[];
}) => {
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    props.setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      props.setOpen(false);
    } else if (event.key === 'Escape') {
      props.setOpen(false);
    }
  }

  return (
    <div>
      <IconButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={props.open ? 'composition-menu' : undefined}
        aria-expanded={props.open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={(evt) => {
          evt.stopPropagation();
          props.setOpen(!props.open);
        }}
      >
        <FeatherIcon name="more-vertical" />
      </IconButton>
      <Popper
        open={props.open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={props.open}
                  id="composition-menu"
                  aria-labelledby="composition-button"
                  onKeyDown={(key) => handleListKeyDown(key)}
                >
                  {props.items.map((item) => (
                    <MenuItem {...item} key={item.key} />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
