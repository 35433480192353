import * as React from 'react';

import { Box, Button, Typography } from '@mui/material';
import { LARGE_SPACING } from '../../../theme';
import { FeatherIcon, IconSize } from '../../../components/FeatherIcon';
import { activityTypeToIcon } from '../../../components/ActivityChip';
import { useBreakpoints } from '../../../hooks/useBreakpoints';

type Props = {
  setActivityType?: (type: string) => void;
  activityType: string;
};

const Tab = ({
  activityType,
  selected,
  clickFn,
  disabled,
}: {
  activityType: string;
  selected: boolean;
  clickFn: () => void;
  disabled: boolean;
}) => {
  const { isPhone } = useBreakpoints();
  return (
    <Button
      onClick={clickFn}
      variant="text"
      disabled={selected || disabled}
      style={{
        border: '1px solid #245F73',
        borderRadius: '14px 14px 0px 0px',
        height: '50px',
        maxWidth: '100%',
        minWidth: '0%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textTransform: 'none',
        padding: '20px',
        background: selected ? '#F1F4F6' : '#91AEB8',
        borderBottom: selected ? '4px solid #DAAC56' : '0px',
      }}
    >
      <FeatherIcon
        size={IconSize.SMALL}
        name={activityTypeToIcon(activityType)}
        color="#172B4D"
      />
      {!isPhone && (
        <Typography
          style={{ marginLeft: '0.2rem', color: '#172B4D', fontSize: '18px' }}
          variant="caption"
        >
          {activityType.charAt(0) + activityType.substring(1).toLowerCase()}
        </Typography>
      )}
    </Button>
  );
};

export const ActivityTypeSelection = ({
  setActivityType,
  activityType,
}: Props) => (
  <Box style={{ display: 'flex' }} sx={{ mx: LARGE_SPACING }}>
    <Tab
      activityType="FUNCTIONAL"
      selected={activityType === 'FUNCTIONAL'}
      disabled={!activityType}
      clickFn={() => {
        if (setActivityType) {
          setActivityType('FUNCTIONAL');
        }
      }}
    />
    <Tab
      disabled={!activityType}
      activityType="BOOK"
      selected={activityType === 'BOOK'}
      clickFn={() => {
        if (setActivityType) {
          setActivityType('BOOK');
        }
      }}
    />
    <Tab
      disabled={!activityType}
      activityType="LINK"
      selected={activityType === 'LINK'}
      clickFn={() => {
        if (setActivityType) {
          setActivityType('LINK');
        }
      }}
    />
  </Box>
);
