import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { QUERY_CONFIG } from '../utils/queryconfig';
import { TOPICS_QUERY_KEY, getTopics } from '../api/topicapi';

type TopicMatchingCriteria = {
  topicId?: string;
  year?: number;
  lessonNumber?: number;
  subject?: string;
  groupId?: string;
};

const topicMatcher = (topic: any, filters: TopicMatchingCriteria) => {
  const { topicId, year, lessonNumber, subject, groupId } = filters;

  return (
    (topicId === undefined ? true : topic.topicId === topicId) &&
    (year === undefined ? true : topic.year === year) &&
    (lessonNumber === undefined ? true : topic.lessonNumber === lessonNumber) &&
    (groupId === undefined ? true : topic.groupId === groupId) &&
    (subject === undefined
      ? true
      : topic?.tasks?.some((task: any) => task.subjectId === subject))
  );
};

export const useTopics = (groupId?: string) => {
  const {
    data: topics,
    isError,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: [
      TOPICS_QUERY_KEY,
      {
        groupId,
      },
    ],
    queryFn: () => getTopics(groupId!!),
    ...QUERY_CONFIG,
    enabled: !!groupId,
  });

  return useMemo(() => {
    const findMatchingTopic = (params: TopicMatchingCriteria) =>
      topics?.find((topic: any) => topicMatcher(topic, params));

    return { topics, isError, isFetching, refetch, findMatchingTopic };
  }, [topics]);
};
