import { useMediaQuery, useTheme } from '@mui/material';

export const useBreakpoints = () => {
  const extraSmall = useMediaQuery(useTheme().breakpoints.only('xs'));
  const small = useMediaQuery(useTheme().breakpoints.only('sm'));
  const medium = useMediaQuery(useTheme().breakpoints.only('md'));

  const isPhone = extraSmall;
  const isTablet = small || medium;
  const isDesktop = !isPhone && !isTablet;

  return {
    isPhone,
    isTablet,
    isDesktop,
    useHamburgerNavigation: isPhone,
    useNarrowUiElements: !isDesktop,
    usePanels: medium || isDesktop,
    useStickyHeaders: isPhone,
  };
};
