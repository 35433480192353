import debug from 'debug';

const BASE = 'curriculum';
enum LevelType {
  TRACE = 'lightblue',
  info = 'blue',
  warn = 'pink',
  ERROR = 'red',
}

type BasicTypes = string | number | boolean | object | null | undefined;

export const Log = {
  generateMessage(level: LevelType, message: BasicTypes, source: string) {
    const namespace = `${BASE}:${level}`;
    const createDebug = debug(namespace);
    createDebug.color = level;

    if (source) {
      createDebug(source, message);
    } else {
      createDebug(message);
    }
  },

  trace(message: BasicTypes, source: string) {
    return this.generateMessage(LevelType.TRACE, message, source);
  },

  info(message: BasicTypes, source: string) {
    return this.generateMessage(LevelType.info, message, source);
  },

  warn(message: BasicTypes, source: string) {
    return this.generateMessage(LevelType.warn, message, source);
  },

  error(message: BasicTypes, source: string) {
    return this.generateMessage(LevelType.ERROR, message, source);
  },
};
