import * as React from 'react';
import { Box, Card, Grid } from '@mui/material';
import { ProfileAvatar } from './ProfileAvatar';
import { ProfileInformation } from './ProfileInformation';
import { useAuth } from '../../utils/auth/useAuth';
import { ProfileGroups } from './ProfileGroups';

export const ProfileEditor = ({ forwardRef }: { forwardRef: any }) => {
  const { user } = useAuth();
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <ProfileAvatar forwardRef={forwardRef} name={user?.name} />
        </Grid>
        <Grid item xs={12}>
          <ProfileInformation
            firstName={user?.firstName}
            lastName={user?.lastName}
            email={user?.email}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <></>
        </Grid>
        <Grid item xs={12}>
          <ProfileGroups isTeacher={user?.role === 'TEACHER'} />
        </Grid>
      </Grid>
    </Box>
  );
};
