import React from 'react';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import { UserTable } from './UserTable';
import { InvitedTeachersTable } from './InvitedTeachersTable';
import { LARGE_SPACING, XXL_SPACING } from '../../theme';
import { OpsTable } from './OpsTable';

export const AdminPage = () => (
  <Box sx={{ p: XXL_SPACING }}>
    <Typography variant="h4">Admin panel</Typography>
    <Stack gap="30px" sx={{ mt: LARGE_SPACING }}>
      <InvitedTeachersTable />
      <OpsTable />
      <UserTable />
    </Stack>
  </Box>
);
