import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { getUserLang, updateUserLang } from '../api/usersapi';
import { locale } from '../App/state';
import { THEME_MAIN_COLOR } from '../theme';
import { LocaleEnum } from '../utils/locale';
import { QUERY_CONFIG } from '../utils/queryconfig';

type Props = {
  close: () => void;
};

const stringToLocaleEnum = (lc: string | unknown): LocaleEnum =>
  (lc as string).toLowerCase() as LocaleEnum;

export const LocaleSelection = ({ close }: Props) => {
  const queryClient = useQueryClient();
  const [_, setLocale] = useRecoilState(locale);

  const userLang = useQuery({
    queryKey: ['userlang'],
    queryFn: () => getUserLang(),
    onSuccess: ({ lang }: { lang?: string }) => {
      if (lang && Object.values(LocaleEnum).some((val) => val === lang)) {
        setLocale(stringToLocaleEnum(lang));
      }
    },
    ...QUERY_CONFIG,
  });

  const { mutate: apiUpdateUserLang } = useMutation(updateUserLang, {
    onSuccess: () => {
      queryClient.invalidateQueries(['userlang']);
      close();
    },
  });
  return (
    <>
      {Object.entries(LocaleEnum).map((entry) => (
        <Button
          style={{
            backgroundColor:
              userLang?.data?.lang === entry[1] ? THEME_MAIN_COLOR : undefined,
          }}
          key={entry[1]}
          onClick={() => apiUpdateUserLang(stringToLocaleEnum(entry[1]))}
          fullWidth
        >
          <Typography
            style={{
              paddingLeft: '8px',
              color: userLang?.data?.lang === entry[1] ? '#fff' : 'black',
            }}
            variant="body2"
          >
            {entry[0]}
          </Typography>
        </Button>
      ))}
    </>
  );
};
