/* eslint-disable react/no-array-index-key */
import { Button, Typography } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';
import {
  addDays,
  format,
  isAfter,
  isBefore,
  isSameDay,
  parseISO,
  startOfWeek,
  subDays,
} from 'date-fns';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import {
  createSubjectTiming,
  deleteSubjectTiming,
  deleteTimetable,
  SubjectTiming,
  TimeTable,
  updateSubjectTiming,
} from '../../../api/timetablesapi';
import { BackAndNextButtons } from '../BackAndNextButtons';
import { MAIN_BORDER } from './consts';
import { Day } from './Day';
import { FavoriteSubjects } from './FavoriteSubjects';
import { FromToSelector } from './FromToSelector';
import { DaySubject } from './interfaces';
import { dateToTime, timeToDate, timestampWithoutTimezone } from './utils';
import { VisualHourItem } from './VisualHourItem';

const getWeekFrom = (date: Date): Date[] => [
  date,
  addDays(date, 1),
  addDays(date, 2),
  addDays(date, 3),
  addDays(date, 4),
];

const getFirstWeek = (fromDate: string): Date[] => {
  const start = startOfWeek(parseISO(fromDate), { weekStartsOn: 1 });
  return getWeekFrom(start);
};

const subjectTimingToDaySubject = (
  subjectTiming: SubjectTiming
): DaySubject => ({
  subject: `${subjectTiming.subject}-${subjectTiming.grade}`,
  from: dateToTime(parseISO(subjectTiming.startAt)),
  to: dateToTime(parseISO(subjectTiming.endAt)),
  subjectTimingId: subjectTiming.subjectTimingId,
});

const daySubjectToSubjectTiming = (
  daySubject: DaySubject,
  date: Date
): SubjectTiming => ({
  subject: daySubject.subject.split('-')[0],
  grade: daySubject.subject.split('-')[1],
  startAt: timestampWithoutTimezone(timeToDate(daySubject.from, date)),
  endAt: timestampWithoutTimezone(timeToDate(daySubject.to, date)),
  subjectTimingId: daySubject.subjectTimingId,
});

export const TimetableEditor = ({
  favoriteSubjects,
  previousPhase,
  timetable,
  saveTimetable,
  resetSelectedTimetableId,
}: {
  favoriteSubjects: string[];
  previousPhase: () => void;
  timetable: TimeTable;
  saveTimetable: (t: TimeTable) => void;
  resetSelectedTimetableId: () => void;
}) => {
  const [from, setFrom] = useState(parseISO(timetable.from));
  const [to, setTo] = useState(parseISO(timetable.to));
  const [subjectTimings, setSubjectTimings] = useState(timetable.times);
  const [selectedWeek, setSelectedWeek] = useState<Date[]>(
    getFirstWeek(timetable.from)
  );
  React.useEffect(() => {
    setFrom(parseISO(timetable.from));
    setTo(parseISO(timetable.to));
    setSubjectTimings(timetable.times);
  }, [timetable]);

  const queryClient = useQueryClient();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const deleteTimeTableMutation = useMutation(deleteTimetable);
  const deleteSubjectTimingMutation = useMutation(deleteSubjectTiming);
  const postSubjectTimingMutation = useMutation(createSubjectTiming);
  const putSubjectTimingMutation = useMutation(updateSubjectTiming);

  const hours = [
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
  ];

  return (
    <>
      <FromToSelector
        from={from}
        to={to}
        setFrom={(d: Date) => setFrom(d)}
        setTo={(d: Date) => setTo(d)}
      />
      <FavoriteSubjects favoriteSubjects={favoriteSubjects} />
      <div style={{ display: 'flex' }}>
        <div>
          <div style={{ width: '75px', position: 'relative' }}>
            <div
              style={{
                width: '75px',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'end',
                borderBottom: MAIN_BORDER,
                height: '80px',
                top: '0px',
              }}
            >
              <ArrowBackIos
                style={{
                  position: 'relative',
                  bottom: '35px',
                  cursor: 'pointer',
                  display: 'block',
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setSelectedWeek(getWeekFrom(subDays(selectedWeek[0], 7)));
                }}
                fontSize="small"
              />
              <Typography variant="body2" style={{ marginLeft: '-20px' }}>
                08:00
              </Typography>
            </div>
            {hours.map((hour, index) => (
              <VisualHourItem
                hour={hour}
                key={hour + index}
                top={`${(index + 1) * 60 + 20}px`}
              />
            ))}
          </div>
        </div>
        {selectedWeek.map((selectedWeeksDate, index) => (
          <Day
            disabled={
              (!isSameDay(selectedWeeksDate, from) &&
                isBefore(selectedWeeksDate, from)) ||
              (!isSameDay(selectedWeeksDate, to) &&
                isAfter(selectedWeeksDate, to))
            }
            dayLabel={format(selectedWeeksDate, 'EEE').toUpperCase()}
            dayLabelForModal={format(selectedWeeksDate, 'EEEE d.M.yyyy')}
            ballLabel={format(selectedWeeksDate, 'd.M')}
            key={`${selectedWeeksDate.toISOString()} + ${uuidv4()}+editor+${index}`}
            subjects={subjectTimings
              .filter((time) =>
                isSameDay(parseISO(time.startAt), selectedWeeksDate)
              )
              .map((subjectTiming) => subjectTimingToDaySubject(subjectTiming))}
            setSubject={(subject: DaySubject) => {
              const subjectTiming = daySubjectToSubjectTiming(
                subject,
                selectedWeeksDate
              );
              if (subjectTiming?.subjectTimingId) {
                putSubjectTimingMutation
                  .mutateAsync({
                    ...subjectTiming,
                    timetableId: timetable.timetableId!!,
                  })
                  .then(() => {
                    queryClient.invalidateQueries();
                    enqueueSnackbar('Subject updated successfully', {
                      variant: 'success',
                    });
                  })
                  .catch(() => {
                    queryClient.invalidateQueries();
                    enqueueSnackbar('Subject update failed', {
                      variant: 'error',
                    });
                  });
              } else {
                postSubjectTimingMutation
                  .mutateAsync({
                    ...subjectTiming,
                    timetableId: timetable.timetableId!!,
                  })
                  .then(() => {
                    queryClient.invalidateQueries();
                    enqueueSnackbar('Subject added successfully', {
                      variant: 'success',
                    });
                  })
                  .catch(() => {
                    queryClient.invalidateQueries();
                    enqueueSnackbar('Subject adding failed', {
                      variant: 'error',
                    });
                  });
              }
            }}
            removeSubject={(subject: DaySubject) => {
              deleteSubjectTimingMutation
                .mutateAsync({
                  subjectTimingId: subject.subjectTimingId!!,
                  timetableId: timetable.timetableId!!,
                })
                .then(() => {
                  queryClient.invalidateQueries();
                  enqueueSnackbar('Subject deleted successfully', {
                    variant: 'success',
                  });
                })
                .catch(() => {
                  queryClient.invalidateQueries();
                  enqueueSnackbar('Subject deletion failed', {
                    variant: 'error',
                  });
                });
            }}
            forwardButtonClick={
              selectedWeek.indexOf(selectedWeeksDate) === 4
                ? (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    setSelectedWeek(getWeekFrom(addDays(selectedWeek[0], 7)));
                  }
                : undefined
            }
          />
        ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <BackAndNextButtons
          back={() => previousPhase()}
          next={() =>
            saveTimetable({
              ...timetable,
              from: from.toISOString(),
              to: to.toISOString(),
              times: subjectTimings,
            })
          }
          nextDisabled={false}
        />
        <div style={{ display: 'flex', marginTop: '20px' }}>
          <Button
            size="small"
            variant="contained"
            style={{ backgroundColor: 'red', color: '#FFFFFF' }}
            onClick={() =>
              confirm({
                title: 'Are you sure you want to delete?',
              })
                .then(() => {
                  deleteTimeTableMutation
                    .mutateAsync(timetable.timetableId!!)
                    .then(() => {
                      queryClient.invalidateQueries();
                      resetSelectedTimetableId();
                      enqueueSnackbar('Timetable deleted successfully', {
                        variant: 'success',
                      });
                    })
                    .catch(() => {
                      queryClient.invalidateQueries();
                      enqueueSnackbar('Timetable deletion failed', {
                        variant: 'error',
                      });
                    });
                })
                .catch(() => {})
            }
          >
            Delete
          </Button>
        </div>
      </div>
    </>
  );
};
