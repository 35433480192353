import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
  TIMETABLES_QUERY_KEY,
  getAllTimeTables,
  TimeTable,
} from '../api/timetablesapi';
import { QUERY_CONFIG } from '../utils/queryconfig';

type SubjectTimingMatchingCriteria = {
  year?: number;
  lessonNumber?: number;
  grade: string;
  subject: string;
};

const subjectTimingMatcher = (
  subjectTiming: any,
  filters: SubjectTimingMatchingCriteria
) => {
  const { year: schoolYear, lessonNumber, grade, subject } = filters;

  return (
    (schoolYear === undefined
      ? true
      : subjectTiming.schoolYear === schoolYear) &&
    (lessonNumber === undefined
      ? true
      : subjectTiming.lessonNumber === lessonNumber) &&
    (grade === undefined ? true : subjectTiming.grade === grade) &&
    (subject === undefined ? true : subjectTiming.subject === subject)
  );
};

export const useTimetables = (groupId?: string | string[]) => {
  const {
    data: timetables,
    isFetching,
    isError,
  } = useQuery({
    queryKey: [
      TIMETABLES_QUERY_KEY,
      {
        groupId,
      },
    ],
    queryFn: () => getAllTimeTables(groupId),
    ...QUERY_CONFIG,
    enabled: !!groupId,
  });

  return useMemo(() => {
    const findMatchingTimetable = (params: SubjectTimingMatchingCriteria) =>
      timetables
        ?.flatMap((t: TimeTable) => t.times)
        .find((timetable: any) => subjectTimingMatcher(timetable, params));

    return { timetables, isFetching, isError, findMatchingTimetable };
  }, [timetables]);
};
