import React, { ReactNode, useState } from 'react';
import { Stack, Box } from '@mui/material';
import CSS from 'csstype';
import { DRAWER as Z_INDEX } from '../utils/z-index';

type DrawerChildren = JSX.Element | ReactNode;

const drawerContainerShared: CSS.Properties = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: Z_INDEX,
};

const openDrawerContainer: CSS.Properties = {
  ...drawerContainerShared,
  top: 0,
};

const closedDrawerContainer: CSS.Properties = {
  ...drawerContainerShared,
  top: '85%',
  backgroundColor: 'white',
};

const puller = (
  <Box
    style={{
      backgroundColor: 'white',
      borderTopLeftRadius: '6px',
      borderTopRightRadius: '6px',
      boxShadow: '0 -3px 6px #0000000D',
      marginTop: '6px',
    }}
    sx={{ py: 2 }}
    height={20}
    justifyContent="center"
    alignItems="center"
  >
    <Box
      style={{
        width: '20%',
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 6,
        backgroundColor: 'lightgrey',
        borderRadius: 3,
      }}
    />
  </Box>
);

const OpenDrawer = (props: {
  children: DrawerChildren;
  closeDrawerCallback: () => void;
}) => (
  <Stack direction="column" style={openDrawerContainer}>
    <Stack
      onClick={props.closeDrawerCallback}
      height="20%"
      flexShrink={0}
      flexGrow={0}
      justifyContent="end"
    >
      {puller}
    </Stack>
    <Box
      flexGrow={1}
      style={{
        backgroundColor: 'white',
        overflowY: 'scroll',
      }}
    >
      {props.children}
    </Box>
  </Stack>
);

const ClosedDrawer = (props: {
  children: DrawerChildren;
  openDrawerCallback: () => void;
  teaser?: DrawerChildren;
}) => (
  <Stack
    direction="column"
    style={closedDrawerContainer}
    onClick={props.openDrawerCallback}
  >
    <Box flexShrink={0} flexGrow={0}>
      {puller}
    </Box>
    {props.teaser || <Box flexGrow={1}>{props.children}</Box>}
  </Stack>
);

export const Drawer = (props: {
  children: DrawerChildren;
  visible: boolean;
  onOpened: () => void; // eslint-disable-line
  onClosed: () => void; // eslint-disable-line
  teaser?: DrawerChildren;
}) => {
  const [open, setOpen] = useState(false);

  if (props.visible === false) {
    return null;
  }

  if (open) {
    return (
      <OpenDrawer closeDrawerCallback={() => setOpen(false)}>
        {props.children}
      </OpenDrawer>
    );
  }
  return (
    <ClosedDrawer
      openDrawerCallback={() => setOpen(true)}
      teaser={props.teaser}
    >
      {props.children}
    </ClosedDrawer>
  );
};
