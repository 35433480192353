import React from 'react';
/* eslint-disable no-console */
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useIntl } from 'react-intl';
import { Topic } from '../../../api/topicapi';
import { SubjectItem } from '../../ops/components/types/types';
import { ContentProgressBulletpointStats } from '../types';
import { ContentSelectorContent } from './ContentSelectorContent';
import { CommonFullScreenDialog } from '../../../components/CommonFullScreenDialog';
import { CenterLoader } from '../../../components/CenterLoader';
import { LessonPlanComponent } from '../lesson-plan/LessonPlanComponent';
import { SubjectTiming } from '../../../api/timetablesapi';
import { LessonHeader } from './LessonHeader';
import {
  BOLD_FONT_WEIGHT,
  EVEN_THICKER_NOT_SELECTED_DETAIL_BORDER,
  EVEN_THICKER_SELECTED_DETAIL_BORDER,
  LARGE_SPACING,
  LIGHT_BACKGROUND_COLOR,
} from '../../../theme';

const TabButton = ({
  click,
  translationId,
  selected,
  style,
}: {
  click: () => void;
  translationId: string;
  selected: boolean;
  style: any;
}) => {
  const intl = useIntl();
  return (
    <Button
      onClick={click}
      style={{
        textTransform: 'none',
        width: '100%',
        backgroundColor: selected ? '#fff' : LIGHT_BACKGROUND_COLOR,
        border: selected
          ? EVEN_THICKER_SELECTED_DETAIL_BORDER
          : EVEN_THICKER_NOT_SELECTED_DETAIL_BORDER,
        ...style,
      }}
      variant="outlined"
    >
      <Typography
        variant="body1"
        style={
          selected
            ? { fontWeight: BOLD_FONT_WEIGHT, color: '#182A4D' }
            : { color: '#182A4D' }
        }
      >
        {intl.formatMessage({ id: translationId })}
      </Typography>
    </Button>
  );
};

export const YearPlanDialog = ({
  subjectTiming,
  open,
  onClose,
  grade,
  subject,
  groupName,
  groupId,
  lessonNumber,
  amountOfLessons,
  year,
  bpStats,
  existingTopic,
  previousLesson,
  nextLesson,
  switchMode,
  changeTabToLessonPlan,
  changeTabToContent,
  loader,
  activeTab,
}: {
  subjectTiming: SubjectTiming;
  open: boolean;
  onClose: () => void;
  grade: string;
  subject: SubjectItem;
  groupName: string;
  groupId: string;
  amountOfLessons: number;
  lessonNumber: number;
  year: number;
  bpStats: ContentProgressBulletpointStats[];
  existingTopic?: Topic;
  previousLesson: () => void;
  nextLesson: () => void;
  switchMode: () => void;
  changeTabToLessonPlan: () => void;
  changeTabToContent: () => void;
  loader?: boolean;
  activeTab: 'Content' | 'Lesson';
}) => {
  const extraSmall = useMediaQuery(useTheme().breakpoints.only('xs'));
  const small = useMediaQuery(useTheme().breakpoints.only('sm'));
  const medium = useMediaQuery(useTheme().breakpoints.only('md'));

  const isPhone = extraSmall;
  const isTablet = small || medium;
  return (
    <CommonFullScreenDialog open={open} onClose={onClose}>
      <LessonHeader
        subject={subject}
        subjectTiming={subjectTiming}
        switchMode={switchMode}
        smallMode={false}
        amountOfLessons={amountOfLessons}
        nextLesson={nextLesson}
        previousLesson={previousLesson}
        lessonNumber={lessonNumber}
        groupId={groupId}
        groupName={groupName}
        year={year}
        grade={grade}
      >
        <Box
          style={{
            display: 'flex',
            // eslint-disable-next-line no-nested-ternary
            width: isPhone ? '100%' : isTablet ? '60%' : '40%',
          }}
          sx={{ mb: LARGE_SPACING }}
        >
          <TabButton
            selected={activeTab === 'Content'}
            click={changeTabToContent}
            translationId="yearplan.contentmodal.contenttab"
            style={{
              borderTopLeftRadius: '10px',
              borderBottomLeftRadius: '10px',
            }}
          />
          <TabButton
            selected={activeTab === 'Lesson'}
            click={changeTabToLessonPlan}
            translationId="yearplan.contentmodal.lessonplantab"
            style={{
              borderTopRightRadius: '10px',
              borderBottomRightRadius: '10px',
            }}
          />
        </Box>
      </LessonHeader>
      {loader && <CenterLoader />}
      {!loader && activeTab === 'Content' && (
        <ContentSelectorContent
          grade={grade}
          subject={subject}
          groupId={groupId}
          lessonNumber={lessonNumber}
          year={year}
          bpStats={bpStats}
          close={onClose}
          existingTopic={existingTopic}
        />
      )}
      {!loader && activeTab === 'Lesson' && (
        <LessonPlanComponent onClose={onClose} />
      )}
    </CommonFullScreenDialog>
  );
};
