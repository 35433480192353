/* eslint-disable no-console */
import { Box, Button } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import {
  postTopic,
  putTopic,
  Topic,
  TOPICS_QUERY_KEY,
} from '../../../api/topicapi';
import { SCHEDULE_API_KEY } from '../../../api/scheduleapi';
import {
  DONE_BUTTON_COLOR,
  LARGE_SPACING,
  XXL_SPACING,
  XXXL_SPACING,
} from '../../../theme';
import { parseNumber } from '../../../utils/string';
import { SubjectItem } from '../../ops/components/types/types';
import { ContentSelector } from './ContentSelector';
import { parseBulletpointId } from '../../../utils/ops/findBulletpointDescFromSubjects';
import { CONTENT_PROGRESS_API_QUERY_KEY } from '../../../api/contentprogressapi';
import { ContentProgressBulletpointStats } from '../types';
import {
  contentLoadingState,
  contentRedirectAfterSaveUrl,
  contentState,
} from '../../../App/state';
import { CONTENT_SELECTOR_CONTENT as Z_INDEX } from '../../../utils/z-index';

const Footer = ({
  onClose,
  onRedirect,
  redirectVisible,
}: {
  onClose: () => void;
  onRedirect: () => void;
  redirectVisible: boolean;
}) => (
  <Box
    style={{
      zIndex: Z_INDEX,
      backgroundColor: '#F2F3F5',
      position: 'sticky',
      bottom: 0,
      marginTop: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '72px',
      minHeight: '72px',
      boxShadow: '0px -3px 4px #00000017',
    }}
  >
    <Button
      style={{ height: '36px', borderRadius: '30px' }}
      onClick={onClose}
      sx={{ mr: XXL_SPACING }}
      variant="outlined"
    >
      Close
    </Button>
    {redirectVisible && (
      <Button
        style={{
          height: '36px',
          borderRadius: '30px',
          backgroundColor: DONE_BUTTON_COLOR,
        }}
        sx={{ mr: XXL_SPACING }}
        variant="contained"
        onClick={onRedirect}
      >
        Done
      </Button>
    )}
  </Box>
);

export const ContentSelectorContent = ({
  grade,
  subject,
  groupId,
  lessonNumber,
  year,
  bpStats,
  existingTopic,
  close,
  smallMode = false,
}: {
  grade: string;
  subject: SubjectItem;
  groupId: string;
  lessonNumber: number;
  year: number;
  bpStats: ContentProgressBulletpointStats[];
  existingTopic?: Topic;
  close: () => void;
  smallMode?: boolean;
}) => {
  const navigate = useNavigate();
  const existingBulletpointIds =
    existingTopic?.tasks?.map((task) => task.bulletpointId) ?? [];
  const [__, setContentViewState] = useRecoilState(contentState);
  const [selectedContentIds, setSelectedContentIds] = React.useState<string[]>([
    ...new Set(existingTopic?.tasks?.map((task) => task.contentId) ?? []),
  ]);
  const [selectedObjectiveIds, setSelectedObjectiveIds] = React.useState<
    string[]
  >([...new Set(existingTopic?.tasks?.map((task) => task.objectiveId) ?? [])]);
  const [redirectAfterSaveUrl, setRedirectAfterSaveUrl] = useRecoilState(
    contentRedirectAfterSaveUrl
  );
  const [contentLoading, setContentLoading] =
    useRecoilState(contentLoadingState);

  React.useEffect(() => {
    setSelectedContentIds([
      ...new Set(existingTopic?.tasks?.map((task) => task.contentId) ?? []),
    ]);
    setSelectedObjectiveIds([
      ...new Set(existingTopic?.tasks?.map((task) => task.objectiveId) ?? []),
    ]);
  }, [existingTopic?.topicId]);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutation(postTopic, {});
  const updateMutation = useMutation(putTopic, {});

  const invalidate = async () => {
    await queryClient.invalidateQueries({
      queryKey: [
        TOPICS_QUERY_KEY,
        {
          groupId,
        },
      ],
    });
    await queryClient.invalidateQueries({
      queryKey: [SCHEDULE_API_KEY],
    });
    await queryClient.invalidateQueries({
      queryKey: [
        CONTENT_PROGRESS_API_QUERY_KEY,
        {
          groupId,
        },
      ],
    });
  };
  const saveTopic = async (bulletpointIds: string[]) => {
    try {
      const topic = {
        name: 'Lesson Topic',
        description: 'not-used-currently',
        subjectId: subject.code,
        gradeId: grade,
        year,
        lessonNumber,
        groupId,
        tasks: bulletpointIds.map((t) => ({
          bulletpointId: t,
          subjectId: subject.code,
          gradeId: grade,
          ...parseBulletpointId(t),
        })),
        activityOrder: {
          initiation: [],
          practicing: [],
          closing: [],
          homework: [],
        },
      };
      if (existingTopic) {
        setContentViewState(
          (existingContentViewState) =>
            ({
              ...existingContentViewState,
              topic: {
                ...existingContentViewState!!.topic,
                tasks: bulletpointIds.map(
                  (bp) =>
                    existingContentViewState?.topic?.tasks.find(
                      (et) => bp === et.bulletpointId
                    ) ?? topic.tasks.find((t) => t.bulletpointId === bp)
                ),
              },
            } as any)
        );
        await updateMutation.mutateAsync({
          ...topic,
          topicId: existingTopic.topicId,
          activityOrder: existingTopic.activityOrder,
        });
      } else {
        setContentLoading(true);
        setContentViewState(
          (existingContentViewState) =>
            ({
              ...existingContentViewState,
              topic: {
                ...topic,
                tasks: bulletpointIds.map(
                  (bp) =>
                    existingContentViewState?.topic?.tasks.find(
                      (et) => bp === et.bulletpointId
                    ) ?? topic.tasks.find((t) => t.bulletpointId === bp)
                ),
                activityOrder: {
                  initiation: [],
                  practicing: [],
                  closing: [],
                  homework: [],
                },
              },
            } as any)
        );
        const createResponse = await mutation.mutateAsync(topic);
        setContentViewState(
          (existingContentViewState) =>
            ({
              ...existingContentViewState,
              topic: createResponse.topic,
            } as any)
        );
        setContentLoading(false);
      }
      enqueueSnackbar('Content saved successfully', {
        variant: 'success',
      });
      await invalidate();
      setContentLoading(false);
      return;
    } catch (error: any) {
      setContentLoading(false);
      enqueueSnackbar('Something went wrong when saving content', {
        variant: 'error',
      });
    }
  };
  return (
    <>
      <Box
        sx={{
          p: smallMode ? LARGE_SPACING : XXXL_SPACING,
          pt: smallMode ? LARGE_SPACING : '80px',
          overflowY: 'scroll',
        }}
      >
        <ContentSelector
          showLoader={contentLoading}
          smallMode={smallMode}
          selectedContentIds={selectedContentIds}
          setSelectedContentIds={setSelectedContentIds}
          setSelectedObjectiveIds={setSelectedObjectiveIds}
          selectedObjectiveIds={selectedObjectiveIds}
          selectedBulletpointIds={existingBulletpointIds}
          setSelectedBulletpointIds={async (bulletpointIds: string[]) => {
            await saveTopic(bulletpointIds);
          }}
          gradeItem={
            subject.grades?.find((g) => g.grade === parseNumber(grade)!!)!!
          }
          bpStats={bpStats}
          subjectCode={subject.code}
        />
      </Box>
      <Footer
        onClose={() => {
          close();
        }}
        redirectVisible={!!redirectAfterSaveUrl}
        onRedirect={async () => {
          if (redirectAfterSaveUrl) {
            navigate(redirectAfterSaveUrl);
            setRedirectAfterSaveUrl(undefined);
          }
        }}
      />
    </>
  );
};
