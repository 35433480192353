import {
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { BackAndNextButtons } from '../BackAndNextButtons';
import { GroupNameHeader } from '../headers/GroupNameHeader';

export const NameAndGradeView = ({
  name,
  setName,
  grade,
  setGrade,
  availableGrades,
  panelMode = false,
  saveAndGoToNextPhase,
}: {
  name: string;
  setName?: (n: string) => void;
  grade?: string;
  setGrade?: (g: string) => void;
  availableGrades: string[];
  panelMode?: boolean;
  saveAndGoToNextPhase?: () => void;
}) => {
  if (availableGrades?.length === 0) {
    return <CircularProgress />;
  }
  return (
    <div>
      <GroupNameHeader checked={!!(name && grade)} />
      <Grid container>
        <Grid
          item
          sm={panelMode ? 8 : 8}
          md={panelMode ? 8 : 4}
          style={{ marginLeft: '30px', marginTop: '15px' }}
        >
          {!panelMode && <Typography variant="caption">Name</Typography>}
          <TextField
            disabled={panelMode}
            variant="outlined"
            value={name}
            onChange={(evt) => setName && setName(evt.target.value)}
            fullWidth
          />
        </Grid>
        <Grid
          item
          sm={3}
          md={panelMode ? 3 : 2}
          lg={panelMode ? 3 : 1}
          style={{ marginLeft: '30px', marginTop: '15px' }}
        >
          {!panelMode && <Typography variant="caption">Grade</Typography>}
          <Select
            disabled={panelMode}
            native={false}
            fullWidth
            value={grade || availableGrades[0]}
            placeholder="Grade"
            variant="outlined"
            onChange={(evt) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              setGrade && setGrade((evt.target.value as string).toString());
            }}
          >
            {availableGrades.map((g) => (
              <MenuItem key={g} value={g}>
                {g}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {!panelMode && (
          <Grid item xs={12}>
            <BackAndNextButtons
              next={() => saveAndGoToNextPhase && saveAndGoToNextPhase()}
              nextDisabled={!(name && grade)}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};
