/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import {
  Grid,
  Breadcrumbs as MuiBreadcrumbs,
  Link as MuiLink,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const BreadcrumbLink = (
  props: React.ComponentProps<typeof RouterLink>,
  currentPage: string
) => (
  // @ts-ignore
  <MuiLink
    sx={{ textDecoration: 'underline' }}
    {...props}
    component={RouterLink as any}
  />
);

export const Breadcrumbs = ({
  previousPages,
  currentPage,
}: {
  previousPages: { title: string; path?: string; clickFn?: () => void }[];
  currentPage: string;
}) => (
  <Grid container sx={{ p: 2 }}>
    <MuiBreadcrumbs>
      {previousPages
        .filter((page) => !!page.path)
        .map((page) => (
          <BreadcrumbLink key={`${page.path}breadcrumbPath`} to={page.path!!}>
            {page.title}
          </BreadcrumbLink>
        ))}
      {previousPages
        .filter((page) => !page.path && !!page.clickFn)
        .map((page) => (
          <a
            key={`${page.path}breadcrumb`}
            style={{
              color: '#245f73',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={() => page.clickFn && page.clickFn()}
          >
            {page.title}
          </a>
        ))}
      <span>{currentPage}</span>
    </MuiBreadcrumbs>
  </Grid>
);
