import { useIntl } from 'react-intl';
import { useDrag, useDrop } from 'react-dnd';
import React, { useRef, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { XXL_SPACING } from '../../../theme';
import { TimeLineNoteItem } from './TimeLineNoteItem';
import { Note, Order } from './ActivityOrder';
import { LessonItem, LinkItem } from '../../ops/components/types/types';
import { DropItem } from './DropItem';
import { NoteEditor } from './NoteEditor';
import { TimelineActivityItemDndPlaceholder } from './TimeLineActivityItem';

export const TimelineNoteWrapper = ({
  order,
  addItemToOrder,
  removeFromLesson,
}: {
  order: Order;
  addItemToOrder: (orderNumber: number, lessonItem: LessonItem) => void;
  removeFromLesson: () => void;
}) => {
  const intl = useIntl();
  const [noteEditOpen, setNoteEditOpen] = useState(false);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'note',
    item: {
      order,
    },
    end: async (item, monitor) => {},
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const stateRef = useRef<number>();
  stateRef.current = order.orderNumber;

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['activity', 'note'],
    drop: async (item: DropItem) => {
      await new Promise((resolve) => setTimeout(resolve, 200));
      if (item.activity || item.order?.note) {
        addItemToOrder(
          stateRef.current!! - 1,
          item.activity || item.order?.note
        );
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));
  return (
    <Stack direction="column" gap="1em" sx={{ pt: XXL_SPACING }} ref={drop}>
      {isOver && !isDragging && <TimelineActivityItemDndPlaceholder />}
      <Box
        ref={drag}
        style={{
          cursor: 'pointer',
          width: '100%',
          maxWidth: '800px',
        }}
      >
        {noteEditOpen && (
          <Box style={{ width: '100%' }}>
            <NoteEditor
              close={() => setNoteEditOpen(false)}
              save={(
                description: string,
                showToStudents: boolean,
                name?: string
              ) => {
                removeFromLesson();
                addItemToOrder(stateRef.current!!, {
                  description,
                  showToStudents,
                  name,
                });
                setNoteEditOpen(false);
              }}
              existingName={order?.note?.name}
              existingDescription={order?.note?.description}
              existingShowToStudents={order?.note?.showToStudents}
            />
          </Box>
        )}
        {!noteEditOpen && (
          <TimeLineNoteItem
            // @ts-ignore
            name={order.note.name}
            // @ts-ignore
            description={order.note.description}
            // @ts-ignore
            showToStudents={order.note.showToStudents}
            actionMenuItems={[
              {
                key: 'edit',
                onClick: () => setNoteEditOpen(true),
                children: intl.formatMessage({
                  id: 'noteOrActivity.edit',
                }),
              },
              {
                key: 'delete',
                onClick: () => removeFromLesson(),
                children: intl.formatMessage({
                  id: 'noteOrActivity.removeFromLesson',
                }),
              },
            ]}
          />
        )}
      </Box>
    </Stack>
  );
};
