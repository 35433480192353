export type Note = {
  description: string;
  name?: string;
  showToStudents: boolean;
};

export type Order = {
  activityId?: string;
  orderNumber: number;
  note?: Note;
};

// For easier iteration of the types, as Object.keys(activityOrder)
// returns string[] instead of ActivityOrderType[] and casting it
// everywhere it's used is unwieldy.
export const ActivityOrderTypes: ActivityOrderType[] = [
  'initiation',
  'practicing',
  'closing',
  'homework',
];

export type ActivityOrder = {
  initiation: Order[];
  practicing: Order[];
  closing: Order[];
  homework: Order[];
};

export type ActivityOrderType = keyof ActivityOrder;
