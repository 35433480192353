import React, { useRef, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import {
  LessonItem,
  LinkItem,
  SubjectItem,
} from '../../ops/components/types/types';
import { Note, Order } from './ActivityOrder';
import { ContentProgress } from '../types';
import { BOLD_FONT_WEIGHT, LARGE_SPACING } from '../../../theme';
import { TimelineNoteWrapper } from './TimelineNoteWrapper';
import { TimelineActivityWrapper } from './TimelineActivityWrapper';
import { NoteEditor } from './NoteEditor';
import { TimelinePlusBox } from './TimelinePlusBox';
import { ActivityEditor } from './ActivityEditor';

export const TimelineSectionContent = ({
  header,
  activities,
  setActivityOrNote,
  order,
  deleteItemFromOrder,
  grade,
  subject,
  allTasks,
  showCurriculumContent,
}: {
  header: string;
  activities: LinkItem[];
  setActivityOrNote: (
    orderNumber: number,
    activityOrNote: LinkItem | Note,
    isNew?: boolean
  ) => void;
  order: Order[];
  deleteItemFromOrder: (orderNumber: number) => void;
  grade: string;
  subject: SubjectItem;
  allTasks: ContentProgress[];
  showCurriculumContent: boolean;
}) => {
  const [activityCreateOpen, setActivityCreateOpen] = useState(false);
  const [noteCreateOpen, setNoteCreateOpen] = useState(false);
  const stateRef = useRef<Order[]>();
  stateRef.current = order;
  return (
    <Stack>
      <Box sx={{ mb: LARGE_SPACING }}>
        <Typography variant="body1" style={{ fontWeight: BOLD_FONT_WEIGHT }}>
          {header}
        </Typography>
      </Box>
      {(stateRef.current ?? [])
        .filter(
          (or) =>
            !!or.note || activities.find((a) => a.linkId === or.activityId)
        )
        .sort((a, b) => (a?.orderNumber > b?.orderNumber ? 1 : -1))
        .map((orderThing) => {
          if (orderThing.note) {
            return (
              <TimelineNoteWrapper
                key={`timelineactivity${orderThing.orderNumber}${header}${orderThing.note.name}`}
                addItemToOrder={(
                  orderNumber: number,
                  lessonItem: LessonItem
                ) => {
                  deleteItemFromOrder(orderThing.orderNumber);
                  setActivityOrNote(orderNumber, lessonItem);
                }}
                order={orderThing}
                removeFromLesson={() =>
                  deleteItemFromOrder(orderThing.orderNumber)
                }
              />
            );
          }
          const activity = activities.find(
            (a) => a.linkId === orderThing.activityId
          )!!;
          return (
            <TimelineActivityWrapper
              myOrder={
                stateRef.current?.find(
                  (currr) => currr.activityId === activity.linkId
                )?.orderNumber!!
              }
              activity={activity}
              subject={subject}
              grade={grade}
              key={`timelineactivity${activity.linkId}`}
              addItemToOrder={(orderNumber: number, lessonItem: LessonItem) => {
                setActivityOrNote(orderNumber, lessonItem);
              }}
              setActivity={(a: LinkItem) =>
                setActivityOrNote(
                  stateRef.current?.find(
                    (currr) => currr.activityId === activity.linkId
                  )?.orderNumber!!,
                  a,
                  false
                )
              }
              deleteActivity={() => deleteItemFromOrder(orderThing.orderNumber)}
              allTasks={allTasks}
              showCurriculumContent={showCurriculumContent}
            />
          );
        })}
      {activityCreateOpen && (
        <Box style={{ width: '100%', maxWidth: '800px' }}>
          <ActivityEditor
            disablePortalForBookSelector={true}
            subject={subject}
            grade={grade}
            allTasks={allTasks}
            editingOver={(activity?: LinkItem) => {
              if (activity) {
                setActivityOrNote(
                  Math.max(
                    ...(stateRef.current ?? [])?.map((ord) => ord.orderNumber)
                  ) ?? 1,
                  activity,
                  true
                );
              }
              setActivityCreateOpen(false);
            }}
          />
        </Box>
      )}
      {noteCreateOpen && (
        <Box style={{ width: '100%', maxWidth: '800px' }}>
          <NoteEditor
            close={() => setNoteCreateOpen(false)}
            save={(
              description: string,
              showToStudents: boolean,
              name?: string
            ) => {
              setActivityOrNote(
                stateRef.current?.length
                  ? Math.max(...stateRef.current.map((or) => or.orderNumber)) +
                      1
                  : 1,
                { description, showToStudents, name }
              );
              setNoteCreateOpen(false);
            }}
          />
        </Box>
      )}
      <TimelinePlusBox
        openNoteCreation={() => setNoteCreateOpen(true)}
        openActivityCreate={() => setActivityCreateOpen(true)}
        addItemToOrder={(activityOrNote: LinkItem | Note) => {
          setActivityOrNote(
            stateRef.current?.length
              ? Math.max(...stateRef.current.map((or) => or.orderNumber)) + 1
              : 1,
            activityOrNote
          );
        }}
      />
    </Stack>
  );
};
