import { Chip, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import React from 'react';
import { useIntl } from 'react-intl';
import { useRecoilState } from 'recoil';
import { TimeTable } from '../../../api/timetablesapi';
import { groupEditorSelectedTimetableId } from '../../../App/state';
import { THEME_MAIN_COLOR } from '../../../theme';

const formatDate = (dateString: string): string =>
  format(parseISO(dateString), 'd.M.yyyy');

const CHIP_STYLE = {
  margin: '2px',
  color: '#FFFFFF',
  backgroundColor: THEME_MAIN_COLOR,
  cursor: 'pointer',
};
export const TimetableSelector = ({
  timetables,
  enableNewTimetableLink,
  moveToTimetablesPage,
}: {
  timetables: TimeTable[];
  enableNewTimetableLink: boolean;
  moveToTimetablesPage: () => void;
}) => {
  const intl = useIntl();
  const [_, setSelectedTimetableId] = useRecoilState(
    groupEditorSelectedTimetableId
  );

  return (
    <div style={{ marginTop: '10px' }}>
      {enableNewTimetableLink && (
        <Chip
          size="small"
          style={CHIP_STYLE}
          onClick={() => {
            setSelectedTimetableId(undefined);
            moveToTimetablesPage();
          }}
          label={
            <Typography variant="caption">
              {intl.formatMessage({ id: 'groupPage.newTimetable' })}
            </Typography>
          }
        />
      )}
      {timetables?.map((timetable) => (
        <Chip
          size="small"
          style={CHIP_STYLE}
          onClick={() => {
            setSelectedTimetableId(timetable.timetableId);
            moveToTimetablesPage();
          }}
          key={`${timetable.timetableId}-chip`}
          label={
            <Typography variant="caption">
              {formatDate(timetable.from)}-{formatDate(timetable.to)}
            </Typography>
          }
        />
      ))}
    </div>
  );
};
