import { Box, Button } from '@mui/material';
import React from 'react';
import { XXL_SPACING } from '../../../theme';

export const CancelSave = ({
  cancelLabel,
  saveLabel,
  cancel,
  save,
  saveDisabled,
}: {
  cancelLabel: string;
  saveLabel: string;
  cancel: () => void;
  save: () => void;
  saveDisabled: boolean;
}) => (
  <Box
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    }}
  >
    <Button
      style={{ height: '36px', borderRadius: '30px' }}
      onClick={() => cancel()}
      sx={{ mr: XXL_SPACING }}
      variant="outlined"
    >
      {cancelLabel}
    </Button>
    <Button
      style={{ height: '36px', borderRadius: '30px' }}
      sx={{ mr: XXL_SPACING }}
      variant="contained"
      disabled={saveDisabled}
      onClick={() => save()}
    >
      {saveLabel}
    </Button>
  </Box>
);
