import { Box, Button, Fab, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import { DropItem } from './DropItem';
import { LinkItem } from '../../ops/components/types/types';
import { Note } from './ActivityOrder';
import { XXL_SPACING } from '../../../theme';
import { FeatherIcon, IconSize } from '../../../components/FeatherIcon';
import { TimelineActivityItemDndPlaceholder } from './TimeLineActivityItem';

const PlusBoxButton = styled(Button)`
  width: 64px;
  height: 62px;
  border: 1px dashed #b0b0b0;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: border-radius 1s;

  &:hover {
    border-radius: 100%;
  }
`;

const MultiSelectorButton = styled(Button)`
  width: 64px;
  height: 62px;
  border: 1px dashed #b0b0b0;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #245f73;

  transition: border-radius 1s;

  &:hover {
    border-radius: 100%;
    background-color: #245f73;
  }
`;

export const TimelinePlusBox = ({
  addItemToOrder,
  openActivityCreate,
  openNoteCreation,
}: {
  addItemToOrder: (activityOrNote: LinkItem | Note) => void;
  openActivityCreate: () => void;
  openNoteCreation: () => void;
}) => {
  const [showMultiSelectorButtons, setShowMultiSelectorButtons] =
    useState(false);
  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['activity', 'note'],
    drop: async (item: DropItem) => {
      await new Promise((resolve) => setTimeout(resolve, 200));
      if (item.activity || item.order?.note) {
        addItemToOrder(item.activity || item.order?.note);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <Box sx={{ pt: XXL_SPACING }} ref={drop}>
      {isOver && <TimelineActivityItemDndPlaceholder />}
      {!isOver && !showMultiSelectorButtons && (
        <Tooltip title="Drag or click to add activity">
          <PlusBoxButton onClick={() => setShowMultiSelectorButtons(true)}>
            <FeatherIcon name="plus" />
          </PlusBoxButton>
        </Tooltip>
      )}
      {!isOver && showMultiSelectorButtons && (
        <Box style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <MultiSelectorButton
            onClick={() => setShowMultiSelectorButtons(false)}
          >
            <FeatherIcon name="x" color="white" />
          </MultiSelectorButton>
          <Fab
            sx={{ backgroundColor: 'white' }}
            onClick={() => {
              setShowMultiSelectorButtons(false);
              openActivityCreate();
            }}
          >
            <FeatherIcon
              size={IconSize['SMALL-MEDIUM']}
              name="loader"
              color="grey"
            />
          </Fab>
          <Fab
            sx={{ backgroundColor: 'white' }}
            onClick={() => {
              setShowMultiSelectorButtons(false);
              openNoteCreation();
            }}
          >
            <FeatherIcon
              size={IconSize['SMALL-MEDIUM']}
              name="clipboard"
              color="grey"
            />
          </Fab>
        </Box>
      )}
    </Box>
  );
};
