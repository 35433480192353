import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { DataGrid } from '@mui/x-data-grid';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { getOpsBucket } from '../../api/opsapi';

const columns = [{ field: 'ops', headerName: 'Ops', type: 'string', flex: 1 }];

export const OpsTable = () => {
  const bucketContent = useQuery(['opsBucket'], () => getOpsBucket(), {
    ...QUERY_CONFIG,
  });

  if (!bucketContent.data) {
    return <CircularProgress />;
  }
  return (
    <Box>
      <Typography variant="h6">Available ops</Typography>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          // @ts-ignore
          rows={bucketContent.data.map((a) => ({
            ops: a,
            id: Math.random()
              .toString(36)
              .replace(/[^a-z]+/g, '')
              .substr(0, 5),
          }))}
          columns={columns}
          pageSize={50}
        />
      </div>
    </Box>
  );
};
