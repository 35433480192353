import { useMemo } from 'react';
import { THEME_ACCENT_COLOR } from '../theme';
import { useOps } from './useOps';

export type SubjectColorDefinition = {
  primaryColor: string;
  secondaryColor: string;
};

const backupColors = {
  primaryColor: THEME_ACCENT_COLOR,
  secondaryColor: 'lightgrey',
};

const getSubjectColorFromOps = (
  ops: any,
  subjectCodeOrName?: string
): SubjectColorDefinition => {
  if (!subjectCodeOrName) {
    return backupColors;
  }

  let subject = ops?.subjects?.[subjectCodeOrName];

  if (!subject) {
    // Can't find subject in OPS with the code, try to find it with the name
    subject = Object.values(ops?.subjects || {}).find(
      (s) => (s as { name: string }).name === subjectCodeOrName
    );
  }

  if (!subject) {
    // Can't find subject in OPS with name either, return backup colors
    return backupColors;
  }

  if (!subject.colors) {
    // Colors not defined for this lesson; poorly configured lesson or out of date ops
    return backupColors;
  }

  const [primaryColor, secondaryColor] = subject.colors;
  return { primaryColor, secondaryColor };
};

export type useSubjectColorResponse = {
  subjectColors: { [key: string]: SubjectColorDefinition };
  subjectColor: SubjectColorDefinition;
};

export const useSubjectColor = (
  subjects?: string | string[] | undefined
): useSubjectColorResponse => {
  const { ops } = useOps();

  return useMemo(() => {
    let subjectColors = {};
    if (Array.isArray(subjects)) {
      subjectColors = subjects.reduce((acc, subject) => {
        const color = getSubjectColorFromOps(ops, subject);
        acc[subject] = color;
        return acc;
      }, {} as { [key: string]: SubjectColorDefinition });
    }

    let subjectColor = backupColors;
    if (subjects && typeof subjects === 'string') {
      subjectColor = getSubjectColorFromOps(ops, subjects);
    }

    return { subjectColors, subjectColor };
  }, [ops, subjects]);
};
