import React from 'react';
import { useDrop } from 'react-dnd';
import { THEME_MAIN_COLOR } from '../../../theme';
import { TIMETABLE_DROPAREA_HOURBOX as Z_INDEX } from '../../../utils/z-index';

interface Item {
  id: string;
  duration: number;
  subjectTimingId?: string;
}

export const DropAreaHourBox = ({
  setSubject,
  disabled = false,
}: {
  setSubject: (
    subject: string,
    itemToRemove?: number,
    subjectTimingId?: string
  ) => void;
  disabled?: boolean;
}) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'subject',
    drop: async (item: Item) => {
      await new Promise((resolve) => setTimeout(resolve, 200));
      setSubject(item.id, item.duration, item.subjectTimingId);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
    canDrop: (item) => !disabled,
  }));

  return (
    <div
      ref={drop}
      style={{
        position: 'relative',
        height: '15px',
        width: '100%',
      }}
    >
      {isOver && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: '100%',
            zIndex: Z_INDEX,
            opacity: 0.5,
            backgroundColor: isOver ? THEME_MAIN_COLOR : '#fff',
          }}
        />
      )}
    </div>
  );
};
