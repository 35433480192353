import { useQuery } from '@tanstack/react-query';
import { getCachedOps } from '../api/opsCacher';
import { QUERY_CONFIG } from '../utils/queryconfig';
import { useAuth } from '../utils/auth/useAuth';

export const useOps = () => {
  const { user } = useAuth();
  const {
    data: ops,
    isFetching,
    isError,
  } = useQuery(['ops'], () => getCachedOps(user), {
    ...QUERY_CONFIG,
    enabled: !!user,
  });

  return { ops, isFetching, isError };
};
