import { createTheme } from '@mui/material';

export const HEADER = 59;
export const HEADER_HEIGHT = `${HEADER}px`;

export const THEME_MAIN_COLOR = '#245f73';
export const THEME_ACCENT_COLOR = 'rgb(36, 95, 115)';
export const THEME_MAIN_TEXT_COLOR = '#172B4D';
export const THEME_DISABLED_COLOR = 'rgba(0, 0, 0, 0.26)';

export const theme = createTheme({
  typography: {
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
  },
  spacing: 4, // use margin / padding with https://mui.com/system/spacing/. values 1 = small, 2 = medium, 3 = large, 4 = xl, for example sx={{ mx: 1 }} adds 4px margin to left and right. p: 2 adds padding of 8px.
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      main: THEME_MAIN_COLOR,
      light: '#307f9a',
    },
    secondary: {
      main: '#1F1F38',
    },
    text: {
      primary: THEME_MAIN_TEXT_COLOR,
      secondary: '#757575',
      disabled: '#6E6E6E',
    },
  },
  components: {
    MuiToolbar: {
      styleOverrides: {},
    },
  },
});

export const SMALL_SPACING = 1;
export const MEDIUM_SPACING = 2;
export const MEDIUM_LARGE_SPACING = 3;
export const LARGE_SPACING = 4;
export const XXL_SPACING = 6;
export const XXXL_SPACING = 10;

export const LEFT_NAV_BACKGROUND_COLOR = '#ECEEF1';
export const DETAIL_COLOR = '#DAAC56';

export const LIGHT_BACKGROUND_COLOR = '#F3F4F5';
export const LIGHT_LIGHT_BACKGROUND_COLOR = '#E9EBEE';
export const LIGHT_LIGHT_LIGHT_BACKGROUND_COLOR = '#F7F8F9';
export const LIGHT_LIGHT_LIGHT_LIGHT_BACKGROUND_COLOR = '#00000029';
export const LIGHT_BACKGROUND_COLOR_VARIATION = '#F7F8FA';

export const BOLD_FONT_WEIGHT = '550';
export const MEDIUM_FONT_WEIGHT = '500';

export const NEW_LIGHT_BORDER = '1px solid #707070';
export const LIGHT_BORDER = '0.5px solid lightgrey';
export const LESS_LIGHT_BORDER = '1px solid #C7C7C7';
export const NOT_SELECTED_DETAIL_BORDER = '1px solid #E4E4E5';
export const THICKER_LIGHT_BORDER = '2px solid #EBEBEB';
export const SELECTED_DETAIL_BORDER = `1px solid ${DETAIL_COLOR}`;
export const THICKER_SELECTED_DETAIL_BORDER = `2px solid ${DETAIL_COLOR}`;

export const EVEN_THICKER_NOT_SELECTED_DETAIL_BORDER = '3px solid #E4E4E5';

export const EVEN_THICKER_SELECTED_DETAIL_BORDER = `3px solid ${DETAIL_COLOR}`;
export const SELECTED_BACKGROUND = '#FEFAF2 0% 0% no-repeat padding-box';
export const COMPLETED_COLOR = '#C7E8C9';
export const DARK_COMPLETED_COLOR = '#21A52A';

export const GREY_BACKGROUND = '#F2F2F2 0% 0% no-repeat padding-box';

export const DONE_BUTTON_COLOR = '#339933';

export const DELETE_COLOR = 'rgb(244,105,142)';
