import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useBreakpoints } from '../hooks/useBreakpoints';
import { getProfilePictureUrl } from '../api/usersapi';
import { useAuth } from '../utils/auth/useAuth';
import { QUERY_CONFIG } from '../utils/queryconfig';
import { LocaleSelection } from './LocaleSelection';

// @ts-ignore
import backup from '../routes/profile/backup.png';
import { HamburgerNavigation } from './HamburgerNavigation';
import { ProfileEditor } from '../routes/profile/ProfileEditor';
import { FeatherIcon, IconSize } from '../components/FeatherIcon';
import {
  BOLD_FONT_WEIGHT,
  LARGE_SPACING,
  MEDIUM_LARGE_SPACING,
  MEDIUM_SPACING,
  THEME_MAIN_COLOR,
  THEME_MAIN_TEXT_COLOR,
} from '../theme';
import { useClickOutSide } from '../hooks/useClickOutSide';

const borderBottom = '1px solid lightgrey';

const ProfileMenuSection = (props: { children: any; noBorder: boolean }) => (
  <div style={{ ...(props.noBorder ? {} : { borderBottom }) }}>
    {props.children}
  </div>
);

const ProfileMenuButton = (props: {
  onClick: () => void;
  label: string;
  icon: string;
}) => (
  <Button
    onClick={props.onClick}
    style={{
      textTransform: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    }}
    sx={{ p: LARGE_SPACING }}
    fullWidth
  >
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      sx={{ ml: LARGE_SPACING }}
    >
      <FeatherIcon name={props.icon} color={THEME_MAIN_COLOR} />
    </Box>
    <Typography
      style={{ paddingLeft: '8px' }}
      variant="body2"
      color="inherit"
      sx={{ ml: MEDIUM_SPACING }}
    >
      {props.label}
    </Typography>
  </Button>
);

const ProfileImage = (props: {
  height: string;
  imageLoadError: boolean;
  pic: string;
  onError: () => void;
}) => (
  <img
    style={{
      height: props.height,
      borderRadius: '50%',
      boxShadow: 'inset 0px 3px 6px #00000029, 0px 3px 6px #00000029',
      border: '2px solid #FFFFFF',
    }}
    src={props.imageLoadError ? backup : props.pic}
    alt="profile"
    onError={props.onError}
  />
);

export const HeaderMenu = () => {
  const wrapperRef = useRef(null);
  const { isLoggedIn, name, logout, email } = useAuth();
  const navigate = useNavigate();
  const [showLocale, setShowLocale] = useState(false);
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [profileEditorOpen, setProfileEditorOpen] = useState(false);
  const [imageLoadError, setImageLoadError] = useState(false);
  const { useHamburgerNavigation } = useBreakpoints();
  const { isPhone } = useBreakpoints();
  const intl = useIntl();
  useClickOutSide(wrapperRef, () => setProfileEditorOpen(false));

  const getPictureUrlResponse = useQuery({
    queryKey: ['profile-picture-get-url'],
    queryFn: getProfilePictureUrl,
    enabled: !!isLoggedIn,
    ...QUERY_CONFIG,
  });

  useEffect(() => {
    setImageLoadError(false);
  }, [getPictureUrlResponse.data]);

  if (!getPictureUrlResponse.data) {
    return <CircularProgress />;
  }

  const imageOnErrorHandler = () => {
    setImageLoadError(true);
  };

  // @ts-ignore
  return (
    <Stack direction="row-reverse" justifyContent="space-between">
      <IconButton
        onClick={() => setProfileMenuOpen(!profileMenuOpen)}
        color="inherit"
      >
        <ProfileImage
          height="30px"
          imageLoadError={imageLoadError}
          pic={getPictureUrlResponse?.data.thumb}
          onError={imageOnErrorHandler}
        />
      </IconButton>
      {profileMenuOpen && (
        <Box
          style={{
            position: 'absolute',
            top: '43px',
            backgroundColor: '#FFFFFF',
            color: 'black',
            borderRadius: '8px',
            ...(isPhone ? { width: '100%', right: '0px' } : { right: '48px' }),
          }}
        >
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderBottom,
            }}
            sx={{ px: MEDIUM_LARGE_SPACING, py: LARGE_SPACING }}
          >
            <ProfileImage
              height="60px"
              imageLoadError={imageLoadError}
              pic={getPictureUrlResponse?.data.thumb}
              onError={imageOnErrorHandler}
            />
            <Stack>
              {name && (
                <Box>
                  <Typography
                    style={{
                      paddingLeft: '8px',
                      fontWeight: BOLD_FONT_WEIGHT,
                      marginBottom: '-8px',
                    }}
                    variant="body1"
                    color={THEME_MAIN_TEXT_COLOR}
                  >
                    {name}
                  </Typography>
                </Box>
              )}

              {email && (
                <Box>
                  <Typography
                    style={{ paddingLeft: '8px' }}
                    variant="caption"
                    color="#8D8D8D"
                  >
                    {email}
                  </Typography>
                </Box>
              )}
            </Stack>
          </Box>

          <ProfileMenuSection noBorder={false}>
            <ProfileMenuButton
              onClick={() => {
                setProfileMenuOpen(false);
                setProfileEditorOpen(true);
              }}
              label={intl.formatMessage({ id: 'profileMenu.editProfile' })}
              icon="edit-3"
            />
          </ProfileMenuSection>
          <ProfileMenuSection noBorder={false}>
            <ProfileMenuButton
              onClick={() => setShowLocale(!showLocale)}
              label={intl.formatMessage({ id: 'profileMenu.changeLocale' })}
              icon="globe"
            />
            {showLocale && (
              <LocaleSelection close={() => setShowLocale(false)} />
            )}
          </ProfileMenuSection>

          <ProfileMenuSection noBorder={true}>
            <ProfileMenuButton
              icon="log-out"
              onClick={() => logout()}
              label={intl.formatMessage({ id: 'routes.logout' })}
            />
          </ProfileMenuSection>
        </Box>
      )}

      {profileEditorOpen && (
        <div
          ref={wrapperRef}
          style={{
            position: 'absolute',
            top: '60px',
            right: '0px',
            backgroundColor: '#FFFFFF',
            color: 'black',
            width: isPhone ? '100%' : 'calc(100vw / 3)',
            height: '100vh',
          }}
        >
          <Box
            style={{
              backgroundColor: '#F1F4F6',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
            sx={{ p: LARGE_SPACING }}
          >
            <Stack>
              <Typography variant="body2">
                {intl.formatMessage({ id: 'profileEditor.header' })}
              </Typography>
              <Typography
                variant="body1"
                style={{ fontWeight: BOLD_FONT_WEIGHT }}
              >
                {intl.formatMessage({ id: 'profileEditor.subHeader' })}
              </Typography>
            </Stack>
            <IconButton onClick={() => setProfileEditorOpen(false)}>
              <FeatherIcon size={IconSize.MEDIUM} color="rgb(4,4,4)" name="x" />
            </IconButton>
          </Box>
          <ProfileEditor forwardRef={wrapperRef} />
        </div>
      )}

      {useHamburgerNavigation && <HamburgerNavigation />}
    </Stack>
  );
};
