/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Stack, Box, Typography, Button } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { useIntl } from 'react-intl';

import { EnhancedSubjectTimings } from '../../api/timetablesapi';
import {
  scheduleViewClass,
  contentSelectedGroupSubjects,
  contentLeftPanelOpenState,
  contentState,
  contentRedirectAfterSaveUrl,
  yearPlanDialogActiveTab,
  contentContentDialogOpen,
} from '../../App/state';
import {
  BOLD_FONT_WEIGHT,
  LIGHT_LIGHT_BACKGROUND_COLOR,
  MEDIUM_SPACING,
  XXL_SPACING,
  THEME_MAIN_COLOR,
  XXXL_SPACING,
  THEME_MAIN_TEXT_COLOR,
} from '../../theme';
import { TopicCard } from './TopicCard';
import { redirectToYearPlanPageWithTopic } from './util';
import { useSubjectColor } from '../../hooks/useSubjectColor';
import { StyledDialog } from '../../components/StyledDialog';
import { LessonPlanPreview } from '../my-plan/lesson-plan/LessonPlanPreview';
import { DialogTransition } from '../../components/DialogTransition';
import { SubjectColorBox } from '../../components/SubjectColorBox';
import { LessonInfo } from '../../components/LessonInfo';
import { IconActionButton } from '../../components/IconActionButton';
import { FeatherIcon, IconSize } from '../../components/FeatherIcon';
import { ActivityOrderTypes } from '../my-plan/lesson-plan/ActivityOrder';

export const LessonView = ({
  subjectTimings,
  isStudent,
}: {
  subjectTimings: EnhancedSubjectTimings[];
  isStudent: boolean;
}) => {
  const navigate = useNavigate();
  const intl = useIntl();
  const [_______, setDialogOpen] = useRecoilState(contentContentDialogOpen);
  const [______, setRedirectAfterSaveUrl] = useRecoilState(
    contentRedirectAfterSaveUrl
  );
  const [subjectTiming, __] = useRecoilState(scheduleViewClass);
  const [_, setSelectedGroupSubjects] = useRecoilState(
    contentSelectedGroupSubjects
  );
  const [________, setActiveTab] = useRecoilState(yearPlanDialogActiveTab);
  const [___, setPanelOpenState] = useRecoilState(contentLeftPanelOpenState);
  const [_____, setContentState] = useRecoilState(contentState);
  const subject: EnhancedSubjectTimings | undefined = subjectTimings.find(
    (s) => s.subjectTimingId === subjectTiming?.subjectTimingId
  );
  const { subjectColor } = useSubjectColor(subject?.subject);
  const [showPlanPreviewDialog, setShowPlanPreviewDialog] = useState(false);

  if (!subject) {
    return <></>;
  }
  const hasTopic = !!subject?.topic;
  const hasTopicCreated =
    hasTopic &&
    subject?.topic?.activityOrder &&
    ActivityOrderTypes.some(
      (key) => subject.topic!!.activityOrder!![key]!!.length > 0
    );
  const showCreateTopicButton = !hasTopic && !isStudent;
  const showPreviewButton = hasTopic && !isStudent;
  const showEditButton = hasTopic && !isStudent;

  const redirectToCreate = () =>
    redirectToYearPlanPageWithTopic(
      setSelectedGroupSubjects,
      subject,
      setPanelOpenState,
      navigate,
      setContentState,
      setRedirectAfterSaveUrl,
      setActiveTab,
      'Content',
      setDialogOpen
    );
  const redirectToEdit = () => {
    if (!subject?.topic) {
      // We should never show the button & end here in this case
      return;
    }
    redirectToYearPlanPageWithTopic(
      setSelectedGroupSubjects,
      subject,
      setPanelOpenState,
      navigate,
      setContentState,
      setRedirectAfterSaveUrl,
      setActiveTab,
      'Lesson',
      setDialogOpen
    );
  };

  return (
    <Box
      style={{
        minHeight: '100%',
      }}
      sx={{ p: 0 }}
    >
      <StyledDialog
        TransitionComponent={DialogTransition}
        open={showPlanPreviewDialog}
        onClose={() => {
          setShowPlanPreviewDialog(false);
        }}
        maxWidth={false}
        fullWidth={true}
      >
        <LessonPlanPreview subjectTiming={subject} />
      </StyledDialog>

      <SubjectColorBox subjectColor={subjectColor}>
        <Stack direction="row" justifyContent="space-between">
          <LessonInfo
            subject={subject.subject}
            group={subject.groupName || ''}
            startAt={subject.startAtDate}
            endAt={subject.endAtDate}
          />
          <Stack justifyContent="end" gap="1em">
            {showPreviewButton && (
              <IconActionButton
                size="medium"
                name="maximize-2"
                color={THEME_MAIN_COLOR}
                onClick={() => {
                  setShowPlanPreviewDialog(true);
                  if (subject.topic) {
                    setContentState(
                      (curValue) =>
                        ({
                          ...curValue,
                          topic: subject.topic,
                        } as any)
                    );
                  }
                }}
              />
            )}
            {showEditButton && (
              <IconActionButton
                size="medium"
                name="edit-2"
                color={THEME_MAIN_COLOR}
                onClick={redirectToEdit}
                tooltip={intl.formatMessage({ id: 'buttons.editLessonPlan' })}
              />
            )}
            {showCreateTopicButton && (
              <IconActionButton
                size="medium"
                name="plus"
                color={THEME_MAIN_COLOR}
                onClick={redirectToCreate}
                tooltip={intl.formatMessage({ id: 'buttons.createLessonPlan' })}
              />
            )}
          </Stack>
        </Stack>
      </SubjectColorBox>
      {subject.topic?.activityOrder &&
        ActivityOrderTypes.some(
          (key) => subject.topic!!.activityOrder!![key]!!.length > 0
        ) && <TopicCard topic={subject.topic} />}
      {showCreateTopicButton && (
        <Box
          sx={{ p: XXL_SPACING, m: XXXL_SPACING, borderRadius: 2 }}
          style={{
            border: '1px solid #AFBBC4',
            backgroundColor: LIGHT_LIGHT_BACKGROUND_COLOR,
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{ m: MEDIUM_SPACING }}
            style={{ display: 'block', fontWeight: BOLD_FONT_WEIGHT }}
          >
            {intl
              .formatMessage({ id: 'lessonPanel.noCurriculumContents' })
              .toUpperCase()}
          </Typography>
          <Box>
            <FeatherIcon
              size={IconSize.MEDIUM}
              name="alert-circle"
              color="red"
              style={{
                strokeWidth: '2',
                fill: LIGHT_LIGHT_BACKGROUND_COLOR,
                position: 'relative',
                marginLeft: '0%',
                zIndex: '1',
              }}
            />
          </Box>
          <Box>
            <FeatherIcon
              size={IconSize['EXTRA-LARGE']}
              name="award"
              color="white"
              style={{
                strokeWidth: '1',
                fill: '#245F73',
                marginLeft: '10%',
                marginTop: '-7%',
                position: 'relative',
                opacity: '0.45',
              }}
            />
          </Box>

          <Button
            onClick={redirectToCreate}
            variant="text"
            style={{
              margin: 0,
              borderRadius: '0%',
            }}
          >
            <FeatherIcon
              color={THEME_MAIN_TEXT_COLOR}
              size={IconSize['SMALL-MEDIUM']}
              name="plus"
              style={{
                display: 'block',
                marginTop: '-1px',
                marginRight: '4px',
              }}
            />
            <Typography
              variant="body2"
              style={{ display: 'block', fontWeight: BOLD_FONT_WEIGHT }}
            >
              {intl.formatMessage({
                id: 'yearplan.contentmodal.contenttab',
              })}
            </Typography>
          </Button>
        </Box>
      )}
      {!hasTopicCreated && (
        <Box
          sx={{ p: XXL_SPACING, m: XXXL_SPACING, borderRadius: 2 }}
          style={{
            border: '1px solid #AFBBC4',
            backgroundColor: LIGHT_LIGHT_BACKGROUND_COLOR,
            textAlign: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{ m: MEDIUM_SPACING }}
            style={{ display: 'block', fontWeight: BOLD_FONT_WEIGHT }}
          >
            {intl
              .formatMessage({ id: 'topicContainer.noLessonPlan' })
              .toUpperCase()}
          </Typography>
          <Box>
            <FeatherIcon
              size={IconSize.MEDIUM}
              name="alert-circle"
              color="red"
              style={{
                strokeWidth: '2',
                fill: LIGHT_LIGHT_BACKGROUND_COLOR,
                position: 'relative',
                marginLeft: '0%',
                zIndex: '1',
              }}
            />
          </Box>
          <Box>
            <FeatherIcon
              size={IconSize['EXTRA-LARGE']}
              name="award"
              color="white"
              style={{
                strokeWidth: '1',
                fill: '#245F73',
                marginLeft: '10%',
                marginTop: '-7%',
                position: 'relative',
                opacity: '0.45',
              }}
            />
          </Box>

          <Button
            onClick={redirectToEdit}
            variant="text"
            disabled={!hasTopic}
            style={{
              margin: 0,
              borderRadius: '0%',
            }}
          >
            <FeatherIcon
              color={THEME_MAIN_TEXT_COLOR}
              size={IconSize['SMALL-MEDIUM']}
              name="plus"
              style={{
                display: 'block',
                marginTop: '-1px',
                marginRight: '4px',
              }}
            />
            <Typography
              variant="body2"
              style={{ display: 'block', fontWeight: BOLD_FONT_WEIGHT }}
            >
              {intl.formatMessage({
                id: 'yearplan.contentmodal.lessonplantab',
              })}
            </Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};
