/* eslint-disable @typescript-eslint/no-explicit-any */
import { LINKS_API_URL } from './constants';
import { doDelete } from '../utils/http';
import { apiDeleteWithUrl, apiGet, apiPost, apiPut } from './genericapi';

export const getBySubjectAndGradeId = <T>(
  subjectId: string,
  gradeId: string
): Promise<T> =>
  apiGet(`${LINKS_API_URL}/cid/?subjectId=${subjectId}&gradeId=${gradeId}`);

export const getLinkImageUploadUrl = (
  linkId: string | undefined
): Promise<any> => apiGet(`${LINKS_API_URL}/put-url/${linkId}`);

export const deleteLinkImage = (linkId: string): Promise<any> =>
  apiDeleteWithUrl(`${LINKS_API_URL}/image/${linkId}`);

export const updateLink = (payload: any): Promise<any> =>
  apiPut(`${LINKS_API_URL}/id/${payload.linkId}`, {
    activityType: payload.activityType,
    title: payload.title,
    type: payload.type,
    link: payload.link,
    private: payload.private,
    userId: payload.userId,
    description: payload.description,
    bookTitle: payload.bookTitle,
    bookId: payload.bookId,
    page: payload.page,
    bulletpointIds: payload.bulletpointIds,
    subjectId: payload.subjectId,
    gradeId: payload.gradeId,
  });

export const createAndAttachLink = (payload: any): Promise<any> =>
  apiPost(LINKS_API_URL, {
    activityType: payload.activityType,
    title: payload.title,
    type: payload.type,
    link: payload.link,
    private: payload.private,
    description: payload.description,
    bookId: payload.bookId,
    page: payload.page,
    bookTitle: payload.bookTitle,
    subjectId: payload.subjectId,
    gradeId: payload.gradeId,
    bulletpointIds: payload.bulletpointIds,
  });

const createDeAttachLinkApiUrl = (linkId: string): string => {
  const base = `${LINKS_API_URL}/deattach/${linkId}`;
  return base;
};

export const deAttachLink = (payload: any): Promise<any> =>
  doDelete({
    url: createDeAttachLinkApiUrl(payload.linkId),
  });

export const LINKS_API_KEY = 'links-api';
export const createLinksApiQueryKey = (subjectId: string, gradeId: string) =>
  `${LINKS_API_KEY}/${subjectId}/${gradeId}`;
