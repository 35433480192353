/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/indent */
import { format } from 'date-fns';
import {
  CONTENT_PROGRESS_API_URL,
  CONTENT_PROGRESS_STUDENT_API_URL,
} from './constants';
import {
  getCurrentSchoolYear,
  getStartAndEndBySchoolYear,
} from '../utils/getCurrentSchoolYear';
import { apiGet } from './genericapi';

// new Date() month is 0-indexed....
const FORMAT = 'yyyy-MM-dd';
const getStartAndEnd = (forYear?: number | undefined): string => {
  const schoolYear = !forYear
    ? getCurrentSchoolYear()
    : getStartAndEndBySchoolYear(forYear);
  return `start=${format(schoolYear.start, FORMAT)}&end=${format(
    schoolYear.end,
    FORMAT
  )}`;
};

export const getAllStats = (
  groupId: string | undefined,
  forYear?: number | undefined
): Promise<any> =>
  apiGet(
    `${CONTENT_PROGRESS_API_URL}/bulletpointstats?groupId=${groupId}&${getStartAndEnd(
      forYear
    )}`
  );

export const getAllStudentStats = (): Promise<any> =>
  apiGet(
    `${CONTENT_PROGRESS_STUDENT_API_URL}/bulletpointstats?${getStartAndEnd()}`
  );

export const CONTENT_PROGRESS_API_QUERY_KEY = 'contentprogressapiallstats';
