import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { StyledDialog } from '../../../components/StyledDialog';
import { GenericTimePicker } from '../../../generics/components/form/input/GenericTimePicker';
import { DaySubject } from './interfaces';
import { dateToTime, timeToDate } from './utils';
import { DialogTransition } from '../../../components/DialogTransition';

export const SubjectDialog = ({
  subject,
  open,
  onClose,
  setSubject,
  removeSubject,
  label,
}: {
  subject: DaySubject;
  open: boolean;
  onClose: () => void;
  setSubject: (subject: DaySubject) => void;
  removeSubject: () => void;
  label: string;
}) => {
  const [from, setFrom] = useState<Date>(timeToDate(subject.from));
  const [to, setTo] = useState<Date>(timeToDate(subject.to));

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      open={open}
      onClose={onClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
      maxWidth="md"
    >
      <DialogTitle
        color="primary"
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Typography variant="h6">{subject?.subject.split('-')[0]}</Typography>
        <Typography variant="caption">{label}</Typography>
      </DialogTitle>
      <DialogContent>
        <GenericTimePicker
          setTime={(val) => setFrom(val as Date)}
          fieldName="From"
          fieldRequired={true}
          fieldValue={from}
          fieldTitle="From"
        />
        <br />
        <GenericTimePicker
          setTime={(val) => setTo(val as Date)}
          style={{ display: 'flex' }}
          fieldName="To"
          fieldRequired={true}
          fieldValue={to}
          fieldTitle="To"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Cancel
        </Button>
        <Button onClick={removeSubject} color="secondary" variant="contained">
          Delete
        </Button>
        <Button
          onClick={() => {
            setSubject({
              subject: subject.subject,
              from: dateToTime(from),
              to: dateToTime(to),
              subjectTimingId: subject.subjectTimingId,
            });
          }}
          color="primary"
          variant="contained"
        >
          Save
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
