/* eslint-disable @typescript-eslint/no-explicit-any */
import { TIME_TABLE_API_URL } from './constants';
import { apiDeleteWithUrl, apiGet, apiPost, apiPut } from './genericapi';
import { Topic } from './topicapi';

export const getAllTimeTables = (
  groupId: string | string[] | undefined
): Promise<any> => {
  if (!groupId) {
    return apiGet(`${TIME_TABLE_API_URL}`);
  }
  // TODO: Update backend to allow multiple values to remove this multi-request hack
  const groupIdArr = typeof groupId === 'string' ? [groupId] : groupId;
  return Promise.all(
    groupIdArr.map((id) => apiGet(`${TIME_TABLE_API_URL}?groupId=${id}`))
  ).then((res) => res.flat());
};

export const createTimetable = (
  payload: TimeTable & { groupId: string }
): Promise<{ timetableId: string }> =>
  apiPost(`${TIME_TABLE_API_URL}?groupId=${payload.groupId}`, payload);

export const updateTimetable = (
  payload: TimeTable & { groupId: string }
): Promise<any> =>
  apiPut(
    `${TIME_TABLE_API_URL}/${payload.timetableId}?groupId=${payload.groupId}`,
    payload
  );

export const deleteTimetable = (timetableId: string): Promise<any> =>
  apiDeleteWithUrl(`${TIME_TABLE_API_URL}/${timetableId}`);

export const deleteSubjectTiming = (payload: {
  timetableId: string;
  subjectTimingId: string;
}): Promise<any> =>
  apiDeleteWithUrl(
    `${TIME_TABLE_API_URL}/${payload.timetableId}/subjecttimings/${payload.subjectTimingId}`
  );

export const createSubjectTiming = (
  payload: SubjectTiming & { timetableId: string }
): Promise<{ timetableId: string }> =>
  apiPost(
    `${TIME_TABLE_API_URL}/${payload.timetableId}/subjecttimings`,
    payload
  );

export const updateSubjectTiming = (
  payload: SubjectTiming & { timetableId: string }
): Promise<any> =>
  apiPut(
    `${TIME_TABLE_API_URL}/${payload.timetableId}/subjecttimings`,
    payload
  );

export interface SubjectTiming {
  subject: string;
  grade: string;
  startAt: string;
  endAt: string;
  notGenerated?: boolean;
  subjectTimingId?: string;
  schoolYear?: number;
  lessonNumber?: number;
}

export type EnhancedSubjectTimings = SubjectTiming & {
  startAtDate: Date;
  endAtDate: Date;
  timetableId: string;
  topic?: Topic;
  groupName?: string;
  groupId?: string;
};

export const isEnhancedSubjectTiming = (
  st: EnhancedSubjectTimings | SubjectTiming | undefined
): st is EnhancedSubjectTimings =>
  (st as EnhancedSubjectTimings)?.startAtDate !== undefined;

export interface TimeTable {
  groupId?: string;
  from: string;
  to: string;
  title: string;
  timetableId?: string;
  times: SubjectTiming[];
}

export const TIMETABLES_QUERY_KEY = 'timetablesapi';
