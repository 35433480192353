import { Button } from '@mui/material';
import React from 'react';

export const BackAndNextButtons = ({
  back,
  next,
  nextDisabled,
  nextText,
}: {
  back?: () => void;
  next: () => void;
  nextDisabled: boolean;
  nextText?: string;
}) => (
  <div style={{ display: 'flex', marginTop: '20px' }}>
    {back && (
      <Button
        style={{ marginRight: '10px' }}
        size="small"
        variant="contained"
        color="secondary"
        onClick={() => back()}
      >
        Back
      </Button>
    )}
    <Button
      size="small"
      variant="contained"
      color="primary"
      onClick={() => next()}
      disabled={nextDisabled}
    >
      {nextText ?? 'Next'}
    </Button>
  </div>
);
