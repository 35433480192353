import { Card, Grid, Typography } from '@mui/material';
import React from 'react';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { XXXL_SPACING } from '../../theme';
import { StickyHeader } from '../my-plan/StickyHeader';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import { TransversalItem } from '../ops/components/types/types';
import { TransversalProgress } from './TransversalProgress';

interface HeaderProps {
  grade: string;
  transversalCode: string;
  transversal: string;
  transversalItems: TransversalItem[];
  bulletpointStats: ContentProgressBulletpointStats[];
  isStudent?: boolean;
}

export const TransversalProgressHeader = ({
  grade,
  transversalCode,
  transversal,
  transversalItems,
  bulletpointStats,
  isStudent,
}: HeaderProps) => (
  <>
    <StickyHeader container>
      <Breadcrumbs
        currentPage="Transversal competences"
        previousPages={[
          {
            title: 'Progress',
            path: isStudent ? '/student/progress' : '/progress',
          },
        ]}
      />
    </StickyHeader>
    <Card
      variant="outlined"
      style={{ padding: '25px' }}
      sx={{ mt: XXXL_SPACING }}
    >
      <Grid
        container
        spacing={1}
        justifyContent="center"
        style={{ padding: '0 0 5px 0' }}
      >
        <Typography variant="h4" style={{ textAlign: 'center', width: '100%' }}>
          {transversalCode} {transversal}
        </Typography>
        <TransversalProgress
          grade={grade}
          label={`${transversalCode} ${transversal}`}
          transversals={transversalItems}
          bulletpointStats={bulletpointStats}
          simpleMode={true}
        />
      </Grid>
    </Card>
  </>
);
