import { Box } from '@mui/material';
import React from 'react';
import { GroupStudentsSummary } from '../GroupStudentsSummary';
import { GroupStudentsHeader } from '../headers/GroupStudentsHeader';

export const StudentsPanelView = ({
  existingStudentEmails,
  allStudentEmails,
}: {
  existingStudentEmails: number;
  allStudentEmails: number;
}) => (
  <>
    <GroupStudentsHeader
      checked={!!existingStudentEmails || !!allStudentEmails}
    />
    <Box sx={{ p: 1 }}>
      <GroupStudentsSummary
        existingStudentEmails={existingStudentEmails}
        allStudentEmails={allStudentEmails}
      />
    </Box>
  </>
);
