import React from 'react';
import { BORDER_1, BORDER_2 } from './constants';
import { SCHEDULE_HOUR_DIVIDER as Z_INDEX } from '../../utils/z-index';

export const VisualHourDividers = ({
  count,
  first,
  interval,
}: {
  count: number;
  first: string;
  interval: number;
}) => (
  <>
    {Array.from({ length: count }, (v, i) => i).map((i) => (
      <div
        // eslint-disable-next-line react/no-array-index-key
        key={i * 6}
        style={{
          position: 'absolute',
          zIndex: Z_INDEX,
          top: `calc(${first} + ${interval * i}px)`,
          width: `100%`,
          height: `${interval}px`,
          borderTop: BORDER_2,
        }}
      >
        <div
          style={{
            width: '100%',
            height: `${interval / 2}px`,
            borderBottom: BORDER_1,
          }}
        />
      </div>
    ))}
  </>
);
