/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  FAVORITES_API_URL as favoritesUrl,
  FAVORITES_STUDENT_API_URL,
  USER_API_URL,
} from './constants';
import { apiGet, apiPut, apiDeleteWithUrl } from './genericapi';

export const getFavorites = (groupId: string | undefined): Promise<string[]> =>
  apiGet(`${favoritesUrl}?groupId=${groupId}`);

export const getStudentFavorites = (): Promise<string[]> =>
  apiGet(FAVORITES_STUDENT_API_URL);

export const putFavorites = <R>(
  payload: string[] | undefined,
  groupId: string | undefined
): Promise<R> => apiPut(`${favoritesUrl}?groupId=${groupId}`, payload);

export const getUserLang = (): Promise<{ lang: string }> =>
  apiGet(`${USER_API_URL}/lang`);
export const updateUserLang = (lang: string): Promise<void> =>
  apiPut(`${USER_API_URL}/lang`, { lang });

export const getProfilePicturePutUrl = (): Promise<{ url: string }> =>
  apiGet(`${USER_API_URL}/profile-picture/put-url`);

export const getProfilePictureUrl = (): Promise<{
  profile: string;
  thumb: string;
}> => apiGet(`${USER_API_URL}/profile-picture`);

export const deleteProfilePicture = (): Promise<Number> =>
  apiDeleteWithUrl(`${USER_API_URL}/profile-picture`);
