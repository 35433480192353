import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { parseISO, format } from 'date-fns';
import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DataGrid } from '@mui/x-data-grid';
import { getUsers } from '../../api/adminapi';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { DAYFORMAT } from '../../utils/weekhelpers';
import { User } from './User';

const columns = [
  { field: 'role', headerName: 'Role', type: 'string', flex: 0.7 },
  { field: 'email', headerName: 'Email', type: 'string', flex: 1 },
  { field: 'ops', headerName: 'Ops', type: 'string', flex: 0.5 },
  { field: 'superAdmin', headherName: 'Admin', type: 'boolean', flex: 0.5 },
  { field: 'name', headerName: 'Name', type: 'string', flex: 1 },
  { field: 'created', headerName: 'Created', type: 'string', flex: 0.7 },
  { field: 'modified', headerName: 'Last Login', type: 'string', flex: 0.7 }, // We can use this because we update user attrs on login in pretoken lambda
  { field: 'lang', headerName: 'Lang  ', type: 'string', flex: 0.5 },
];

export const UserTable = () => {
  const users = useQuery(['users'], () => getUsers(), {
    ...QUERY_CONFIG,
  });

  if (!users.data) {
    return <CircularProgress />;
  }
  const usersArr: User[] = users?.data.length ? users.data : [];
  return (
    <Box>
      <Typography variant="h6">Users</Typography>
      <div style={{ height: 1000, width: '100%' }}>
        <DataGrid
          rows={usersArr.map((u) => ({
            ...u,
            created: u.created
              ? format(parseISO(u.created!!), DAYFORMAT)
              : undefined,
            modified: u.modified
              ? format(parseISO(u.modified!!), DAYFORMAT)
              : undefined,
            id:
              u.cognitoId ??
              Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, '')
                .substr(0, 5),
          }))}
          columns={columns}
          pageSize={50}
        />
      </div>
    </Box>
  );
};
