import { useCallback } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SCHEDULE_API_KEY } from '../api/scheduleapi';
import { TOPICS_QUERY_KEY, putTopic } from '../api/topicapi';
import { CONTENT_PROGRESS_API_QUERY_KEY } from '../api/contentprogressapi';

export const useTopicMutation = (params: {
  groupId?: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();

  const successHandler = useCallback(async () => {
    await queryClient.invalidateQueries({
      queryKey: [
        TOPICS_QUERY_KEY,
        {
          groupId: params.groupId,
        },
      ],
    });
    await queryClient.invalidateQueries({
      queryKey: [SCHEDULE_API_KEY],
    });
    await queryClient.invalidateQueries({
      queryKey: [
        CONTENT_PROGRESS_API_QUERY_KEY,
        {
          groupId: params.groupId,
        },
      ],
    });
    if (params.onSuccess) {
      params.onSuccess();
    }
    await new Promise((resolve) => setTimeout(resolve, 1500));
  }, [params.groupId, params.onSuccess, queryClient]);

  const errorHandler = useCallback(() => {
    if (params.onError) {
      params.onError();
    }
  }, [params.onError]);

  const updateMutation = useMutation(putTopic, {
    onSuccess: successHandler,
    onError: errorHandler,
  });

  return { updateMutation };
};
