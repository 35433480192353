import { Box, Grid, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import {
  LARGE_SPACING,
  MEDIUM_SPACING,
  SMALL_SPACING,
  XXL_SPACING,
} from '../../theme';

interface Props {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export const ProfileInformation = ({ firstName, lastName, email }: Props) => (
  <Stack
    style={{
      justifyContent: 'stretch',
      width: '100%',
      height: '100%',
    }}
    sx={{ p: XXL_SPACING }}
  >
    <TextField
      label={<Typography variant="caption">First name</Typography>}
      variant="outlined"
      value={firstName}
      fullWidth
      disabled
      sx={{ mb: LARGE_SPACING, p: MEDIUM_SPACING }}
    />
    <TextField
      label={<Typography variant="caption">Last name</Typography>}
      variant="outlined"
      value={lastName}
      fullWidth
      disabled
      sx={{ mb: LARGE_SPACING, p: MEDIUM_SPACING }}
    />
    <TextField
      label={<Typography variant="caption">Email</Typography>}
      variant="outlined"
      value={email}
      fullWidth
      disabled
      sx={{ mb: LARGE_SPACING, p: MEDIUM_SPACING }}
    />
    <Box sx={{ width: '100%', borderBottom: '1px solid #C8D0D7' }} />
  </Stack>
);
