import { useQuery } from '@tanstack/react-query';
import { getGroups, Group } from '../api/groupapi';
import { QUERY_CONFIG } from '../utils/queryconfig';

export const useSelectedGroup = () => {
  const res = useQuery(
    ['groupapi'],
    async () => {
      const response = await getGroups();
      return response;
    },
    QUERY_CONFIG
  ) as {
    data: Group[];
  };
  return res?.data?.filter((g) => g.selected)[0];
};
