export const TIMETABLE_DROPAREA_HOURBOX = 1;
export const YEAR_TIMELINE_CARD = 1;

export const CONTENT_SELECTOR_CARD = 100;

export const LESSON_PLAN_ACITIVTY_EDITOR = 200;

export const LESSON_PLAN_BUTTONS = 300;

export const SCHEDULE_HOUR_DIVIDER = 400;

export const CONTENT_BALL_WRAPPER = 500;
export const PANEL_CLASSIC = 500;

export const SCHEDULE_SUBJECT = 600;

export const SCHEDULE_COLUMN_HEADER = 700;

export const SCHEDULE_CURRENT_TIME_INDICATOR = 800;

export const CONTENT_SELECTOR_CONTENT = 900;

export const LESSON_PLAN_HEADER = 1000;

export const SCHEDULE_WEEK_SELECTION = 1100;
export const LEFT_NAV = 1100;

export const DRAWER = 1200;

export const PANEL_MOBILE = 1250;

export const CLASSIC_PANEL_CONTROLS = 1300;

export const FULL_SCREEN_DIALOG = 1400;

export const OBJECTIVE_POPOVER = 1500;
