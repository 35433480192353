import React from 'react';

export const TimeLineItemTitle = (props: {
  children: any;
  isHighlighted?: boolean;
  isHomeWork?: boolean;
}) => (
  <h3
    style={{
      margin: 0,
      color: props.isHomeWork ? '#C69232' : '#172B4D',
      fontSize: props.isHighlighted ? '44px' : '20px',
      lineHeight: props.isHighlighted ? '48px' : '24px',
      fontFamily: 'robotoMedium',
    }}
  >
    {props.children}
  </h3>
);
