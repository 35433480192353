import * as React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Typography,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useIntl } from 'react-intl';
import { useSnackbar } from 'notistack';
import Papa from 'papaparse';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ErrorOutline } from '@mui/icons-material';
import { StyledDialog } from '../../components/StyledDialog';
import {
  Row,
  rowToGroupsAndTimetables,
  validateAndFormatGroup,
} from './validateAndFormatGroup';
import { getSubjectsByGrade } from '../../utils/ops/getSubjectsByGrade';
import { parseSubjectList } from '../../utils/ops/utils';
import { addOrPutGroup } from '../../api/groupapi';
import { putFavorites } from '../../api/usersapi';
import { createTimetable } from '../../api/timetablesapi';
import { DialogTransition } from '../../components/DialogTransition';
import { useOps } from '../../hooks/useOps';
import { useAuth } from '../../utils/auth/useAuth';

enum Phase {
  INITIAL,
  LOADING,
}

const addFavorites = async (params: any): Promise<void> => {
  await putFavorites(params.favorites, params.groupId);
};

export const ImportGroupsDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { user } = useAuth();
  const groupMutation = useMutation(addOrPutGroup);
  const favoriteMutation = useMutation(addFavorites);
  const timeTableMutation = useMutation(createTimetable);
  const { ops } = useOps();
  const intl = useIntl();
  const inputFile = React.useRef<HTMLInputElement | null>(null);
  const [phase, setPhase] = React.useState(Phase.INITIAL);
  const [rows, setRows] = React.useState<Row[]>([]);
  const [validationError, setValidationError] = React.useState<
    string | undefined
  >();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const onUploadButtonClick = () => {
    if (inputFile !== null && inputFile.current !== null) {
      inputFile.current.click();
    }
  };
  React.useEffect(() => {
    setRows([]);
    setValidationError(undefined);
  }, [open]);

  if (!ops) {
    return <CircularProgress />;
  }

  const subjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: false })
  );

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      open={open}
      // @ts-ignore
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      maxWidth="md"
    >
      <DialogTitle color="primary">
        <Typography variant="h6" style={{ margin: '20px' }}>
          {intl.formatMessage({ id: 'importGroupsDialog.title' })}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {phase === Phase.LOADING && (
          <div
            style={{
              width: '200px',
              height: '200px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CircularProgress />
          </div>
        )}
        {phase === Phase.INITIAL && (
          <div style={{ margin: '20px', maxWidth: '400px' }}>
            <Typography variant="body2" style={{ marginBottom: '20px' }}>
              {intl.formatMessage({ id: 'importGroupsDialog.content' })}
            </Typography>
            <input
              type="file"
              id="file"
              accept=".csv"
              style={{ display: 'none' }}
              ref={inputFile}
              onChange={(evt: any) => {
                setValidationError(undefined);
                setPhase(Phase.LOADING);
                Papa.parse(evt.target.files[0], {
                  header: true,
                  skipEmptyLines: true,
                  complete: (result: any) => {
                    const parseValidationResult = validateAndFormatGroup(
                      result.data,
                      subjects
                    );
                    if (Array.isArray(parseValidationResult)) {
                      setValidationError(undefined);
                      setRows(parseValidationResult as Row[]);
                    } else {
                      setRows([]);
                      setValidationError(parseValidationResult.errorValue);
                    }
                    setPhase(Phase.INITIAL);
                  },
                  error: () => {
                    enqueueSnackbar(
                      intl.formatMessage({ id: 'notifications.genericError' }),
                      { variant: 'error' }
                    );
                  },
                });
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<CloudUploadIcon />}
              onClick={onUploadButtonClick}
            >
              {intl.formatMessage({ id: 'importGroupsDialog.uploadButton' })}
            </Button>
            {(validationError || rows.length > 0) && (
              <Box
                sx={{ p: 5, mt: 5 }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: validationError ? 'lightcoral' : 'aliceblue',
                  width: '100%',
                  height: '40px',
                  borderRadius: '6px',
                }}
              >
                {validationError && (
                  <>
                    <ErrorOutline style={{ marginRight: '10px' }} />
                    <Typography variant="body2">
                      {intl.formatMessage({
                        id: 'importGroupsDialog.validationError',
                      })}{' '}
                      <b>{validationError}</b>
                    </Typography>
                  </>
                )}
                {rows.length > 0 && (
                  <div
                    style={{
                      display: 'flex',
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <Typography variant="body2">
                      {intl.formatMessage(
                        {
                          id: 'importGroupsDialog.groupsFound',
                        },
                        {
                          groups: [...new Set(rows.map((r) => r.groupName))]
                            .length,
                          timetables: [
                            ...new Set(
                              rows.map(
                                (r) => r.groupName + r.startDate + r.endDate
                              )
                            ),
                          ].length,
                        }
                      )}
                    </Typography>
                  </div>
                )}
              </Box>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions
        style={{ display: 'flex', justifyContent: 'space-between' }}
      >
        <Button
          onClick={onClose}
          disabled={phase === Phase.LOADING}
          color="secondary"
          variant="contained"
        >
          {intl.formatMessage({ id: 'button.cancel' })}
        </Button>
        <Button
          onClick={async () => {
            setPhase(Phase.LOADING);
            const data = rowToGroupsAndTimetables(rows, user?.ops);
            const result = await Promise.allSettled(
              data.flatMap(async (d) => {
                const responseFromGroupMutation =
                  await groupMutation.mutateAsync(d.group);
                await favoriteMutation.mutateAsync({
                  favorites: d.favorites,
                  groupId: responseFromGroupMutation.groupId,
                });
                return Promise.allSettled(
                  d.timetables.map(async (timetable) => {
                    await timeTableMutation.mutateAsync({
                      ...timetable,
                      groupId: responseFromGroupMutation.groupId,
                    });
                  })
                );
              })
            );
            const resultHasErrors = result.some(
              (re) => re.status !== 'fulfilled'
            );
            enqueueSnackbar(
              intl.formatMessage({
                id: resultHasErrors
                  ? 'notifications.importGroupsDialog.importError'
                  : 'notifications.importGroupsDialog.importSuccess',
              }),
              { variant: resultHasErrors ? 'error' : 'success' }
            );
            queryClient.invalidateQueries();
            setRows([]);
            setPhase(Phase.INITIAL);
            onClose();
          }}
          variant="contained"
          color="primary"
          autoFocus
          disabled={
            !!validationError || rows.length === 0 || phase === Phase.LOADING
          }
        >
          {intl.formatMessage({ id: 'importGroupsDialog.saveButton' })}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
