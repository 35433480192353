/* eslint-disable @typescript-eslint/indent */
import { Grid } from '@mui/material';
import styled from 'styled-components';

export const StickyHeader = styled(Grid)`
  top: 56px;
  position: sticky;
  background-color: ${(p) => p.theme.palette.background.default};
  z-index: 100;
`;
