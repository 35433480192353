export const uploadImageToS3 = async (
  url: string,
  body: ArrayBuffer,
  mime: string
) =>
  fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    headers: {
      'Content-Type': mime,
    },
    body,
  });
