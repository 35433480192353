import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components';
import { CssBaseline, ThemeProvider } from '@mui/material';
import * as serviceWorker from './serviceWorker';
import './resources/fonts/Roboto-Regular.ttf';
import { AuthContextProvider } from './utils/auth/AuthContext';
import { App } from './App/App';
import { theme } from './theme';

const queryClient = new QueryClient();

if (process.env.NODE_ENV !== 'production') {
  localStorage.setItem('debug', 'curriculum:*');
}
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <RecoilRoot>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <StyledComponentsThemeProvider theme={theme}>
          <QueryClientProvider client={queryClient}>
            <AuthContextProvider>
              <App />
            </AuthContextProvider>
          </QueryClientProvider>
        </StyledComponentsThemeProvider>
      </ThemeProvider>
    </RecoilRoot>
  </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
