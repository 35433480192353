/* eslint-disable no-nested-ternary */
import { Box, Button } from '@mui/material';
import * as React from 'react';
import { LIGHT_LIGHT_LIGHT_LIGHT_BACKGROUND_COLOR, HEADER } from '../theme';
import { FeatherIcon, IconSize } from './FeatherIcon';
import {
  CLASSIC_PANEL_CONTROLS,
  PANEL_CLASSIC as Z_INDEX,
} from '../utils/z-index';

export const getPanelWidth = (isOpen: boolean) =>
  isOpen ? 'calc(100vw / 3)' : '60px';

const ExpandButton = (props: {
  panelAlignment: 'left' | 'right';
  onClick: () => void;
}) => (
  <Button
    variant="text"
    style={{
      position: 'absolute',
      top: '20px',
      zIndex: Z_INDEX,
      ...(props.panelAlignment === 'right' && {
        left: '0px',
      }),
      ...(props.panelAlignment === 'left' && {
        right: '0px',
      }),
    }}
    onClick={props.onClick}
  >
    <FeatherIcon
      size={IconSize.LARGE}
      name={props.panelAlignment === 'left' ? 'chevron-right' : 'chevron-left'}
    />
  </Button>
);

const CloseButton = (props: { onClick: () => void }) => (
  <Button
    variant="text"
    style={{
      position: 'absolute',
      top: '20px',
      zIndex: CLASSIC_PANEL_CONTROLS,
      right: '0px',
    }}
    onClick={props.onClick}
  >
    <FeatherIcon size={IconSize.LARGE} name="x" />
  </Button>
);

export const PanelClassic = ({
  children,
  isOpen,
  closePanel,
  openPanel,
  showControlButton,
  panelAlignment,
}: {
  children: JSX.Element | React.ReactNode;
  isOpen: boolean;
  closePanel: () => void;
  openPanel: () => void;
  showControlButton?: boolean;
  panelAlignment: 'left' | 'right';
}) => (
  <Box
    style={{
      flexShrink: 0,
      backgroundColor: '#FFFFFF',
      width: getPanelWidth(isOpen),
      minHeight: `calc(100dvh - ${HEADER}px)`,
      boxShadow: '0px 3px 6px #00000029',
      position: 'relative',
    }}
    sx={{ p: 0 }}
  >
    {!isOpen && (
      <Box
        style={{
          display: 'flex',
        }}
        sx={{ p: 0 }}
      >
        {showControlButton !== false && (
          <ExpandButton onClick={openPanel} panelAlignment={panelAlignment} />
        )}
        <Box
          style={{
            position: 'absolute',
            height: '100%',
            flexShrink: 0,
            width: '18px',
            overflow: 'hidden',
          }}
        >
          {children}
        </Box>
        <Box
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            onClick={() => (isOpen ? closePanel() : openPanel())}
            style={{
              alignSelf: 'center',
              backgroundColor: LIGHT_LIGHT_LIGHT_LIGHT_BACKGROUND_COLOR,
              width: '6px',
              height: '30px',
              borderRadius: '4px',
              ...(panelAlignment === 'right' && { marginRight: '13px' }),
              ...(panelAlignment === 'left' && { marginLeft: '13px' }),
            }}
          />
        </Box>
      </Box>
    )}
    {isOpen && (
      <Box
        style={{
          position: 'fixed',
          top: `${HEADER}px`,
          bottom: 0,
          width: getPanelWidth(isOpen),
          overflowY: 'auto',
        }}
      >
        {showControlButton !== false && <CloseButton onClick={closePanel} />}
        {children}
      </Box>
    )}
  </Box>
);
