import * as React from 'react';
import { Box, Stack } from '@mui/material';
import { FeatherIcon, IconSize } from './FeatherIcon';
import { HEADER_HEIGHT } from '../theme';
import { useBreakpoints } from '../hooks/useBreakpoints';
import { getMainNavWidth } from '../app-base/MainNav';
import { PanelContentDivider } from './PanelContentDivider';
import { PANEL_MOBILE as Z_INDEX } from '../utils/z-index';

const PanelMobileOpener = ({
  isOpen,
  onClick,
}: {
  isOpen: boolean;
  onClick: () => void;
}) => {
  const { useHamburgerNavigation } = useBreakpoints();

  const positionClosed: React.CSSProperties = {
    position: 'fixed',
    left: useHamburgerNavigation ? 0 : getMainNavWidth(false),
  };

  const positionOpen: React.CSSProperties = {
    position: 'absolute',
    left: 'reset',
  };

  const positionStyles = isOpen ? positionOpen : positionClosed;

  const styles: React.CSSProperties = {
    left: useHamburgerNavigation ? 0 : getMainNavWidth(false),
    top: `calc(${!isOpen ? HEADER_HEIGHT : '0px'} + 5px)`,
    borderRadius: '0 100% 100% 0',
    width: 0,
    overflow: 'visible',
    zIndex: Z_INDEX + 2,
    ...positionStyles,
  };

  const icon = isOpen ? 'chevron-left' : 'chevron-right';

  return (
    <Box style={styles}>
      <Box
        style={{
          width: '50px',
          height: '50px',
          padding: '13px 0 0 10px',
          borderRadius: '0 100% 100% 0',
          boxShadow: '3px 4px 4px #00000040',
          cursor: 'pointer',
          backgroundColor: 'white',
        }}
        onClick={onClick}
      >
        <FeatherIcon name={icon} size={IconSize.MEDIUM} />
      </Box>
    </Box>
  );
};

const PanelMobileContent = ({
  title,
  children,
  onClose,
}: {
  title: string;
  children: JSX.Element | React.ReactNode;
  onClose: () => void;
}) => {
  const { useHamburgerNavigation } = useBreakpoints();

  return (
    <Stack
      direction="row"
      style={{
        position: 'fixed',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        top: HEADER_HEIGHT,
        bottom: 0,
        left: useHamburgerNavigation ? 0 : getMainNavWidth(false),
        right: 0,
        zIndex: Z_INDEX,
      }}
      onClick={onClose}
    >
      <Stack
        direction="row"
        style={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
        }}
      >
        <Box
          style={{
            backgroundColor: 'white',
            width: 'fit-content',
            zIndex: Z_INDEX + 1,
            height: '100%',
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <Stack style={{ height: '100%' }}>
            <Box
              style={{
                fontSize: '18px',
                fontWeight: 900,
              }}
              sx={{ padding: 4 }}
            >
              {title}
            </Box>
            <PanelContentDivider sx={{ marginY: 0 }} />
            <Box
              flexGrow={1}
              style={{
                overflowY: 'auto',
              }}
            >
              <Box sx={{ padding: 4 }}>{children}</Box>
            </Box>
          </Stack>
        </Box>
        <Box style={{ zIndex: Z_INDEX + 1, minWidth: 0 }}>
          <PanelMobileOpener isOpen={true} onClick={onClose} />
        </Box>
      </Stack>
    </Stack>
  );
};

export const PanelMobile = ({
  title,
  children,
  isOpen,
  onClose,
  onOpen,
}: {
  title: string;
  children: JSX.Element | React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onOpen: () => void;
}) => {
  if (!isOpen) {
    return <PanelMobileOpener isOpen={isOpen} onClick={onOpen} />;
  }

  return (
    <PanelMobileContent title={title} onClose={onClose}>
      {children}
    </PanelMobileContent>
  );
};
