import { Log } from '../utils/debug';
import * as api from './opsapi';

export const LOCAL_STORAGE_KEY_OPS = 'ops';

const getOpsKey = (user) => {
  if (user.ops !== 'GEORGIA') {
    return user.ops;
  }
  return 'generic';
};

export const getCachedOps = async (user) => {
  // eslint-disable-next-line no-undef
  const ops = localStorage.getItem(LOCAL_STORAGE_KEY_OPS);
  if (!ops) {
    try {
      const response = await api.get(getOpsKey(user));
      // eslint-disable-next-line no-undef
      localStorage.setItem(LOCAL_STORAGE_KEY_OPS, JSON.stringify(response));
      return response;
    } catch (e) {
      Log.error('could not get ops');
      throw new Error('Could not fetch ops');
    }
  }

  return JSON.parse(ops);
};
