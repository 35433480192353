import { Box, Card, Collapse, Grid } from '@mui/material';
import * as React from 'react';
import { SubjectCardHeaderBox } from './SubjectCardHeaderBox';
import { ProgressBarWrapper } from './ProgressBarWrapper';
import { findSubjectAbbreviation } from '../../utils/ops/findSubjectAbbreviation';
import { getTransversalStats } from '../../utils/stathelpers';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import {
  SubjectItem,
  TransversalItemWithInfo,
} from '../ops/components/types/types';
import { TransversalProgressCardBody } from './TransversalProgressCardBody';

interface Props {
  groupId?: string | undefined;
  transversals: TransversalItemWithInfo[];
  bulletpointStats: ContentProgressBulletpointStats[];
  subjects: SubjectItem[];
  isStudent?: boolean;
}

export const TransversalProgressCard = ({
  groupId,
  transversals,
  bulletpointStats,
  subjects,
  isStudent,
}: Props) => {
  const [expanded, setExpanded] = React.useState(false);
  const stats = getTransversalStats(transversals, bulletpointStats);
  const { subject, grade } = transversals[0];
  return (
    <Grid item xs={12} md={6} lg={4}>
      <Card variant="outlined">
        <Box
          display="flex"
          style={{ alignItems: 'center' }}
          onClick={() => setExpanded(!expanded)}
        >
          <SubjectCardHeaderBox
            subjectAbbreviation={findSubjectAbbreviation(
              subject.title,
              subjects
            )}
            grade={grade.grade}
          />
          <Box flex={1} p={1} style={{ cursor: 'pointer' }}>
            <ProgressBarWrapper stats={stats} />
          </Box>
        </Box>
        <Collapse in={expanded}>
          <TransversalProgressCardBody
            groupId={groupId}
            transversals={transversals}
            isStudent={isStudent}
          />
        </Collapse>
      </Card>
    </Grid>
  );
};
