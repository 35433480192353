import { isAfter, isBefore, isEqual, parseISO } from 'date-fns';
import { ContentProgressBulletpointStats } from '../routes/my-plan/types';
import {
  Objective,
  TransversalItem,
} from '../routes/ops/components/types/types';

const today = new Date();

// returns true if completed, false if in progress, null if not planned
export const isBulletpointCompleted = (
  bulletpointStat?: ContentProgressBulletpointStats
): boolean | null => {
  if (bulletpointStat && bulletpointStat.plannedAt) {
    return isBefore(parseISO(bulletpointStat?.plannedAt), today);
  }
  return null;
};

export const isBulletpointPlanned = (
  bulletpointStat?: ContentProgressBulletpointStats
): boolean | null => {
  if (bulletpointStat && bulletpointStat.plannedAt) {
    const parsed = parseISO(bulletpointStat?.plannedAt);
    return isAfter(parsed, today) || isEqual(parsed, today);
  }
  return null;
};

export const isContentCompletedNew = (
  contentBulletpoints: string[] = [],
  allStats?: ContentProgressBulletpointStats[]
): boolean | null => {
  const relevantStats = contentBulletpoints?.filter((cp) =>
    allStats?.find((ast) => ast.bulletpointId === cp)
  );
  if (relevantStats.length) {
    if (relevantStats.length < contentBulletpoints?.length) {
      return false;
    }
    return relevantStats.every((relevantStat) => {
      const bp = allStats?.find((as) => as.bulletpointId === relevantStat);
      return bp && isBulletpointCompleted(bp);
    });
  }
  return null;
};

export const getObjectiveStats = (
  objective: Objective,
  allStats?: ContentProgressBulletpointStats[]
): { completed: number; notCompleted: number; planned: number } | undefined => {
  const allBulletpoints = [
    ...(objective.contents
      ? objective.contents.flatMap((c) => Object.keys(c.bulletpoints))
      : []),
    ...(objective.transversals
      ? objective.transversals.flatMap((c) => Object.keys(c.bulletpoints))
      : []),
  ];
  const combined = allBulletpoints.length;
  const bpCompleted =
    allStats
      ?.filter((stat) => allBulletpoints.includes(stat.bulletpointId))
      .filter((bp) => isBulletpointCompleted(bp)).length ?? 0;
  const bpPlanned =
    allStats
      ?.filter((stat) => allBulletpoints.includes(stat.bulletpointId))
      .filter((bp) => isBulletpointPlanned(bp)).length ?? 0;
  return {
    completed: bpCompleted,
    notCompleted: combined - bpCompleted - bpPlanned,
    planned: bpPlanned,
  };
};

export const getContentStats = (
  contentBulletpoints: string[],
  allStats?: ContentProgressBulletpointStats[]
): { completed: number; notCompleted: number; planned: number } | undefined => {
  const bulletpoints = contentBulletpoints;
  const bpCompleted =
    allStats
      ?.filter((stat) => bulletpoints.includes(stat.bulletpointId))
      .filter((bp) => isBulletpointCompleted(bp)).length ?? 0;
  const bpPlanned =
    allStats
      ?.filter((stat) => bulletpoints.includes(stat.bulletpointId))
      .filter((bp) => isBulletpointPlanned(bp)).length ?? 0;
  return {
    completed: bpCompleted,
    notCompleted: bulletpoints.length - bpCompleted - bpPlanned,
    planned: bpPlanned,
  };
};

export interface Stats {
  completed: number;
  planned: number;
  all: number;
}

const countAllBulletpoints = (objectives: Objective[]) =>
  objectives
    .map((o) => {
      const contentBulletpointsAmount =
        o?.contents
          ?.map((c) => Object.keys(c.bulletpoints).length)
          .reduce((a, b) => a + b, 0) ?? 0;
      const transversalsBulletpointsAmount =
        o?.transversals
          ?.map((t) => Object.keys(t.bulletpoints).length)
          .reduce((a, b) => a + b, 0) ?? 0;
      return contentBulletpointsAmount + transversalsBulletpointsAmount;
    })
    .reduce((a, b) => a + b, 0);

export const getSubjectStatsByContentProgressBulletpointStats = (
  objectives: Objective[],
  stats: ContentProgressBulletpointStats[]
): Stats => ({
  completed: stats.filter((bp) => isBulletpointCompleted(bp)).length,
  planned: stats.filter((bp) => isBulletpointPlanned(bp)).length,
  all: countAllBulletpoints(objectives),
});

const countAllTransversalBulletpoints = (transversals: TransversalItem[]) =>
  transversals
    .map((t) => Object.keys(t.bulletpoints).length ?? 0)
    .reduce((a, b) => a + b, 0) ?? 0;

const countAllCompletedTransversalBulletpoints = (
  transversals: TransversalItem[],
  bulletpointStats: ContentProgressBulletpointStats[]
) =>
  transversals
    .map(
      (t) =>
        Object.keys(t.bulletpoints).filter((bp) => {
          const found = bulletpointStats.find(
            (bps) => bps.bulletpointId === bp
          );
          if (found) {
            return isBulletpointCompleted(found);
          }
          return false;
        }).length ?? 0
    )
    .reduce((a, b) => a + b, 0) ?? 0;

const countAllPlannedTransversalBulletpoints = (
  transversals: TransversalItem[],
  bulletpointStats: ContentProgressBulletpointStats[]
) =>
  transversals
    .map(
      (t) =>
        Object.keys(t.bulletpoints).filter((bp) => {
          const found = bulletpointStats.find(
            (bps) => bps.bulletpointId === bp
          );
          if (found) {
            return isBulletpointPlanned(found);
          }
          return false;
        }).length ?? 0
    )
    .reduce((a, b) => a + b, 0) ?? 0;

export const getTransversalStats = (
  transversals: TransversalItem[],
  bulletpointStats: ContentProgressBulletpointStats[]
): Stats => ({
  completed: countAllCompletedTransversalBulletpoints(
    transversals,
    bulletpointStats
  ),
  planned: countAllPlannedTransversalBulletpoints(
    transversals,
    bulletpointStats
  ),
  all: countAllTransversalBulletpoints(transversals),
});
