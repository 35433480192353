import {
  GROUP_API_URL,
  STUDENT_API_URL,
  STUDENT_GROUP_API_URL,
} from './constants';
import { apiDeleteWithUrl, apiGet, apiPost, apiPut } from './genericapi';

export const getGroups = <T>(): Promise<T> => apiGet(`${GROUP_API_URL}/`);

export const studentGetGroups = <T>(): Promise<T> =>
  apiGet(`${STUDENT_GROUP_API_URL}/`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateGroupSelection = (groupId: string): Promise<any> =>
  apiPut(`${GROUP_API_URL}/${groupId}/select`, {});

// refactor student api url to groups...
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const inviteStudentsToGroup = (payload: any): Promise<any> =>
  apiPost(`${STUDENT_API_URL}?groupId=${payload.groupId}`, payload.emails);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const addOrPutGroup = (payload: any): Promise<any> =>
  apiPut(`${GROUP_API_URL}`, payload);

export const deleteGroup = (groupId: string): Promise<any> =>
  apiDeleteWithUrl(`${GROUP_API_URL}/${groupId}`);

export interface Group {
  name: string;
  students: string[];
  school: string;
  subjects: string[];
  selected: boolean;
  groupId?: string;
  existingStudentEmails?: string[];
  grade?: string;
  favorites?: string[];
}

export const GROUPS_QUERY_KEY = 'groupapi';
