/* eslint-disable @typescript-eslint/indent */
import { CircularProgress, Grid } from '@mui/material';
import * as React from 'react';
import { useQuery } from '@tanstack/react-query';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { Params } from '../my-plan/types';
import {
  TransversalItem,
  TransversalItemWithInfo,
} from '../ops/components/types/types';
import * as contentProgressApi from '../../api/contentprogressapi';
import { ObjectiveCard } from '../../components/ObjectiveCard';
import { CONTENT_PROGRESS_API_QUERY_KEY } from '../../api/contentprogressapi';

interface Props {
  groupId?: string | undefined;
  transversals: TransversalItemWithInfo[];
  isStudent?: boolean;
}

const fetchAllContentProgressStats = (params: Params) => {
  const obj = params.queryKey[1];
  return contentProgressApi.getAllStats(obj.groupId);
};
const fetchAllStudentContentProgressStats = (params: Params) =>
  contentProgressApi.getAllStudentStats();

export const TransversalProgressCardBody = ({
  groupId,
  transversals,
  isStudent,
}: Props) => {
  const subjectCode = transversals[0].subject.code;
  const { grade } = transversals[0].grade;

  const contentProgressStats = useQuery({
    queryKey: [CONTENT_PROGRESS_API_QUERY_KEY, { groupId }],
    queryFn: isStudent
      ? fetchAllStudentContentProgressStats
      : fetchAllContentProgressStats,
    ...QUERY_CONFIG,
    enabled: isStudent ? true : !!groupId,
  });

  if (!contentProgressStats.data) {
    return <CircularProgress />;
  }

  const allStats = contentProgressStats?.data?.length
    ? contentProgressStats.data
    : [];

  // ObjectiveCards could be set open by recoilState
  return (
    <Grid container spacing={2} style={{ height: '100%', padding: '2px' }}>
      {transversals.map((t) => (
        <ObjectiveCard
          gradeId={grade.toString()}
          key={t.objective.code}
          objective={{
            ...t.objective,
            contents: undefined,
            transversals: [t as TransversalItem],
          }}
          subjectCode={subjectCode}
          oneSize={true}
          allStats={allStats}
        />
      ))}
    </Grid>
  );
};
