import React from 'react';
import { useIntl } from 'react-intl';
import { add, set } from 'date-fns';
import { Day } from './Day';
import { EnhancedSubjectTimings } from '../../api/timetablesapi';
import { ScheduleWeek } from '../../generics/types/scheduleWeek';

export const Days = ({
  startHour,
  hoursInDay,
  times,
  showWeekend,
  week,
  fullHeight,
  switchToNextWeek,
  switchToPrevWeek,
}: {
  startHour: number;
  hoursInDay: string[];
  times: EnhancedSubjectTimings[];
  showWeekend: boolean;
  week: ScheduleWeek;
  fullHeight: string;
  switchToNextWeek: () => void;
  switchToPrevWeek: () => void;
}) => {
  const intl = useIntl();
  let days = ['days.mon', 'days.tue', 'days.wed', 'days.thu', 'days.fri'];
  if (showWeekend) {
    days = [...days, 'days.sat', 'days.sun'];
  }
  days = days.map((id) => intl.formatMessage({ id }).toUpperCase());
  return (
    <>
      {days.map((day, index) => {
        const currentDate = add(week.startAt, { days: index });
        return (
          <Day
            dayStart={set(currentDate, { hours: startHour, minutes: 0 })}
            hoursInDay={hoursInDay}
            currentDate={currentDate}
            key={currentDate.toString()}
            day={day}
            times={times}
            isFirst={index === 0}
            isLast={days.length - 1 === index}
            fullHeight={fullHeight}
            switchToNextWeek={switchToNextWeek}
            switchToPrevWeek={switchToPrevWeek}
          />
        );
      })}
    </>
  );
};
