import { Box, Button, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Group } from '../../api/groupapi';
import { SMALL_SPACING } from '../../theme';
import { FilterButton } from '../../components/FilterButton';
import { FilterItemBox } from '../../components/FilterItemBox';

export const GroupFilter = ({
  groups,
  selectedGroups,
  setSelectedGroups,
}: {
  groups: Group[];
  selectedGroups: string[];
  setSelectedGroups: (groupIds: string[]) => void;
}) => {
  const [groupsVisible, setGroupsVisible] = useState(true);

  return (
    <>
      <FilterButton click={() => setGroupsVisible(!groupsVisible)} />
      {groupsVisible && (
        <Box
          sx={{ p: 2, mb: 2, mt: SMALL_SPACING }}
          style={{
            backgroundColor: '#FFFFFF',
            border: '0.5px solid',
            borderRadius: '4px',
          }}
        >
          <Typography sx={{ ml: 2 }} variant="body2" style={{ color: 'gray' }}>
            <b>FILTER GROUPS</b>
          </Typography>
          <Grid container>
            <Grid item xs={12}>
              {groups.map((g) => (
                <Button
                  key={`${g.groupId}selector`}
                  onClick={() => {
                    if (selectedGroups.includes(g.groupId!!)) {
                      setSelectedGroups(
                        selectedGroups?.filter((sg) => sg !== g.groupId)
                      );
                    } else {
                      setSelectedGroups([...selectedGroups, g.groupId!!]);
                    }
                  }}
                >
                  <FilterItemBox
                    text={g.name}
                    selected={selectedGroups.includes(g?.groupId!!)}
                  />
                </Button>
              ))}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
