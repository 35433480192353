import React from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import { Offline } from 'react-detect-offline';
import { Websocket } from '../App/Websocket';
import { useAuth } from '../utils/auth/useAuth';
import { HeaderMenu } from './HeaderMenu';
import { HEADER_HEIGHT } from '../theme';
// @ts-ignore
import Image from './toolbarbg2.png';
import { useBreakpoints } from '../hooks/useBreakpoints';

const Header = () => {
  const { isLoggedIn } = useAuth();
  const { useStickyHeaders } = useBreakpoints();
  const { isPhone } = useBreakpoints();

  return (
    <AppBar
      position={useStickyHeaders ? 'fixed' : 'sticky'}
      style={{
        backgroundImage: `url(${Image})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '35% 100%',
        backgroundPosition: 'right',
        height: HEADER_HEIGHT,
      }}
    >
      <Toolbar variant={isPhone ? 'regular' : 'dense'}>
        {isLoggedIn && (
          <>
            <Websocket />
          </>
        )}
        <Offline>
          <div
            style={{
              color: 'red',
              marginLeft: '15px',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <span style={{ marginLeft: '10px' }}>Browser is offline</span>
          </div>
        </Offline>
        {isLoggedIn && (
          <Box style={{ width: '100%' }}>
            <HeaderMenu />
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;
