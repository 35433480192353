import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import React from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { XXL_SPACING } from '../../theme';
import { deleteGroup, getGroups, Group } from '../../api/groupapi';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { useAuth } from '../../utils/auth/useAuth';

export const DeleteData = () => {
  const { logout } = useAuth();
  const res = useQuery(
    ['groupapi'],
    async () => {
      const response = await getGroups();
      return response;
    },
    QUERY_CONFIG
  );
  const deleteMutation = useMutation(deleteGroup);

  const deleteGroups = async (groupIds: string[]) => {
    await Promise.allSettled(
      groupIds.map(async (groupId) => {
        await deleteMutation.mutateAsync(groupId);
        console.log('deleted group', groupId);
      })
    );
    logout();
  };

  if (res.isFetched) {
    const groups = ((res.data as Group[]) ?? [])
      .map((g) => g.groupId)
      .filter(Boolean) as string[];
    deleteGroups(groups);
  }
  return (
    <Box style={{ width: '100%', height: '100%' }} sx={{ p: XXL_SPACING }}>
      <Stack>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="h5">
            Deleting all your data and redirecting to logout
          </Typography>

          <CircularProgress sx={{ marginLeft: '50px' }} />
        </Box>
      </Stack>
    </Box>
  );
};
