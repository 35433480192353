import { Box } from '@mui/material';
import React from 'react';
import { THEME_MAIN_COLOR } from '../theme';

export const FilterItemBox = ({
  text,
  selected,
}: {
  text: string;
  selected: boolean;
}) => (
  <Box
    sx={{ p: 1 }}
    style={{
      borderRadius: '4px',
      border: '0.5px solid',
      color: selected ? 'white' : THEME_MAIN_COLOR,
      backgroundColor: selected ? THEME_MAIN_COLOR : 'white',
      fontSize: '10px',
      minWidth: '40px',
    }}
  >
    {text}
  </Box>
);
