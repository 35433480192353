import { Box, Stack } from '@mui/material';
import * as React from 'react';
import { THEME_MAIN_COLOR } from '../../../theme';
import { SubjectItem } from '../../ops/components/types/types';
import { YEAR_PLAN_CARD_HEIGHT_IN_HEADER_SELECTED } from './Commons';
import { SubjectColorBox } from '../../../components/SubjectColorBox';
import { useSubjectColor } from '../../../hooks/useSubjectColor';
import { IconActionButton } from '../../../components/IconActionButton';
import { SubjectTiming } from '../../../api/timetablesapi';
import { LessonInfo } from '../../../components/LessonInfo';
import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { LessonSwitcher } from './LessonSwitcher';

export const LessonHeader = ({
  subject,
  subjectTiming,
  groupName,
  groupId,
  grade,
  year,
  lessonNumber,
  previousLesson,
  nextLesson,
  amountOfLessons,
  smallMode = false,
  switchMode,
  children,
}: {
  subject: SubjectItem;
  subjectTiming?: SubjectTiming;
  groupName: string;
  groupId: string;
  grade: string;
  year: number;
  lessonNumber: number;
  previousLesson: (() => void) | null;
  nextLesson: (() => void) | null;
  amountOfLessons: number;
  smallMode?: boolean;
  switchMode?: () => void;
  children?: JSX.Element;
}) => {
  const { subjectColor } = useSubjectColor(subject.code);
  const { isPhone, usePanels } = useBreakpoints();

  return (
    <SubjectColorBox subjectColor={subjectColor}>
      {children}
      <Stack
        direction={isPhone ? 'column' : 'row'}
        justifyContent="space-between"
        style={{
          height: `${YEAR_PLAN_CARD_HEIGHT_IN_HEADER_SELECTED}px`,
        }}
      >
        <Stack
          direction="column"
          flexGrow={1}
          justifyContent={smallMode ? 'end' : 'start'}
        >
          <LessonInfo
            subject={subject.title}
            group={groupName}
            startAt={subjectTiming?.startAt}
            endAt={subjectTiming?.endAt}
          />
        </Stack>
        <div
          style={{
            display: 'flex',
            flexDirection: smallMode ? 'column' : 'row-reverse',
            marginTop: !usePanels ? '' : 'auto',
            alignItems: smallMode ? 'flex-end' : 'flex-start',
          }}
        >
          {switchMode && (
            <Box style={{ float: 'right', marginBottom: '5px' }}>
              <IconActionButton
                onClick={switchMode}
                color={THEME_MAIN_COLOR}
                name={smallMode ? 'maximize-2' : 'minimize-2'}
                tooltip={smallMode ? 'Maximize' : 'Minimize'}
                size="medium"
              />
            </Box>
          )}
          <Box
            flexGrow={1}
            sx={{ mr: !previousLesson && !nextLesson ? 10 : 0 }}
          >
            <LessonSwitcher
              smallMode={smallMode}
              amountOfLessons={amountOfLessons}
              lessonNumber={lessonNumber}
              nextLesson={nextLesson}
              previousLesson={previousLesson}
              subject={subject}
              groupId={groupId}
              groupName={groupName}
              grade={grade}
              year={year}
            />
          </Box>
        </div>
      </Stack>
    </SubjectColorBox>
  );
};
