import { Topic } from '../api/topicapi';
import {
  ActivityOrderType,
  Note,
  Order,
} from '../routes/my-plan/lesson-plan/ActivityOrder';
import { LinkItem } from '../routes/ops/components/types/types';

type WithOrderInfo = {
  order: {
    orderNumber: number;
    type: ActivityOrderType;
  };
};

type LessonItem = LinkItem | Note;

export type EnhancedLessonItem = LessonItem & WithOrderInfo;

const mapOrder = (
  allLinks: LinkItem[],
  isStudent: boolean,
  type: ActivityOrderType,
  order?: Order[]
): EnhancedLessonItem[] =>
  (order ?? [])
    .filter(
      (o) =>
        (!!o.note && (!isStudent || o.note.showToStudents)) ||
        allLinks?.find((linkItem) => linkItem.linkId === o.activityId)
    )
    .sort((a, b) => (a.orderNumber > b.orderNumber ? 1 : -1))
    .map((orderItem) =>
      orderItem.note
        ? {
            ...orderItem.note,
            order: { orderNumber: orderItem.orderNumber, type },
          }
        : {
            ...allLinks?.find(
              (linkItem) => linkItem.linkId === orderItem.activityId
            )!!,
            order: { orderNumber: orderItem.orderNumber, type },
          }
    );

const getActivitiesAndNotesFromTopicByType = (
  topic: Topic,
  isStudent: boolean,
  types: ActivityOrderType[]
): EnhancedLessonItem[] => {
  const allLinks = topic.tasks?.flatMap((task) => task.allAvailableLinks ?? []);
  return types
    .map((type) =>
      mapOrder(allLinks, isStudent, type, topic.activityOrder?.[type])
    )
    .flat();
};

export const getActivitiesAndNotesFromTopic = (
  topic: Topic,
  isStudent: boolean
): EnhancedLessonItem[] =>
  getActivitiesAndNotesFromTopicByType(topic, isStudent, [
    'initiation',
    'practicing',
    'closing',
  ]);

export const getHomeworkFromTopic = (
  topic: Topic,
  isStudent: boolean
): EnhancedLessonItem[] =>
  getActivitiesAndNotesFromTopicByType(topic, isStudent, ['homework']);
