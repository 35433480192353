import { Grid, Stack } from '@mui/material';
import React, { useState } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import {
  CONTENT_PROGRESS_API_QUERY_KEY,
  getAllStats,
} from '../../api/contentprogressapi';
import { getGroups, Group, GROUPS_QUERY_KEY } from '../../api/groupapi';
import { getFavorites } from '../../api/usersapi';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { GroupFilter } from './GroupFilter';
import { LARGE_SPACING, MEDIUM_SPACING } from '../../theme';
import { getSubjectsByGrade } from '../../utils/ops/getSubjectsByGrade';
import { parseSubjectList } from '../../utils/ops/utils';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { StickyHeader } from '../my-plan/StickyHeader';
import { Params } from '../my-plan/types';
import { SubjectProgressContainer } from './SubjectProgressContainer';
import { TransversalProgressContainer } from './TransversalProgressContainer';
import { GroupsWithFavoritesAndStats } from './types';
import { CenterLoader } from '../../components/CenterLoader';
import { useOps } from '../../hooks/useOps';

const queryFn = (params: Params) => {
  const obj = params.queryKey[1];
  return getAllStats(obj.groupId);
};

export const ProgressPage = () => {
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const { ops } = useOps();
  const groupsResponse = useQuery({
    queryKey: [GROUPS_QUERY_KEY],
    queryFn: getGroups,
    ...QUERY_CONFIG,
  });
  const favoriteQueries = useQueries({
    queries: selectedGroups.map((groupId) => ({
      queryKey: [`favoriteSubjects:${groupId}`],
      queryFn: () => getFavorites(groupId),
      ...QUERY_CONFIG,
    })),
  });
  const statQueries = useQueries({
    queries: selectedGroups.map((groupId) => ({
      queryKey: [CONTENT_PROGRESS_API_QUERY_KEY, { groupId }],
      queryFn,
      ...QUERY_CONFIG,
    })),
  });
  const favoritesLoading = favoriteQueries.some((result) => result.isLoading);
  const statsLoading = statQueries.some((result) => result.isLoading);
  if (!ops || !groupsResponse.data || statsLoading || favoritesLoading) {
    return <CenterLoader />;
  }
  const groups = groupsResponse.data as Group[];

  const selectedGroupsWithData: GroupsWithFavoritesAndStats[] =
    selectedGroups?.map((groupId, index) => {
      const selectedG: Group = groups.find((gr) => gr.groupId === groupId)!!;
      return {
        favorites: favoriteQueries[index].data ?? [],
        stats: statQueries[index].data ?? [],
        ...selectedG,
      };
    });

  const subjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: true }, [
      ...new Set(selectedGroupsWithData?.flatMap((sgwd) => sgwd.favorites)),
    ])
  );

  return (
    <Stack sx={{ p: LARGE_SPACING }}>
      <StickyHeader container>
        <Breadcrumbs currentPage="Progress" previousPages={[]} />
      </StickyHeader>
      <Grid container spacing={2} sx={{ mt: LARGE_SPACING }}>
        <Grid item xs={12} sx={{ mt: LARGE_SPACING, mb: MEDIUM_SPACING }}>
          <GroupFilter
            groups={groups}
            selectedGroups={selectedGroups}
            setSelectedGroups={(groupIds: string[]) =>
              setSelectedGroups(groupIds)
            }
          />
        </Grid>
        <Grid item xs={12}>
          <SubjectProgressContainer
            subjects={subjects}
            groups={selectedGroupsWithData}
          />
        </Grid>
        <Grid item xs={12}>
          <TransversalProgressContainer
            subjects={subjects}
            groups={selectedGroupsWithData}
            ops={ops}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};
