/* eslint-disable import/no-duplicates */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { Box, Stack } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enGB } from 'date-fns/locale';
import { format } from 'date-fns';
import { FeatherIcon } from '../../components/FeatherIcon';
import { ScheduleActionButton } from './ScheduleActionButton';
import { SCHEDULE_WEEK_SELECTION as Z_INDEX } from '../../utils/z-index';
import { getWeekFromDay } from '../../utils/weekhelpers';
import { ScheduleWeek } from '../../generics/types/scheduleWeek';

export const WeekSelection = ({
  week,
  setWeek,
}: {
  week: ScheduleWeek;
  setWeek: (week: ScheduleWeek) => void;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState();
  React.useEffect(() => {
    setTimeout(() => setAnchorEl(anchorRef?.current), 1);
  }, [anchorRef]);

  return (
    <Box ref={anchorRef as any}>
      <LocalizationProvider adapterLocale={enGB} dateAdapter={AdapterDateFns}>
        <DatePicker
          PopperProps={{
            anchorEl,
            style: { zIndex: Z_INDEX },
          }}
          onClose={() => setIsOpen(false)}
          open={isOpen}
          inputFormat="MM/dd/yyyy"
          value={week.startAt}
          onChange={(date: any) => {
            setWeek(getWeekFromDay(date as Date));
          }}
          renderInput={() => (
            <ScheduleActionButton
              onClick={() => setIsOpen(true)}
              buttonRef={anchorRef as any}
            >
              <Stack alignItems="center" style={{ color: 'white' }}>
                <Box>{format(week.startAt, 'MMM')}</Box>
                <FeatherIcon color="white" name="calendar" />
                <Box>{format(week.startAt, 'Y')}</Box>
              </Stack>
            </ScheduleActionButton>
          )}
        />
      </LocalizationProvider>
    </Box>
  );
};
