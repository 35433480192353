import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';

import { useRecoilState } from 'recoil';

import { Objective } from '../routes/ops/components/types/types';
import { ContentOrTransversalView } from './ContentOrTransversalView';
import { cardsOpen } from '../App/state';
import { ContentProgressBulletpointStats } from '../routes/my-plan/types';
import { resolveContentStyle } from './StatStyles';
import {
  DETAIL_COLOR,
  LARGE_SPACING,
  LIGHT_BACKGROUND_COLOR_VARIATION,
  SMALL_SPACING,
  XXL_SPACING,
  XXXL_SPACING,
} from '../theme';
import { FeatherIcon, IconSize } from './FeatherIcon';
import { ObjectiveOrContentWithBorder } from './ObjectiveOrContentWithBorder';
import { ContentBallWrapper } from './ContentBallWrapper';
import { ProgressBarWithIcon } from './ProgressBarWithIcon';
import { getObjectiveStats, isContentCompletedNew } from '../utils/stathelpers';

type Props = {
  gradeId: string;
  objective: Objective;
  subjectCode: string;
  oneSize?: boolean;
  allStats?: ContentProgressBulletpointStats[];
};

const createCardIdentifier = (
  gradeId: string,
  objective: Objective,
  subjectCode: string
): string => gradeId + objective.code + subjectCode;

export const ObjectiveCard = ({
  gradeId,
  objective,
  subjectCode,
  oneSize = false,
  allStats,
}: Props) => {
  const [crdsOpen, setCardsOpen] = useRecoilState(cardsOpen);

  const expanded = crdsOpen?.includes(
    createCardIdentifier(gradeId, objective, subjectCode)
  );

  const setExpanded = () => {
    if (expanded) {
      setCardsOpen(
        crdsOpen.filter(
          (c: string) =>
            c !== createCardIdentifier(gradeId, objective, subjectCode)
        )
      );
    } else {
      setCardsOpen([
        ...crdsOpen,
        createCardIdentifier(gradeId, objective, subjectCode),
      ]);
    }
  };

  const objectiveStats = getObjectiveStats(objective, allStats);

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={oneSize ? 12 : 6}
      xl={oneSize ? 12 : 4}
    >
      {allStats && objectiveStats && (
        <ProgressBarWithIcon
          completed={objectiveStats.completed}
          notCompleted={objectiveStats.notCompleted}
          planned={objectiveStats.planned}
          style={{ paddingLeft: XXXL_SPACING, paddingRight: XXXL_SPACING + 8 }}
        />
      )}
      <Box style={{ height: '100%' }} sx={{ p: LARGE_SPACING, mt: -3 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              style={{
                cursor: 'pointer',
                backgroundColor: LIGHT_BACKGROUND_COLOR_VARIATION,
                borderRadius: '6px',
              }}
              sx={{ p: LARGE_SPACING }}
              onClick={() => setExpanded()}
            >
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ ml: -LARGE_SPACING }}>
                  <ObjectiveOrContentWithBorder
                    objectiveOrContent={objective.code}
                  />
                </Box>
                <Typography variant="body2" style={{ color: '#3E3E3E' }}>
                  {objective.name}
                </Typography>
              </Box>
              <div
                style={{
                  marginLeft: 'auto',
                  marginTop: 'auto',
                  marginBottom: '-14px',
                }}
              >
                <FeatherIcon
                  color={DETAIL_COLOR}
                  name={!expanded ? 'chevron-down' : 'chevron-up'}
                  size={IconSize.MEDIUM}
                />
              </div>
            </Grid>
          </Grid>
          {!expanded ? (
            <Grid
              item
              container
              spacing={1}
              xs={12}
              sx={{ mt: SMALL_SPACING, ml: XXL_SPACING }}
            >
              {[
                ...(objective.contents ?? []),
                ...(objective.transversals ?? []),
              ].map((it, index) => {
                const statStyle = resolveContentStyle(
                  Object.keys(it.bulletpoints),
                  allStats
                );
                const contentCompletionStatus = isContentCompletedNew(
                  Object.keys(it.bulletpoints),
                  allStats
                );

                const showHalfFill =
                  contentCompletionStatus !== null && !contentCompletionStatus;
                return (
                  <ContentBallWrapper
                    // eslint-disable-next-line react/no-array-index-key
                    key={it.code + index}
                    contentCode={it.code}
                    statStyle={statStyle}
                    alignRight={false}
                    showHalfFill={showHalfFill}
                  />
                );
              })}
            </Grid>
          ) : null}
          {expanded ? (
            <Grid
              item
              container
              spacing={1}
              xs={12}
              sx={{ mt: SMALL_SPACING, ml: XXL_SPACING }}
            >
              {[
                ...(objective.contents ?? []),
                ...(objective.transversals ?? []),
              ].map((it) => (
                <ContentOrTransversalView
                  key={it.code}
                  item={it}
                  allStats={allStats}
                />
              ))}
            </Grid>
          ) : null}
        </Grid>
      </Box>
    </Grid>
  );
};
