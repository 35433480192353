import { Box, Typography } from '@mui/material';
import React from 'react';
import { COMPLETED_COLOR, DETAIL_COLOR } from '../theme';
import { ContentBall } from './Balls';
import { CONTENT_BALL_WRAPPER as Z_INDEX } from '../utils/z-index';

export const ContentBallWrapper = ({
  statStyle,
  contentCode,
  alignRight = false,
  showHalfFill = false,
}: {
  statStyle?: { backgroundColor: string; color?: string };
  contentCode: string;
  alignRight?: boolean;
  showHalfFill?: boolean;
}) => (
  <ContentBall
    style={{
      ...(statStyle ?? {}),
      marginTop: '8px',
      border: `2px solid ${statStyle?.color ?? DETAIL_COLOR}`,
      position: 'relative',
    }}
    color={statStyle?.color ?? DETAIL_COLOR}
  >
    {showHalfFill && (
      <Box
        style={{
          width: '30px',
          height: '15px',
          backgroundColor: COMPLETED_COLOR,
          position: 'absolute',
          top: '13px',
          left: '-4.3px',
          zIndex: Z_INDEX,
          borderRadius: `50% 50% 0 0 / 100% 100% 0 0`,
          borderBottom: 0,
          border: `8px solid ${COMPLETED_COLOR}`,
          transform: 'rotate(0.60turn)',
        }}
      />
    )}

    <Typography
      variant="body1"
      style={{ color: statStyle?.color ?? DETAIL_COLOR, zIndex: Z_INDEX + 1 }}
      align={alignRight ? 'right' : undefined}
    >
      {contentCode.substring(0, 3)}
    </Typography>
  </ContentBall>
);
