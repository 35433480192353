import React from 'react';
import { NameAndGradeView } from './NameAndGradeView';

export const NameAndGrade = ({
  name,
  setName,
  grade,
  setGrade,
  availableGrades = [],
  panelMode = false,
  saveAndGoToNextPhase,
}: {
  name: string;
  setName?: (n: string) => void;
  grade?: string;
  setGrade?: (g: string) => void;
  availableGrades?: string[];
  panelMode?: boolean;
  saveAndGoToNextPhase?: () => void;
}) => (
  <NameAndGradeView
    panelMode={panelMode}
    name={name}
    setName={(n: string) => setName && setName(n)}
    grade={grade}
    setGrade={(g: string) => setGrade && setGrade(g)}
    availableGrades={availableGrades}
    saveAndGoToNextPhase={() => saveAndGoToNextPhase && saveAndGoToNextPhase()}
  />
);
