/* eslint-disable @typescript-eslint/indent */
import { CircularProgress, Grid, Stack } from '@mui/material';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  CONTENT_PROGRESS_API_QUERY_KEY,
  getAllStats,
} from '../../api/contentprogressapi';
import { useFavoriteSubjects } from '../../hooks/useFavoriteSubjects';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { subjectShortForm } from '../../utils/subjectShortForm';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import { TransversalItemWithInfo } from '../ops/components/types/types';
import { getSubjectsByGrade } from '../../utils/ops/getSubjectsByGrade';
import { parseSubjectList } from '../../utils/ops/utils';
import { TransversalProgressCard } from './TransversalProgressCard';
import { parseTransversalsMap } from './TransversalHelpers';
import { TransversalProgressHeader } from './TransversalProgressHeader';
import { useSelectedGroup } from '../../hooks/useSelectedGroup';
import { LARGE_SPACING } from '../../theme';
import { CenterLoader } from '../../components/CenterLoader';
import { useOps } from '../../hooks/useOps';

// @ts-ignore
const queryFn = (obj: unknown) => getAllStats(obj.groupId);

export const TransversalProgressPage = () => {
  const { transversal, transversalCode } = useParams();
  const group = useSelectedGroup();
  const { favoriteSubjects } = useFavoriteSubjects(group?.groupId);
  const { ops } = useOps();
  const { data: bpdata } = useQuery({
    queryKey: [CONTENT_PROGRESS_API_QUERY_KEY, { groupId: group?.groupId }],
    queryFn,
    ...QUERY_CONFIG,
    enabled: !!group?.groupId,
  });
  if (
    !ops ||
    !bpdata ||
    !favoriteSubjects ||
    favoriteSubjects[0]?.slice(-1) !== group?.grade
  ) {
    return <CircularProgress />;
  }
  const subjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: true }, favoriteSubjects)
  );
  const bpStats: ContentProgressBulletpointStats[] = bpdata.length
    ? bpdata
    : [];
  const transversals = parseTransversalsMap(subjects);
  const currentTransversals = transversals.get(
    Array.from(transversals.keys()).find((key) => key === transversal)!!
  );
  const groupedBySubj = new Map<string, TransversalItemWithInfo[]>();

  currentTransversals?.forEach((ct) => {
    const subjectCode = subjectShortForm(
      ct.subject.abbreviation,
      ct.grade.grade
    );
    const existing = groupedBySubj.get(subjectCode);
    if (existing) {
      groupedBySubj.set(subjectCode, [...existing, ct]);
    } else {
      groupedBySubj.set(subjectCode, [ct]);
    }
  });

  if (!currentTransversals || !transversal || !transversalCode) {
    return <CenterLoader />;
  }
  return (
    <Stack sx={{ p: LARGE_SPACING }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TransversalProgressHeader
            grade={currentTransversals[0]?.grade.grade.toString()}
            transversal={transversal}
            transversalCode={transversalCode}
            transversalItems={currentTransversals}
            bulletpointStats={bpStats}
          />
        </Grid>
        {Array.from(groupedBySubj.keys()).map((subj) => (
          <TransversalProgressCard
            key={subj}
            groupId={group?.groupId}
            bulletpointStats={bpStats}
            transversals={groupedBySubj.get(subj)!!}
            subjects={subjects}
          />
        ))}
      </Grid>
    </Stack>
  );
};
