import { sortOnlyByNumber } from '../../../utils/ops/utils';
import {
  ContentItem,
  GradeItem,
  TransversalItem,
} from '../../ops/components/types/types';

export interface Bulletpoint {
  bulletpointId: string;
  description: string;
}

// For example content C1 with bulletpoints from all objectives O1 O3 that have C1.
export interface ContentWithAllBulletpoints {
  contentId: string;
  title: string;
  bulletpoints: Bulletpoint[];
}

export const mapGradeItem = (
  gradeItem: GradeItem
): ContentWithAllBulletpoints[] =>
  (
    gradeItem?.objectives
      ?.flatMap((objective) => [
        ...(objective.contents ?? []),
        ...(objective.transversals ?? []),
      ])
      .filter((c) => !!c)
      .reduce(
        (
          all: ContentWithAllBulletpoints[],
          current: ContentItem | TransversalItem
        ) => {
          const foundContent = all.find(
            (a) => a.contentId === current.code && a.title === current.name
          );
          if (foundContent) {
            return [
              ...all.filter((al) => al.contentId !== current.code),
              {
                ...foundContent,
                bulletpoints: [
                  ...foundContent.bulletpoints,
                  ...Object.entries(current.bulletpoints).map((arr) => ({
                    bulletpointId: arr[0],
                    description: arr[1],
                  })),
                ],
              },
            ];
          }
          return [
            ...all,
            {
              contentId: current.code,
              title: current.name,
              bulletpoints: Object.entries(current.bulletpoints).map((arr) => ({
                bulletpointId: arr[0],
                description: arr[1],
              })),
            },
          ];
        },
        [] as ContentWithAllBulletpoints[]
      ) ?? []
  )
    .sort((a, b) => sortOnlyByNumber(a.contentId, b.contentId))
    .sort((a, b) => {
      const first = a.contentId.charAt(0);
      const second = b.contentId.charAt(0);
      if (first < second) {
        return -1;
      }
      if (first > second) {
        return 1;
      }
      return 0;
    });

export interface SelectedGroupSubject {
  group: { name: string; groupId: string };
  subject: string;
  grade: number;
  year: number;
  isFavorite?: boolean;
}

export const getAmountOfLessonsForSubjects = (): {
  subjectCode: string;
  grade: number;
  amountOfLessons: number;
}[] => [
  { subjectCode: 'Crafts', grade: 1, amountOfLessons: 76 },
  { subjectCode: 'Crafts', grade: 2, amountOfLessons: 76 },
  { subjectCode: 'Crafts', grade: 3, amountOfLessons: 76 },
  { subjectCode: 'Crafts', grade: 4, amountOfLessons: 76 },
  { subjectCode: 'Crafts', grade: 5, amountOfLessons: 76 },
  { subjectCode: 'Crafts', grade: 6, amountOfLessons: 76 },
  { subjectCode: 'English Language', grade: 1, amountOfLessons: 190 },
  { subjectCode: 'English Language', grade: 2, amountOfLessons: 190 },
  { subjectCode: 'English Language', grade: 3, amountOfLessons: 190 },
  { subjectCode: 'English Language', grade: 4, amountOfLessons: 190 },
  { subjectCode: 'English Language', grade: 5, amountOfLessons: 152 },
  { subjectCode: 'English Language', grade: 6, amountOfLessons: 152 },
  { subjectCode: 'Environmental Studies', grade: 1, amountOfLessons: 76 },
  { subjectCode: 'Environmental Studies', grade: 2, amountOfLessons: 76 },
  { subjectCode: 'Environmental Studies', grade: 3, amountOfLessons: 76 },
  { subjectCode: 'Environmental Studies', grade: 4, amountOfLessons: 114 },
  { subjectCode: 'Environmental Studies', grade: 5, amountOfLessons: 114 },
  { subjectCode: 'Environmental Studies', grade: 6, amountOfLessons: 76 },
  { subjectCode: 'Ethics And World Religions', grade: 1, amountOfLessons: 38 },
  { subjectCode: 'Ethics And World Religions', grade: 2, amountOfLessons: 38 },
  { subjectCode: 'Ethics And World Religions', grade: 3, amountOfLessons: 38 },
  { subjectCode: 'Ethics And World Religions', grade: 4, amountOfLessons: 38 },
  { subjectCode: 'Ethics And World Religions', grade: 5, amountOfLessons: 76 },
  { subjectCode: 'Ethics And World Religions', grade: 6, amountOfLessons: 38 },
  {
    subjectCode: 'Georgian As A Second Language',
    grade: 1,
    amountOfLessons: 190,
  },
  {
    subjectCode: 'Georgian As A Second Language',
    grade: 2,
    amountOfLessons: 190,
  },
  {
    subjectCode: 'Georgian As A Second Language',
    grade: 3,
    amountOfLessons: 152,
  },
  {
    subjectCode: 'Georgian As A Second Language',
    grade: 4,
    amountOfLessons: 152,
  },
  {
    subjectCode: 'Georgian As A Second Language',
    grade: 5,
    amountOfLessons: 114,
  },
  {
    subjectCode: 'Georgian As A Second Language',
    grade: 6,
    amountOfLessons: 114,
  },
  { subjectCode: 'Georgian Language', grade: 1, amountOfLessons: 190 },
  { subjectCode: 'Georgian Language', grade: 2, amountOfLessons: 190 },
  { subjectCode: 'Georgian Language', grade: 3, amountOfLessons: 152 },
  { subjectCode: 'Georgian Language', grade: 4, amountOfLessons: 152 },
  { subjectCode: 'Georgian Language', grade: 5, amountOfLessons: 114 },
  { subjectCode: 'Georgian Language', grade: 6, amountOfLessons: 114 },
  { subjectCode: 'History', grade: 5, amountOfLessons: 38 },
  { subjectCode: 'History', grade: 6, amountOfLessons: 38 },
  { subjectCode: 'Mathematics', grade: 1, amountOfLessons: 114 },
  { subjectCode: 'Mathematics', grade: 2, amountOfLessons: 114 },
  { subjectCode: 'Mathematics', grade: 3, amountOfLessons: 114 },
  { subjectCode: 'Mathematics', grade: 4, amountOfLessons: 152 },
  { subjectCode: 'Mathematics', grade: 5, amountOfLessons: 152 },
  { subjectCode: 'Mathematics', grade: 6, amountOfLessons: 152 },
  { subjectCode: 'Music', grade: 1, amountOfLessons: 38 },
  { subjectCode: 'Music', grade: 2, amountOfLessons: 38 },
  { subjectCode: 'Music', grade: 3, amountOfLessons: 38 },
  { subjectCode: 'Music', grade: 4, amountOfLessons: 38 },
  { subjectCode: 'Music', grade: 5, amountOfLessons: 38 },
  { subjectCode: 'Music', grade: 6, amountOfLessons: 38 },
  { subjectCode: 'Physical Education', grade: 1, amountOfLessons: 76 },
  { subjectCode: 'Physical Education', grade: 2, amountOfLessons: 76 },
  { subjectCode: 'Physical Education', grade: 3, amountOfLessons: 114 },
  { subjectCode: 'Physical Education', grade: 4, amountOfLessons: 114 },
  { subjectCode: 'Physical Education', grade: 5, amountOfLessons: 76 },
  { subjectCode: 'Physical Education', grade: 6, amountOfLessons: 76 },
  { subjectCode: 'Social Studies', grade: 4, amountOfLessons: 38 },
  { subjectCode: 'Social Studies', grade: 5, amountOfLessons: 38 },
  { subjectCode: 'Social Studies', grade: 6, amountOfLessons: 38 },
  { subjectCode: 'Visual Arts', grade: 1, amountOfLessons: 38 },
  { subjectCode: 'Visual Arts', grade: 2, amountOfLessons: 38 },
  { subjectCode: 'Visual Arts', grade: 3, amountOfLessons: 76 },
  { subjectCode: 'Visual Arts', grade: 4, amountOfLessons: 76 },
  { subjectCode: 'Visual Arts', grade: 5, amountOfLessons: 76 },
  { subjectCode: 'Visual Arts', grade: 6, amountOfLessons: 76 },
];

export const getAmountOfLessonsForSubject = (
  subjectCode: string,
  grade: number
): number =>
  getAmountOfLessonsForSubjects().find(
    (amount) => amount.subjectCode === subjectCode && amount.grade === grade
  )?.amountOfLessons ?? 99;
