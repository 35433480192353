/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/indent */
import { SubjectItem } from '../../routes/ops/components/types/types';

export const getSubjectsByGrade = (
  subjectList: Map<String, SubjectItem>,
  selectedGrade?: number
): SubjectItem[] =>
  selectedGrade != null
    ? Array.from(subjectList.values()).filter((subject: SubjectItem) =>
        subject.grades?.map((it) => it.grade).includes(selectedGrade)
      )
    : Array.from(subjectList.values());
