export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const OAUTH_LOGIN = `${LOGIN_URL}`;

export const FAVORITES_API_URL = `${API_BASE_URL}/v3/favorites`;
export const FAVORITES_STUDENT_API_URL = `${API_BASE_URL}/student/v1/favorites`;
export const OPS_BUCKET_URL = `https://curriculum-ops-${ENVIRONMENT}.s3-eu-west-1.amazonaws.com/?list-type=2`;
export const OPS_API_URL = (id: string) =>
  `https://curriculum-ops-${ENVIRONMENT}.s3-eu-west-1.amazonaws.com/${id}.json`;
export const LINKS_API_URL = `${API_BASE_URL}/v3/links`;
export const BOOKS_API_URL = `${API_BASE_URL}/v1/books`;
export const ADMIN_API_URL = `${API_BASE_URL}/v1/admin`;
export const TOPIC_API_URL = `${API_BASE_URL}/v1/topic`;

export const CONTENT_PROGRESS_API_URL = `${API_BASE_URL}/v3/progress`;
export const CONTENT_PROGRESS_STUDENT_API_URL = `${API_BASE_URL}/student/v1/progress`;
export const GROUP_API_URL = `${API_BASE_URL}/v3/groups`;
export const STUDENT_GROUP_API_URL = `${API_BASE_URL}/student/v1/groups`;
export const STUDENT_API_URL = `${API_BASE_URL}/v3/students`;
export const TIME_TABLE_API_URL = `${API_BASE_URL}/v1/timetables`;
export const TIME_TABLE_STUDENT_API_URL = `${API_BASE_URL}/student/v1/timetables`;
export const SCHEDULE_API_URL = `${API_BASE_URL}/v1/schedule`;
export const SCHEDULE_STUDENT_API_URL = `${API_BASE_URL}/student/v1/schedule`;

export const USER_API_URL = `${API_BASE_URL}/v1/user`;
