/* eslint-disable @typescript-eslint/indent */
import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useFavoriteSubjects } from '../../hooks/useFavoriteSubjects';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { ContentProgressBulletpointStats, Params } from '../my-plan/types';
import { getSubjectsByGrade } from '../../utils/ops/getSubjectsByGrade';
import { parseSubjectList } from '../../utils/ops/utils';
import { useSelectedGroup } from '../../hooks/useSelectedGroup';
import { LARGE_SPACING, XXL_SPACING } from '../../theme';
import { ObjectiveCard } from '../../components/ObjectiveCard';
import { CenterLoader } from '../../components/CenterLoader';
import * as contentProgressApi from '../../api/contentprogressapi';
import { useOps } from '../../hooks/useOps';

const fetchAllStats = (params: Params) => {
  const obj = params.queryKey[1];
  return contentProgressApi.getAllStats(obj.groupId);
};

const formatGrade = (grade: string): string => {
  switch (grade) {
    case '1':
      return '1st';
    case '2':
      return '2nd';
    case '3':
      return '3rd';
    case '4':
      return '4th';
    case '5':
      return '5th';
    case '6':
      return '6th';
    default:
      return '';
  }
};

export const SubjectProgressPage = () => {
  const { subject, grade } = useParams();
  const group = useSelectedGroup();
  const { favoriteSubjects } = useFavoriteSubjects(group?.groupId);
  const { ops } = useOps();
  const allStats = useQuery({
    queryKey: [
      contentProgressApi.CONTENT_PROGRESS_API_QUERY_KEY,
      { groupId: group?.groupId },
    ],
    queryFn: fetchAllStats,
    ...QUERY_CONFIG,
    enabled: !!group?.groupId,
  });
  if (
    !ops ||
    !favoriteSubjects ||
    !group ||
    !subject ||
    !grade ||
    favoriteSubjects[0]?.slice(-1) !== group?.grade ||
    !allStats?.data
  ) {
    return <CenterLoader />;
  }

  const subjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: true }, favoriteSubjects)
  );
  const aSt: ContentProgressBulletpointStats[] = allStats?.data ?? [];
  return (
    <Stack sx={{ p: LARGE_SPACING }}>
      <Box sx={{ p: XXL_SPACING }}>
        <Typography variant="h6">
          <b>{subject}</b> - {formatGrade(grade)} grade
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ mt: LARGE_SPACING }}>
        {subjects
          .find((foundSubject) => foundSubject.code === subject)
          ?.grades?.find((_grade) => _grade.grade.toString() === grade)
          ?.objectives?.map((objective) => (
            <ObjectiveCard
              gradeId={grade.toString()}
              key={objective.code}
              objective={objective}
              subjectCode={subject}
              allStats={aSt}
            />
          ))}
      </Grid>
    </Stack>
  );
};
