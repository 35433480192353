import { Box, Typography } from '@mui/material';
import React from 'react';
import { FeatherIcon, IconSize } from './FeatherIcon';

export const BulletpointTimesPlanned = ({
  timesPlanned,
  style,
  sx,
  useIcon = true,
  fontColor = '#245F73',
}: {
  timesPlanned: number;
  style?: { [key: string]: string | number };
  sx?: { [key: string]: string | number };
  useIcon?: boolean;
  fontColor?: string;
}) => (
  <Box
    style={{
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      ...style,
    }}
    sx={sx}
  >
    {useIcon && (
      <FeatherIcon
        size={IconSize['EXTRA-SMALL']}
        name="copy"
        style={{ marginRight: '4px' }}
        color="#245F73"
      />
    )}
    <Typography
      variant="caption"
      style={{
        color: fontColor,
        fontSize: '12px',
        fontWeight: '400',
      }}
    >
      {timesPlanned}
    </Typography>
  </Box>
);
