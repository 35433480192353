import { Chip, Typography } from '@mui/material';
import React from 'react';
import { useFavoriteSubjects } from '../../../hooks/useFavoriteSubjects';
import { parseNumber } from '../../../utils/string';
import { SelectSubjects } from '../SelectSubjects';
import { SubjectItem } from '../../ops/components/types/types';
import { GroupSubjectHeader } from './GroupSubjectHeader';
import { BackAndNextButtons } from '../BackAndNextButtons';
import { THEME_MAIN_COLOR } from '../../../theme';

export const GroupSubjects = ({
  grade,
  groupId,
  subjects,
  panelMode = false,
  previousPhase,
  nextPhase,
}: {
  grade?: string;
  groupId?: string;
  subjects: SubjectItem[];
  panelMode?: boolean;
  previousPhase?: () => void;
  nextPhase?: () => void;
}) => {
  const { favoriteSubjects, toggleFavoriteSubject } =
    useFavoriteSubjects(groupId);

  return (
    <>
      <GroupSubjectHeader checked={!!favoriteSubjects?.length} />
      {panelMode && !!favoriteSubjects?.length && (
        <div style={{ marginTop: '10px' }}>
          {favoriteSubjects
            .filter((subj) => subj.includes(grade!!))
            .map((subj) => (
              <Chip
                sx={{ m: 1 }}
                size="small"
                style={{
                  color: '#FFFFFF',
                  backgroundColor: THEME_MAIN_COLOR,
                }}
                key={`${subj}-chip`}
                label={
                  <Typography variant="caption">
                    {subj.split('-')[0]}
                  </Typography>
                }
              />
            ))}
        </div>
      )}
      {grade && !panelMode && (
        <>
          <SelectSubjects
            grade={parseNumber(grade)!!}
            subjects={subjects}
            toggleFavoriteSubject={toggleFavoriteSubject}
            favoriteSubjects={favoriteSubjects}
          />
          <BackAndNextButtons
            back={() => previousPhase && previousPhase()}
            next={() => nextPhase && nextPhase()}
            nextDisabled={!favoriteSubjects?.length}
          />
        </>
      )}
    </>
  );
};
