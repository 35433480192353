import {
  ContentItem,
  GradeItem,
  Objective,
} from '../routes/ops/components/types/types';
import { ContentProgress } from '../routes/my-plan/types';

export const getObjectiveAndContent = (
  g: GradeItem,
  task: ContentProgress
): { objective: Objective; content?: ContentItem } => {
  const objective: Objective = g?.objectives?.find(
    (o) => o.code === task.objectiveId
  )!!;
  const content: ContentItem | undefined = [
    ...(objective.contents ?? []),
    ...(objective.transversals ?? []),
  ].find(
    (contentOrTransversal) => contentOrTransversal.code === task.contentId
  );
  return { objective, content };
};
