import { Card, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { Group } from '../../../api/groupapi';
import { groupEditorGroup } from '../../../App/state';
import { THEME_MAIN_COLOR } from '../../../theme';
import { Phase } from '../phase';
import { GroupStudentsSummary } from '../GroupStudentsSummary';

type Props = {
  group: Group;
  setPhase: (p: Phase) => void;
};

export const GroupItem = ({ group, setPhase }: Props) => {
  const [activeGroup, setGroup] = useRecoilState(groupEditorGroup);

  const isActive = activeGroup?.groupId === group.groupId;
  return (
    <Grid item key={group.groupId} xs={12} sm={6} md={4} lg={3}>
      <Card
        sx={{ p: 2 }}
        onClick={() => {
          if (isActive) {
            setGroup(undefined);
            setPhase(Phase.VIEW_GROUPS);
          } else {
            setGroup(group);
            setPhase(Phase.VIEW_GROUP);
          }
        }}
        variant="outlined"
        style={{
          cursor: 'pointer',
          border: isActive ? 'rgb(63, 81, 181) 3px solid' : '0.5px solid black',
        }}
      >
        <Typography variant="h6" style={{ color: THEME_MAIN_COLOR }}>
          {group.name}
        </Typography>
        <GroupStudentsSummary
          existingStudentEmails={group?.existingStudentEmails?.length ?? 0}
          allStudentEmails={group?.students?.length ?? 0}
        />
      </Card>
    </Grid>
  );
};
