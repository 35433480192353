import React from 'react';

import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { Box, Button, Stack, Typography } from '@mui/material';
import {
  ContentItem,
  LinkItem,
  SubjectItem,
  TransversalItem,
} from '../../../ops/components/types/types';
import * as linksApi from '../../../../api/linksapi';
import { createLinksApiQueryKey } from '../../../../api/linksapi';
import { QUERY_CONFIG } from '../../../../utils/queryconfig';
import { useAuth } from '../../../../utils/auth/useAuth';
import {
  BOLD_FONT_WEIGHT,
  LARGE_SPACING,
  XXL_SPACING,
} from '../../../../theme';
import { FeatherIcon, IconSize } from '../../../../components/FeatherIcon';
import { ActivityEditor } from '../../../my-plan/lesson-plan/ActivityEditor';
import { TimeLineActivityItem } from '../../../my-plan/lesson-plan/TimeLineActivityItem';
import { useRemoveLink } from '../../../../hooks/useRemoveLink';

const ActivityButton = ({
  createActivityClicked,
}: {
  createActivityClicked: () => void;
}) => (
  <Button variant="text" onClick={createActivityClicked}>
    <FeatherIcon name="plus" />
    Activity
  </Button>
);

const NoLinks = ({
  createActivityClicked,
}: {
  createActivityClicked: () => void;
}) => (
  <Box style={{ display: 'flex', justifyContent: 'center' }}>
    <Stack
      style={{
        width: '60%',
        backgroundColor: '#F7F8F9',
        borderRadius: '19px',
        gap: '10px',
      }}
      sx={{ p: XXL_SPACING }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '10px',
        }}
      >
        <FeatherIcon name="book" size={IconSize.LARGE} color="#245F73" />
        <FeatherIcon name="sun" size={IconSize.LARGE} color="#245F73" />
        <FeatherIcon name="link" size={IconSize.LARGE} color="#245F73" />
      </Box>
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body2" style={{ fontWeight: BOLD_FONT_WEIGHT }}>
          NO ACTIVITY
        </Typography>
      </Box>
      <ActivityButton createActivityClicked={createActivityClicked} />
    </Stack>
  </Box>
);

export const Links = ({
  gradeId,
  item,
  subjectCode,
  selectedLinkBulletpoint,
  subjectItem,
  description,
}: {
  gradeId: string;
  item: TransversalItem | ContentItem;
  subjectCode: string;
  selectedLinkBulletpoint: string;
  subjectItem: SubjectItem;
  description: string;
}) => {
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [linkEditorOpen, setLinkEditorOpen] = React.useState(false);
  const [editItem, setEditItem] = React.useState<LinkItem | undefined>();
  const linksApiQueryKey = createLinksApiQueryKey(subjectCode, gradeId);
  const links = useQuery({
    queryKey: [linksApiQueryKey],
    queryFn: () => linksApi.getBySubjectAndGradeId(subjectCode, gradeId),
    enabled: !!item,
    ...QUERY_CONFIG,
  });

  const { user } = useAuth();
  // @ts-ignore
  const linksData: LinkItem[] = links?.data?.length ? links.data : [];
  const linksDataWithOwner = linksData
    .map((link) => ({
      ...link,
      owner: link.pk?.split('USER#')[1] === user?.sub,
    }))
    .filter((l) => l.bulletpointIds.includes(selectedLinkBulletpoint));
  const createNotification = (translationId: string, success = true) => {
    enqueueSnackbar(intl.formatMessage({ id: translationId }), {
      variant: success ? 'success' : 'error',
    });
  };

  const { removeLink } = useRemoveLink({
    subjectCode,
    gradeId,
    onSuccess: () => createNotification('notifications.activity.removed', true),
    onError: () => createNotification('notifications.genericError', false),
  });

  if (!links.isSuccess) {
    return <></>;
  }

  return (
    <>
      <Stack
        style={{ backgroundColor: '#F1F4F6' }}
        sx={{ py: LARGE_SPACING, px: XXL_SPACING }}
      >
        <Box>
          <Typography variant="body2">Activities for</Typography>
        </Box>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="body1" style={{ fontWeight: BOLD_FONT_WEIGHT }}>
            {description}
          </Typography>
          {linksDataWithOwner?.length > 0 && (
            <ActivityButton
              createActivityClicked={() => setLinkEditorOpen(true)}
            />
          )}
        </Box>
      </Stack>
      <Box sx={{ py: LARGE_SPACING, px: XXL_SPACING }}>
        {linkEditorOpen && (
          <ActivityEditor
            activity={editItem}
            preDefinedBulletpoint={
              editItem ? undefined : selectedLinkBulletpoint
            }
            editingOver={(a) => {
              setLinkEditorOpen(false);
              setEditItem(undefined);
            }}
            subject={subjectItem}
            allTasks={[]}
            grade={gradeId}
          />
        )}
        {linksDataWithOwner
          .filter((linksDataItem) => linksDataItem.linkId !== editItem?.linkId)
          .map((activity) => (
            <Box
              key={`${
                activity.bulletpointIds.join() + activity.linkId
              }linkItem`}
              sx={{ my: LARGE_SPACING }}
            >
              <TimeLineActivityItem
                {...activity}
                allTasks={undefined}
                actionMenuItems={[
                  {
                    key: 'edit',
                    onClick: async () => {
                      setLinkEditorOpen(false);
                      setEditItem(undefined);
                      await new Promise((resolve) => setTimeout(resolve, 100));
                      setLinkEditorOpen(true);
                      setEditItem(activity);
                    },
                    children: intl.formatMessage({
                      id: 'noteOrActivity.edit',
                    }),
                  },
                  {
                    key: 'delete',
                    onClick: () => removeLink({ linkId: activity.linkId }),
                    children: intl.formatMessage({
                      id: 'noteOrActivity.remove',
                    }),
                  },
                ].filter(
                  (activityItem) =>
                    activityItem.key !== 'edit' || activity.isEditable
                )}
              />
            </Box>
          ))}
        {!linksDataWithOwner?.length && !linkEditorOpen && (
          <NoLinks createActivityClicked={() => setLinkEditorOpen(true)} />
        )}
      </Box>
    </>
  );
};
