import React from 'react';
import { Button, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import {
  BOLD_FONT_WEIGHT,
  EVEN_THICKER_SELECTED_DETAIL_BORDER,
  GREY_BACKGROUND,
  LARGE_SPACING,
  MEDIUM_SPACING,
  THEME_MAIN_TEXT_COLOR,
} from '../theme';
import { TabStyle } from './CommonTabs';

export const CommonTab = ({
  tabTranslationId,
  selected,
  clickTab,
  disabled = false,
  tabStyle = TabStyle.TEXT,
  style = {},
}: {
  tabTranslationId: string;
  selected: boolean;
  clickTab?: () => void;
  disabled?: boolean;
  tabStyle?: TabStyle;
  style?: Record<string, string | number>;
}) => {
  const intl = useIntl();

  return (
    <Button
      variant="text"
      onClick={clickTab}
      disabled={selected || disabled}
      style={{
        borderRadius: 0,
        textTransform: 'none',
        ...(selected && tabStyle === TabStyle.TEXT
          ? { borderBottom: EVEN_THICKER_SELECTED_DETAIL_BORDER }
          : {}),
        ...(!selected && tabStyle === TabStyle.BACKGROUND
          ? { background: GREY_BACKGROUND }
          : {}),
        ...(tabStyle === TabStyle.BACKGROUND
          ? { width: '100%', flexBasis: 0, flexGrow: 1 }
          : {}),
        ...style,
      }}
      sx={{
        px: LARGE_SPACING,
        py: MEDIUM_SPACING,
      }}
    >
      <Typography
        variant="h5"
        style={{
          fontSize: '18px',
          color: THEME_MAIN_TEXT_COLOR,
          letterSpacing: '0px',
          ...(selected ? { fontWeight: BOLD_FONT_WEIGHT } : {}),
          ...(disabled ? { opacity: 0.5 } : {}),
        }}
      >
        {intl.formatMessage({ id: tabTranslationId })}
      </Typography>
    </Button>
  );
};
