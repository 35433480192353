import { Box, Stack, Typography, Popover } from '@mui/material';
import React, { useState } from 'react';
import {
  XXL_SPACING,
  DETAIL_COLOR,
  MEDIUM_SPACING,
  THEME_MAIN_TEXT_COLOR,
} from '../../../theme';
import { ContentItem, Objective } from '../../ops/components/types/types';
import { ObjectiveOrContentWithBorder } from '../../../components/ObjectiveOrContentWithBorder';
import { FeatherIcon } from '../../../components/FeatherIcon';
import { OBJECTIVE_POPOVER } from '../../../utils/z-index';

export const ObjectivePopoverIcon = ({
  objective,
  content,
  iconColor = THEME_MAIN_TEXT_COLOR,
}: {
  objective: Objective;
  content?: ContentItem;
  iconColor?: string;
}) => {
  const [popoverAnchor, setPopoverAnchor] = useState<HTMLElement | null>(null);
  const [touchTimeoutId, setTouchTimeoutId] = useState<NodeJS.Timeout | null>(
    null
  );

  // Custom touch event handling to reduce default delay of 700ms
  const handleTouchStart = (evt: React.TouchEvent) => {
    evt.preventDefault();
    const id = setTimeout(
      () => setPopoverAnchor(evt.currentTarget as HTMLElement),
      200
    );
    setTouchTimeoutId(id);
  };

  const handleTouchEnd = () => {
    clearTimeout(touchTimeoutId as NodeJS.Timeout);
    setPopoverAnchor(null);
  };

  return (
    <>
      <div
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onMouseEnter={(e) => {
          setPopoverAnchor(e.currentTarget);
        }}
        onMouseLeave={(e) => {
          setPopoverAnchor(null);
        }}
      >
        <FeatherIcon color={iconColor} name="info" />
      </div>
      <ObjectivePopover
        anchorEl={popoverAnchor}
        objective={objective}
        content={content}
      />
    </>
  );
};

const ObjectivePopover = ({
  anchorEl,
  objective,
  content,
}: {
  anchorEl: HTMLElement | null;
  objective: Objective;
  content?: ContentItem;
}) => {
  const objectiveDesc = objective?.name;
  const contentDesc = content?.name;

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      sx={{
        pointerEvents: 'none',
        zIndex: OBJECTIVE_POPOVER,
      }}
    >
      <Stack
        direction="column"
        gap={MEDIUM_SPACING}
        sx={{ p: MEDIUM_SPACING, pr: XXL_SPACING }}
      >
        <Stack direction="row">
          <ObjectiveOrContentWithBorder objectiveOrContent={objective.code} />
          <Stack justifyContent="center" direction="column">
            {objectiveDesc}
          </Stack>
        </Stack>
        {content && (
          <Stack direction="row">
            <Box sx={{ ml: MEDIUM_SPACING, display: 'flex' }}>
              <Box
                style={{
                  border: `2px solid ${DETAIL_COLOR}`,
                  color: DETAIL_COLOR,
                  borderRadius: '50%',
                  width: '33px',
                  height: '34px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                sx={{ mr: MEDIUM_SPACING }}
              >
                <Typography variant="caption" style={{ fontSize: '11px' }}>
                  {content?.code}
                </Typography>
              </Box>
            </Box>
            <Stack direction="column" justifyContent="center">
              {contentDesc}
            </Stack>
          </Stack>
        )}
      </Stack>
    </Popover>
  );
};
