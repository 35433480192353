import { getYear, isBefore, parseISO } from 'date-fns';

const safeParse = (date: Date | string): Date =>
  typeof date === 'string' ? parseISO(date) : date;

const getEndOfSchoolYear = (year: number) =>
  parseISO(`${year}-08-31T23:59:59.000Z`);

export const getSchoolYear = (date: string | Date): number => {
  const parsed = safeParse(date);
  const yearFromParsed = getYear(parsed);
  const cutOffDate = getEndOfSchoolYear(yearFromParsed);
  return isBefore(parsed, cutOffDate) ? yearFromParsed - 1 : yearFromParsed;
};
