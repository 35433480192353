import { Box, Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import {
  LessonItem,
  LinkItem,
  SubjectItem,
} from '../../ops/components/types/types';
import { Note } from './ActivityOrder';
import { ContentProgress } from '../types';
import { DropItem } from './DropItem';
import { XXL_SPACING } from '../../../theme';
import { ActivityEditor } from './ActivityEditor';
import {
  TimeLineActivityItem,
  TimelineActivityItemDndPlaceholder,
} from './TimeLineActivityItem';

export const TimelineActivityWrapper = ({
  activity,
  addItemToOrder,
  setActivity,
  myOrder,
  deleteActivity,
  allTasks,
  showCurriculumContent,
  subject,
  grade,
}: {
  activity: LinkItem;
  addItemToOrder: (orderNumber: number, lessonItem: LessonItem) => void;
  setActivity: (activity: LinkItem) => void;
  myOrder: number;
  deleteActivity: () => void;
  allTasks: ContentProgress[];
  showCurriculumContent: boolean;
  subject: SubjectItem;
  grade: string;
}) => {
  const intl = useIntl();
  const [isBeingEdited, setIsBeingEdited] = useState(false);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'activity',
    item: {
      activity,
    },
    end: async (item, monitor) => {},
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const stateRef = useRef<number>();
  stateRef.current = myOrder;

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['activity', 'note'],
    drop: async (item: DropItem) => {
      await new Promise((resolve) => setTimeout(resolve, 200));
      if (item.activity || item.order?.note) {
        addItemToOrder(
          stateRef.current!! - 1,
          item.activity || item.order?.note
        );
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  return (
    <Stack direction="column" gap="1em" ref={drop} sx={{ pt: XXL_SPACING }}>
      {isOver && !isDragging && <TimelineActivityItemDndPlaceholder />}
      <Box
        ref={drag}
        style={{
          cursor: 'pointer',
          width: '100%',
          maxWidth: '800px',
        }}
      >
        {isBeingEdited && (
          <ActivityEditor
            activity={activity}
            editingOver={(a) => {
              if (a) {
                setActivity(a);
              }
              setIsBeingEdited(false);
            }}
            subject={subject}
            allTasks={allTasks}
            grade={grade}
            disablePortalForBookSelector={true}
          />
        )}
        {!isBeingEdited && (
          <TimeLineActivityItem
            {...activity}
            allTasks={showCurriculumContent ? allTasks : undefined}
            actionMenuItems={[
              {
                key: 'edit',
                onClick: () => setIsBeingEdited(true),
                children: intl.formatMessage({
                  id: 'noteOrActivity.edit',
                }),
              },
              {
                key: 'delete',
                onClick: () => deleteActivity(),
                children: intl.formatMessage({
                  id: 'noteOrActivity.removeFromLesson',
                }),
              },
            ].filter((item) => item.key !== 'edit' || activity.isEditable)}
          />
        )}
      </Box>
    </Stack>
  );
};
