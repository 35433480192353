import React from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { NEW_LIGHT_BORDER } from '../theme';

export const PanelContentDivider = ({ sx }: { sx?: SxProps<Theme> }) => {
  const defaultSx: SxProps<Theme> = { my: 8 };
  return (
    <Box sx={sx ? { ...defaultSx, ...sx } : defaultSx}>
      <hr
        style={{
          borderBottom: '0',
          borderTop: NEW_LIGHT_BORDER,
          opacity: 0.12,
        }}
      />
    </Box>
  );
};
