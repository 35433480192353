/* eslint-disable no-nested-ternary */
import * as React from 'react';
import { useBreakpoints } from '../hooks/useBreakpoints';
import { Drawer } from './Drawer';
import { PanelClassic } from './PanelClassic';

export const PanelOrDrawer = ({
  children,
  openCallback,
  closeCallback,
  showPanelControls,
  closedDrawerTeaser,
  panelOnly = false,
  panelAlignment = 'left',
  forceOpen,
}: {
  children: JSX.Element | React.ReactNode;
  openCallback?: () => void;
  closeCallback?: () => void;
  showPanelControls?: boolean;
  closedDrawerTeaser?: JSX.Element | React.ReactNode;
  panelOnly?: boolean;
  panelAlignment?: 'left' | 'right';
  forceOpen?: boolean;
}) => {
  const { usePanels } = useBreakpoints();
  const [isOpen, setOpen] = React.useState(true);
  React.useEffect(() => {
    if (forceOpen != null) {
      setOpen(forceOpen);
    }
  }, [forceOpen]);
  const onOpened = () => {
    setOpen(true);
    if (openCallback) {
      openCallback();
    }
  };

  const onClosed = () => {
    setOpen(false);
    if (closeCallback) {
      closeCallback();
    }
  };
  return (
    <>
      {usePanels || panelOnly ? (
        <PanelClassic
          panelAlignment={panelAlignment}
          isOpen={isOpen}
          openPanel={onOpened}
          closePanel={onClosed}
          showControlButton={showPanelControls}
        >
          {children}
        </PanelClassic>
      ) : (
        <Drawer
          visible={isOpen}
          onOpened={onOpened}
          onClosed={onClosed}
          teaser={closedDrawerTeaser}
        >
          {children}
        </Drawer>
      )}
    </>
  );
};
