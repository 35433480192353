import { useMediaQuery, useTheme, Box } from '@mui/material';
import React from 'react';
import {
  BORDER_1,
  BORDER_2,
  MINUTE_HEIGHT_IN_PX,
  SCHEDULE_HEADER_PX,
} from './constants';
import { WeekSelection } from './WeekSelection';
import { HEADER_HEIGHT } from '../../theme';
import { useBreakpoints } from '../../hooks/useBreakpoints';
import { ScheduleWeek } from '../../generics/types/scheduleWeek';

export const VisualHoursColumn = ({
  hoursInDay,
  week,
  setWeek,
}: {
  hoursInDay: string[];
  week: ScheduleWeek;
  setWeek: (week: ScheduleWeek) => void;
}) => {
  const { isPhone } = useBreakpoints();

  return (
    <Box
      style={{
        backgroundColor: '#FFFFFF',
      }}
    >
      <div
        style={{
          height: `${SCHEDULE_HEADER_PX}px`,
          position: 'sticky',
          top: 0,
          backgroundColor: '#154657',
          borderRight: BORDER_2,
          marginTop: 0,
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'end',
        }}
      >
        <WeekSelection week={week} setWeek={setWeek} />
      </div>
      {hoursInDay.map((time, i) => (
        <div
          key={time}
          style={{
            borderTop: BORDER_2,
            borderRight: BORDER_2,
            width: '100%',
            height: `${MINUTE_HEIGHT_IN_PX * 60}px`,
            display: 'block',
          }}
        >
          <span style={{ float: 'left', marginLeft: '5px', color: '#757575' }}>
            {time}
          </span>
          <div
            style={{
              width: '100%',
              height: `${MINUTE_HEIGHT_IN_PX * 30}px`,
              borderBottom: BORDER_1,
            }}
          />
        </div>
      ))}
    </Box>
  );
};

export const VisualHoursColumns = ({
  hoursInDay,
  weekend,
  week,
  setWeek,
}: {
  hoursInDay: string[];
  weekend: boolean;
  week: ScheduleWeek;
  setWeek: (newWeek: ScheduleWeek) => void;
}) => {
  const sm = useMediaQuery(useTheme().breakpoints.down('sm'));
  const portrait = useMediaQuery('(orientation: portrait)');
  const times =
    // eslint-disable-next-line no-nested-ternary
    sm && portrait && weekend
      ? [1, 2, 3, 4, 5, 6, 7]
      : sm && portrait
      ? [1, 2, 3, 4, 5]
      : [1];
  return (
    <>
      {times.map((i, index) => (
        <VisualHoursColumn
          key={i * 257}
          hoursInDay={hoursInDay}
          week={week}
          setWeek={setWeek}
        />
      ))}
    </>
  );
};
