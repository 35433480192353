import { messagesEN } from '../resources/en_EN';
import { messagesFI } from '../resources/fi_FI';
// import { messagesSE } from '../resources/se_SE';

export enum LocaleEnum {
  Finnish = 'fi',
  English = 'en',
}

export const getBrowserLocale = (): LocaleEnum => {
  const locale = window.navigator.language;
  switch (locale) {
    case 'fi':
      return LocaleEnum.Finnish;
    // case 'se':
    //   return LocaleEnum.SE;
    case 'en':
    default:
      return LocaleEnum.English;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getMessages = (locale: LocaleEnum): any => {
  switch (locale) {
    case LocaleEnum.Finnish:
      return messagesFI;
    // case LocaleEnum.SE:
    //   return messagesSE;
    case LocaleEnum.English:
    default:
      return messagesEN;
  }
};
