import { Box, Typography } from '@mui/material';
import React from 'react';
import { FeatherIcon, IconSize } from '../../../components/FeatherIcon';
import { BOLD_FONT_WEIGHT, SMALL_SPACING } from '../../../theme';

export const LibraryHeader = () => (
  <Box style={{ display: 'flex', alignItems: 'center' }}>
    <FeatherIcon name="book-open" size={IconSize['SMALL-MEDIUM']} />
    <Typography variant="body1" sx={{ ml: SMALL_SPACING }}>
      <span style={{ fontWeight: BOLD_FONT_WEIGHT }}>Activity library</span> and
      Contents
    </Typography>
  </Box>
);
