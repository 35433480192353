import { ContentProgressBulletpointStats } from '../routes/my-plan/types';
import { COMPLETED_COLOR, DARK_COMPLETED_COLOR, DETAIL_COLOR } from '../theme';
import { isContentCompletedNew } from '../utils/stathelpers';

export const StatStyleCompletedColor = COMPLETED_COLOR;
export const StatStylePlannedColor = DETAIL_COLOR;
const StatStyleCompleted = {
  backgroundColor: StatStyleCompletedColor,
  color: DARK_COMPLETED_COLOR,
};
const StatStylePlanned = {
  backgroundColor: 'white',
  color: DETAIL_COLOR,
};
const StatStyleNone = { backgroundColor: '#FFFFFF' };

export const resolveContentStyle = (
  contentBulletpoints?: string[],
  allStats?: ContentProgressBulletpointStats[]
): { backgroundColor: string; color?: string } => {
  const contentStatus = isContentCompletedNew(contentBulletpoints, allStats);
  if (contentStatus !== null) {
    return contentStatus ? StatStyleCompleted : StatStylePlanned;
  }
  return StatStyleNone;
};
