/* eslint-disable @typescript-eslint/indent,react/no-array-index-key */
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { LessonPreviewHeader } from './LessonPreviewHeader';
import { LessonPlanStaticTimeline } from './LessonPlanStaticTimeline';
import { useSubjectColor } from '../../../hooks/useSubjectColor';
import { EnhancedSubjectTimings } from '../../../api/timetablesapi';
import { FeatherIcon } from '../../../components/FeatherIcon';
import { activityTypeToIcon } from '../../../components/ActivityChip';
import { LARGE_SPACING, THEME_MAIN_COLOR } from '../../../theme';
import { useAuth } from '../../../utils/auth/useAuth';
import { IconActionButton } from '../../../components/IconActionButton';
import { contentState } from '../../../App/state';
import { CenterLoader } from '../../../components/CenterLoader';

export const LessonPlanPreview = (props: {
  subjectTiming: EnhancedSubjectTimings;
}) => {
  const user = useAuth();
  const intl = useIntl();
  const [showCurriculumContent, setShowCurriculumContent] = useState(false);
  const { subjectColor } = useSubjectColor(props.subjectTiming.subject);
  const [contentViewState, _] = useRecoilState(contentState);
  const topic = contentViewState?.topic;

  if (!topic) {
    return <CenterLoader />;
  }

  const homework = topic.activityOrder?.homework || [];
  return (
    <Stack spacing={1}>
      <LessonPreviewHeader
        subjectTiming={props.subjectTiming}
        subject={props.subjectTiming.subject}
        groupName={props.subjectTiming.groupName ?? ''}
        grade={props.subjectTiming.grade}
      />
      <h2 style={{ fontSize: '33px', fontFamily: 'RobotoRegular' }}>
        {topic.name}
      </h2>
      <Stack
        direction="row"
        justifyContent="space-between"
        style={{ fontSize: '22px', fontFamily: 'RobotoRegular' }}
      >
        <Box>{props.subjectTiming.topic?.description}</Box>
        <IconActionButton
          size="medium"
          name={!showCurriculumContent ? 'eye' : 'eye-off'}
          color={THEME_MAIN_COLOR}
          onClick={() => setShowCurriculumContent(!showCurriculumContent)}
          tooltip={
            !showCurriculumContent
              ? intl.formatMessage({ id: 'buttons.showCurriculumContents' })
              : intl.formatMessage({ id: 'buttons.hideCurriculumContents' })
          }
        />
      </Stack>
      <LessonPlanStaticTimeline
        topic={topic}
        highlightColor={subjectColor.primaryColor}
        showCurriculumContent={showCurriculumContent}
      />
      <Stack
        direction="row"
        style={{ gap: '3.33%', fontFamily: 'RobotoRegular' }}
      >
        <Box style={{ width: '30%' }}>
          <h3 style={{ fontFamily: 'RobotoRegular', fontSize: '24px' }}>
            <FeatherIcon name="book" color="rgba(218, 172, 86, 1)" /> Homework
          </h3>
          {homework.length === 0 && <Box>No Homework Assigned</Box>}
          {homework.map((order, index) => {
            if (order.activityId) {
              const hw = topic.tasks
                .flatMap((t) => t.allAvailableLinks)
                .find((l) => l.linkId === order.activityId);
              return (
                <Stack
                  key={hw?.linkId}
                  direction="row"
                  gap={5}
                  style={{
                    backgroundColor: 'rgba(218, 172, 86, 0.08)',
                    padding: '24px',
                  }}
                >
                  <Box>
                    <FeatherIcon
                      size={22}
                      name={activityTypeToIcon(hw?.activityType)}
                      color="rgba(218, 172, 86, 1)"
                    />
                  </Box>
                  <Box>
                    <Box
                      style={{
                        textTransform: 'uppercase',
                        color: '#6E6E6E',
                        fontSize: '16px',
                      }}
                    >
                      {intl.formatMessage({ id: hw?.activityType })}
                    </Box>
                    <h4
                      style={{
                        color: 'rgba(218, 172, 86, 1)',
                        margin: 0,
                        marginBottom: '22px',
                      }}
                    >
                      {hw?.title}
                    </h4>
                    <Box>{hw?.description}</Box>
                  </Box>
                </Stack>
              );
            }
            if (
              order.note?.description &&
              (user?.role === 'TEACHER' || order.note.showToStudents)
            ) {
              return (
                <Stack
                  sx={{ mt: LARGE_SPACING }}
                  key={order.note?.description + order.note?.name + index}
                  direction="row"
                  gap={5}
                  style={{
                    backgroundColor: 'rgba(218, 172, 86, 0.08)',
                    padding: '24px',
                  }}
                >
                  <Box>
                    <FeatherIcon
                      size={22}
                      name="clipboard"
                      color="rgba(218, 172, 86, 1)"
                    />
                  </Box>
                  <Box>
                    <h4
                      style={{
                        color: 'rgba(218, 172, 86, 1)',
                        margin: 0,
                        marginBottom: '22px',
                      }}
                    >
                      {order.note?.name}
                    </h4>
                    <Box>{order.note?.description}</Box>
                  </Box>
                </Stack>
              );
            }
            return <></>;
          })}
        </Box>

        {/* VISUAL STYLING FOR NOTIFICATIONS SECTION ONCE WE HAVE THE DATA
        <Box>
          <h3 style={{ fontFamily: 'RobotoRegular', fontSize: '24px' }}><FeatherIcon name='bell' color='rgba(255, 94, 0, 1)' /> Notifications</h3>
          <Box style={{ backgroundColor: 'rgba(247, 248, 249, 1)', padding: '24px' }}>
            Remember to take plastic bag with you next time
          </Box>
        </Box>
      */}
      </Stack>
    </Stack>
  );
};
