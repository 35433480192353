import {
  Box,
  Stack,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { FeatherIcon, IconSize } from '../../../components/FeatherIcon';
import { IconActionButton } from '../../../components/IconActionButton';
import {
  BOLD_FONT_WEIGHT,
  LARGE_SPACING,
  SMALL_SPACING,
  THEME_MAIN_COLOR,
} from '../../../theme';

export const TimelineHeader = ({
  showCurriculumContent,
  setShowCurriculumContent,
}: {
  showCurriculumContent: boolean;
  setShowCurriculumContent: (newValue: boolean) => void;
}) => {
  const intl = useIntl();

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="body1"
            style={{ fontWeight: BOLD_FONT_WEIGHT }}
            sx={{ ml: SMALL_SPACING, mr: LARGE_SPACING }}
          >
            Lesson timeline
          </Typography>
          <FeatherIcon
            name="info"
            size={IconSize['SMALL-MEDIUM']}
            color="#245F73"
          />
        </Box>
        <IconActionButton
          size="medium"
          name={!showCurriculumContent ? 'eye' : 'eye-off'}
          color={THEME_MAIN_COLOR}
          onClick={() => setShowCurriculumContent(!showCurriculumContent)}
          tooltip={
            !showCurriculumContent
              ? intl.formatMessage({ id: 'buttons.showCurriculumContents' })
              : intl.formatMessage({ id: 'buttons.hideCurriculumContents' })
          }
        />
      </Stack>
      <Box>
        <Typography
          variant="caption"
          style={{ color: '#757575' }}
          sx={{ ml: SMALL_SPACING }}
        >
          Plan the structure and activities for the lesson.
        </Typography>
      </Box>
    </Box>
  );
};
