import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { useDrag } from 'react-dnd';
import { ActivityChip } from '../../../components/ActivityChip';
import { BulletpointTimesPlanned } from '../../../components/BulletpointTimesPlanned';
import { DETAIL_COLOR, MEDIUM_SPACING, SMALL_SPACING } from '../../../theme';
import { GradeItem, LinkItem } from '../../ops/components/types/types';
import { ContentProgress } from '../types';
import { ActivityOrder, ActivityOrderTypes } from './ActivityOrder';
import { ObjectivePopoverIcon } from './ObjectivePopover';
import { getObjectiveAndContent } from '../../../utils/taskGradeUtils';

const Header = ({
  taskText,
  timesPlanned,
  task,
  grade,
}: {
  taskText: string;
  timesPlanned: number;
  task: ContentProgress;
  grade?: GradeItem;
}) => {
  const { objective, content } = getObjectiveAndContent(grade!!, task);

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      gap={SMALL_SPACING}
      sx={{ p: SMALL_SPACING }}
    >
      <BulletpointTimesPlanned
        timesPlanned={timesPlanned}
        style={{
          borderRight: `1px solid ${DETAIL_COLOR}`,
        }}
        sx={{ px: MEDIUM_SPACING }}
      />
      <Typography
        variant="caption"
        style={{ color: '#3E3E3E' }}
        sx={{ marginLeft: MEDIUM_SPACING }}
        flexGrow={1}
      >
        {taskText}
      </Typography>
      <ObjectivePopoverIcon objective={objective} content={content} />
    </Stack>
  );
};

const Activity = ({
  activity,
  isSelected,
}: {
  activity: LinkItem;
  isSelected: boolean;
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'activity',
    item: {
      activity,
    },
    end: async (item, monitor) => {},
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  return (
    <ActivityChip
      dragRef={isSelected ? undefined : drag}
      activity={activity}
      isSelected={isSelected}
      style={isSelected ? {} : { cursor: 'pointer' }}
    />
  );
};

const Activities = ({
  task,
  activityOrder,
}: {
  task: ContentProgress;
  activityOrder: ActivityOrder;
}) => (
  <Box sx={{ p: SMALL_SPACING, width: '100%' }}>
    {task?.allAvailableLinks?.map((availableLink) => (
      <Activity
        key={availableLink.linkId}
        activity={availableLink}
        isSelected={ActivityOrderTypes.some((key) =>
          activityOrder[key].some(
            (item) => item.activityId === availableLink.linkId
          )
        )}
      />
    ))}
  </Box>
);

export const LibraryTask = ({
  activityOrder,
  task,
  taskText,
  timesPlanned,
  grade,
}: {
  activityOrder: ActivityOrder;
  task: ContentProgress;
  taskText: string;
  timesPlanned: number;
  grade?: GradeItem;
}) => (
  <Box
    style={{
      border: '1px solid #E4E4E5',
      backgroundColor: '#FFFFFF',
      borderRadius: '6px',
    }}
    sx={{ mb: SMALL_SPACING, p: SMALL_SPACING }}
  >
    <Stack style={{ overflowX: 'hidden' }}>
      <Header
        taskText={taskText}
        timesPlanned={timesPlanned}
        task={task}
        grade={grade}
      />
      <Activities task={task} activityOrder={activityOrder} />
    </Stack>
  </Box>
);
