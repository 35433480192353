import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import {
  DAY_INDICATOR_WIDTH,
  DottedLine,
  YEAR_PLAN_CARD_HEIGHT,
} from './Commons';
import { FeatherIcon } from '../../../components/FeatherIcon';
import { BOLD_FONT_WEIGHT, DETAIL_COLOR, SMALL_SPACING } from '../../../theme';
import { SubjectColorDefinition } from '../../../hooks/useSubjectColor';

export const YearPlanHeader = ({
  lessonNumber,
  amountOfLessons,
  lowOpacity,
  nextLesson,
  previousLesson,
  subjectColor,
  selected,
  style = {},
}: {
  lessonNumber: number;
  amountOfLessons: number;
  lowOpacity?: boolean;
  nextLesson?: () => void;
  previousLesson?: () => void;
  subjectColor: SubjectColorDefinition;
  selected?: boolean;
  style?: Record<string, string | number>;
}) => (
  <Box
    style={{
      opacity: lowOpacity ? 0.75 : 1,
      backgroundColor: subjectColor.secondaryColor,
      height: `${YEAR_PLAN_CARD_HEIGHT / 8}px`,
      ...style,
    }}
  >
    <Box
      style={{
        display: 'flex',
        justifyContent:
          !!nextLesson || !!previousLesson ? 'space-between' : 'center',
        alignItems: 'center',
      }}
    >
      {previousLesson ? (
        <IconButton size="small" sx={{ p: 0 }} onClick={previousLesson}>
          <FeatherIcon color="#6F787B" name="chevron-left" />
        </IconButton>
      ) : (
        <Box />
      )}
      <Typography
        style={{
          color: '#172B4D',
          ...(selected && {
            fontWeight: BOLD_FONT_WEIGHT,
            color: '#172B4D',
            fontSize: '16px',
            marginBottom: '-6.5px',
          }),
          ...(!previousLesson && nextLesson && { marginLeft: '20px' }),
          ...(!nextLesson && previousLesson && { marginRight: '20px' }),
        }}
        variant="caption"
      >
        {lessonNumber}/{amountOfLessons}
      </Typography>
      {nextLesson ? (
        <IconButton size="small" sx={{ p: 0 }} onClick={nextLesson}>
          <FeatherIcon color="#6F787B" name="chevron-right" />
        </IconButton>
      ) : (
        <Box />
      )}
    </Box>
    <Box
      sx={{ mt: SMALL_SPACING }}
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <DottedLine />
      <Box
        style={{
          width: `${DAY_INDICATOR_WIDTH * 1.5}px`,
          height: `${DAY_INDICATOR_WIDTH}px`,
          border: `2px solid ${selected ? DETAIL_COLOR : '#245F73'}`,
          borderRadius: '50%',
        }}
      />
      <DottedLine />
    </Box>
  </Box>
);
