import styled from 'styled-components';
import { Dialog } from '@mui/material';

export const StyledDialog = styled(Dialog)`
  & .MuiDialog-container > .MuiPaper-root {
    padding: 24px;
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin: 16px;
      padding: 16px;
    }
  }
`;
