import {
  ActivityOrder,
  ActivityOrderType,
  ActivityOrderTypes,
  Note,
} from '../routes/my-plan/lesson-plan/ActivityOrder';
import { LinkItem, isLinkItem } from '../routes/ops/components/types/types';

export const removeItemFromActivityOrder = (
  currentOrder: ActivityOrder,
  orderSection: ActivityOrderType,
  orderNumber: number
): ActivityOrder => ({
  ...currentOrder,
  [orderSection]: [
    ...currentOrder[orderSection].filter(
      (or) => or.orderNumber !== orderNumber
    ),
  ],
});

export const moveItemInActivityOrder = (
  currentOrder: ActivityOrder,
  newSection: ActivityOrderType,
  newNumber: number,
  item: LinkItem | Note
): ActivityOrder => {
  const newActivityOrder: ActivityOrder = {
    initiation: [],
    practicing: [],
    closing: [],
    homework: [],
  };

  const activity = isLinkItem(item) ? (item as LinkItem) : undefined;
  const note = isLinkItem(item) ? undefined : (item as Note);

  // Remove the same activity/note if it exists in order already
  ActivityOrderTypes.forEach((key) => {
    newActivityOrder[key] =
      currentOrder[key]?.filter((activityFromOrder: any) => {
        if (activity?.linkId && activityFromOrder.activityId) {
          return activityFromOrder.activityId !== activity.linkId;
        }

        return !(
          note?.showToStudents === activityFromOrder.note?.showToStudents &&
          note?.description === activityFromOrder?.note?.description &&
          note?.name === activityFromOrder.note?.name
        );
      }) ?? [];
  });
  return {
    ...newActivityOrder,
    [newSection]: [
      // make -1 to all existing same or smaller ordernumber
      ...(newActivityOrder[newSection] ?? []).map((order) =>
        order.orderNumber <= newNumber
          ? {
              ...order,
              orderNumber: order.orderNumber - 1,
            }
          : order
      ),
      {
        orderNumber: newNumber,
        ...(activity?.linkId ? { activityId: activity.linkId } : { note }),
      },
    ],
  };
};
