import React from 'react';
import { Box } from '@mui/material';
import { LARGE_SPACING, NEW_LIGHT_BORDER } from '../theme';

export enum TabStyle {
  'TEXT',
  'BACKGROUND',
}

export const CommonTabs = ({
  children,
  tabStyle = TabStyle.TEXT,
  style = {},
}: {
  children: any;
  tabStyle?: TabStyle;
  style?: Record<string, string | number>;
}) => (
  <Box
    style={{
      display: 'flex',
      width: '100%',
      justifyContent:
        tabStyle === TabStyle.TEXT ? 'flex-start' : 'space-between',
      alignItems: 'center',
      ...(tabStyle === TabStyle.TEXT
        ? { borderBottom: NEW_LIGHT_BORDER, gap: '3em' }
        : {}),
      ...(tabStyle === TabStyle.BACKGROUND ? { background: '#EFF0F2' } : {}),
      ...style,
    }}
    sx={{ ...(tabStyle === TabStyle.TEXT && { px: LARGE_SPACING }) }}
  >
    {children}
  </Box>
);
