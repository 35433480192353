import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { EnhancedSubjectTimings, SubjectTiming } from '../api/timetablesapi';
import { Topic } from '../api/topicapi';
import { getBrowserLocale, LocaleEnum } from '../utils/locale';
import { SimpleSubject } from '../routes/my-plan/helpers';
import { Group } from '../api/groupapi';
import { SelectedGroupSubject } from '../routes/my-plan/year-plan/yearPlanUtils';
import { getWeekFromDay } from '../utils/weekhelpers';
import { ScheduleWeek } from '../generics/types/scheduleWeek';

const { persistAtom } = recoilPersist();

export enum SchedulePanelMode {
  VIEW_LESSON,
  VIEW_TOPIC,
}

export type LessonPlan = {
  topic: Topic;
  subjectTiming?: EnhancedSubjectTimings | SubjectTiming;
};

export const selectedSubject = atom<SimpleSubject | undefined>({
  key: 'simpleSubject',
  default: undefined,
});

export const locale = atom<LocaleEnum>({
  key: 'locale',
  default: getBrowserLocale(),
});

export const cardsOpen = atom<string[]>({
  key: 'cardsOpen',
  default: [],
});
const TODAY = new Date();

export const scheduleSelectedWeek = atom<ScheduleWeek>({
  key: 'scheduleSelectedWeek',
  default: getWeekFromDay(TODAY),
});

export const schedulePanelMode = atom<SchedulePanelMode>({
  key: 'schedulePanelMode',
  default: SchedulePanelMode.VIEW_LESSON,
});

export const scheduleViewClass = atom<EnhancedSubjectTimings | undefined>({
  key: 'scheduleViewClass',
  default: undefined,
});

export const groupEditorGroup = atom<Group | undefined>({
  key: 'groupEditorGroup',
  default: undefined,
});

export const groupEditorSelectedTimetableId = atom<string | undefined>({
  key: 'groupEditorSelectedTimetableId',
  default: undefined,
});

export const groupEditorTimetableSubjectWidth = atom<number>({
  key: 'groupEditorTimetableSubjectWidth',
  default: 150,
});

export interface ContentViewState {
  grade: string;
  subject: string;
  groupName: string;
  groupId: string;
  amountOfLessons: number;
  lessonNumber: number;
  year: number;
  topic: Topic;
}

export const contentState = atom<ContentViewState | undefined>({
  key: 'contentState',
  default: undefined,
});

export const yearPlanDialogActiveTab = atom<'Content' | 'Lesson'>({
  key: 'yearPlanDialogActiveTab',
  default: 'Content',
});
export const contentContentDialogOpen = atom<boolean>({
  key: 'contentContentDialogOpen',
  default: false,
});

export const contentLoadingState = atom<boolean>({
  key: 'contentLoadingState',
  default: false,
});

export const contentSelectedGroupSubjects = atom<SelectedGroupSubject[]>({
  key: 'contentSelectedGroupSubjects',
  default: [],
});

export const contentLeftPanelOpenState = atom<boolean>({
  key: 'contentLeftPanelOpenState',
  default: true,
});

export const contentRedirectAfterSaveUrl = atom<string | undefined>({
  key: 'contentRedirectAfterSaveUrl',
  default: undefined,
});

export const curriculumLinksState = atom<{ bulletpointId: string } | undefined>(
  {
    key: 'curriculumLinksState',
    default: undefined,
  }
);
