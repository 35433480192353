import React from 'react';
import { Links } from './subjects/view-tab/Links';
import { findContentOrTransversalForBulletpointId } from '../../utils/ops/findBulletpointDescFromSubjects';
import { SubjectItem } from '../ops/components/types/types';

export const LinksPanelOrDrawer = ({
  bulletpointId,
  subjectCode,
  subjectItem,
  grade,
}: {
  bulletpointId: string;
  subjectCode: string;
  subjectItem: SubjectItem;
  grade: string;
}) => {
  const contentOrTransversal = findContentOrTransversalForBulletpointId(
    bulletpointId,
    subjectItem
  );
  const description = Object.keys(contentOrTransversal.bulletpoints || {})
    .map((key) => ({
      code: key,
      // @ts-ignore
      description: contentOrTransversal.bulletpoints[key],
    }))
    .find((bp) => bp.code === bulletpointId);

  return (
    <>
      <Links
        description={description?.description!!}
        subjectItem={subjectItem}
        gradeId={grade.toString()}
        item={contentOrTransversal}
        subjectCode={subjectCode}
        selectedLinkBulletpoint={bulletpointId}
      />
    </>
  );
};
