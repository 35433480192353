import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import {
  NOT_SELECTED_DETAIL_BORDER,
  SELECTED_BACKGROUND,
  SMALL_SPACING,
  THICKER_SELECTED_DETAIL_BORDER,
} from '../../../theme';
import { findBulletpointDescFromSubject } from '../../../utils/ops/findBulletpointDescFromSubjects';
import { SubjectItem } from '../../ops/components/types/types';
import { ContentProgress } from '../types';
import { ObjectivePopoverIcon } from './ObjectivePopover';
import { getObjectiveAndContent } from '../../../utils/taskGradeUtils';
import { useBreakpoints } from '../../../hooks/useBreakpoints';

export const SelectableObjective = ({
  task,
  subject,
  isChecked,
  setChecked,
  grade,
}: {
  task: ContentProgress;
  subject: SubjectItem;
  grade: string;
  isChecked: boolean;
  setChecked: () => void;
}) => {
  const { isPhone } = useBreakpoints();
  const bulletPointDesc = findBulletpointDescFromSubject(
    task.bulletpointId,
    grade,
    subject
  );
  const { objective, content } = getObjectiveAndContent(
    subject.grades?.find((g) => String(g.grade) === grade)!!,
    task
  );
  return (
    <Grid
      item
      xs={12}
      md={6}
      key={`taskSelection${task.contentProgressId}`}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      sx={{ mb: SMALL_SPACING }}
    >
      <Button
        onClick={setChecked}
        variant="text"
        style={{
          backgroundColor: isChecked ? '#91AEB8' : 'white',
          background: isChecked ? SELECTED_BACKGROUND : 'white',
          border: isChecked
            ? THICKER_SELECTED_DETAIL_BORDER
            : NOT_SELECTED_DETAIL_BORDER,
          width: '100%',
          position: 'relative',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
        sx={{ p: SMALL_SPACING }}
      >
        <Typography
          variant="caption"
          style={{
            whiteSpace: isPhone ? 'normal' : 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            color: '#3E3E3E',
            maxWidth: '95%',
          }}
          sx={{ px: SMALL_SPACING }}
        >
          {bulletPointDesc}
        </Typography>
        <Box sx={{ mb: -2 }}>
          <ObjectivePopoverIcon
            objective={objective}
            content={content}
            iconColor="#6F787B"
          />
        </Box>
      </Button>
    </Grid>
  );
};
