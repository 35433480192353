import { Note } from '../../../my-plan/lesson-plan/ActivityOrder';

export type Item = {
  code: string;
  name: string;
  key?: string;
};

export type ContentItem = {
  bulletpoints: { [key: string]: string };
} & Item;

export type TransversalItem = {
  bulletpoints: { [key: string]: string };
} & Item;

export type TransversalItemWithInfo = {
  bulletpoints: { [key: string]: string };
  subject: SubjectItem;
  grade: GradeItem;
  objective: Objective;
} & Item;

export type Objective = {
  contents?: Array<ContentItem>;
  transversals?: Array<TransversalItem>;
} & Item;

export type FavoriteItem = {
  favorite: boolean;
};

export type GradeItem = {
  grade: number;
  objectives?: Array<Objective>;
} & FavoriteItem;

export type SubjectItem = {
  title: string;
  code: string;
  grades?: Array<GradeItem>;
  abbreviation: string;
} & FavoriteItem;

export type LinkItem = {
  subjectId: string;
  gradeId: string;
  linkId: string;
  bulletpointIds: string[];
  title: string;
  type: string;
  link: string;
  enabled: boolean;
  private: boolean;
  description: string;
  owner: boolean;
  bookTitle?: string;
  page?: string;
  bookId?: string;
  activityType?: string;
  pk?: string;

  isEditable?: boolean;
  imageUrl?: string;
};

export type LessonItem = LinkItem | Note;

export const isLinkItem = (linkOrNote: LessonItem): linkOrNote is LinkItem =>
  (linkOrNote as LinkItem).activityType !== undefined;
