import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as usersApi from '../api/usersapi';
import { QUERY_CONFIG } from '../utils/queryconfig';

const getQueryKey = (groupId: string | undefined) =>
  `favoriteSubjects:${groupId}`;

const addOrRemove = (array: string[], val: string) =>
  array.includes(val) ? array.filter((it) => it !== val) : [...array, val];

export const useFavoriteSubjects = (groupId: string | undefined) => {
  const queryClient = useQueryClient();
  const { data: favoriteSubjects } = useQuery({
    queryKey: [getQueryKey(groupId)],
    queryFn: () => usersApi.getFavorites(groupId),
    ...QUERY_CONFIG,
    enabled: !!groupId,
  });
  const { mutate: toggleFavoriteSubject } = useMutation(
    (subjectCode: string) =>
      usersApi.putFavorites(
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        addOrRemove(favoriteSubjects || [], subjectCode),
        groupId
      ),
    {
      onMutate: (subjectCode: string) => {
        const updatedFavoriteSubjects = addOrRemove(
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          favoriteSubjects || [],
          subjectCode
        );
        queryClient.cancelQueries([getQueryKey(groupId)]);
        queryClient.setQueryData(
          [getQueryKey(groupId)],
          updatedFavoriteSubjects
        );

        return updatedFavoriteSubjects;
      },
      onSettled: () => {
        queryClient.invalidateQueries([getQueryKey(groupId)]);
      },
    }
  );

  return {
    favoriteSubjects,
    toggleFavoriteSubject,
  };
};
