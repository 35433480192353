import { Dialog } from '@mui/material';

import React from 'react';
import { useBreakpoints } from '../hooks/useBreakpoints';
import { HEADER } from '../theme';
import { DialogTransition } from './DialogTransition';
import { FULL_SCREEN_DIALOG as Z_INDEX } from '../utils/z-index';

export const CommonFullScreenDialog = ({
  open,
  onClose,
  children,
  forwardRef,
}: {
  open: boolean;
  onClose: () => void;
  children: JSX.Element | React.ReactNode;
  forwardRef?: any;
}) => {
  const { useHamburgerNavigation } = useBreakpoints();
  const menuElementWidth = useHamburgerNavigation ? '0' : '114';
  return (
    <Dialog
      ref={forwardRef}
      open={open}
      TransitionComponent={DialogTransition}
      // @ts-ignore
      onClose={onClose}
      onClick={(e) => e.stopPropagation()}
      fullScreen
      sx={{
        marginTop: `${HEADER}px`,
        marginLeft: `${menuElementWidth}px`,
        zIndex: Z_INDEX,
      }}
      hideBackdrop={true}
    >
      {children}
    </Dialog>
  );
};
