import { Box, Typography } from '@mui/material';
import React from 'react';
import { BOLD_FONT_WEIGHT, MEDIUM_SPACING } from '../theme';
import { FeatherIcon, IconSize } from './FeatherIcon';

export const ContentMissing = (props: {
  title: string;
  description: string;
}) => (
  <Box sx={{ m: 'auto' }} style={{ textAlign: 'center' }}>
    <Typography
      variant="h4"
      sx={{ m: MEDIUM_SPACING }}
      style={{ display: 'block', fontWeight: BOLD_FONT_WEIGHT }}
    >
      {props.title.toUpperCase()}
    </Typography>
    <Box>
      <FeatherIcon
        size={IconSize['EXTRA-EXTRA-LARGE']}
        name="clipboard"
        color="white"
        style={{
          strokeWidth: '1',
          fill: '#245F73',
          marginLeft: '-10%',
          position: 'relative',
          opacity: '0.45',
        }}
      />
    </Box>
    <Box>
      <FeatherIcon
        size={IconSize['EXTRA-EXTRA-LARGE']}
        name="search"
        color="#245F73"
        style={{
          strokeWidth: '1',
          fill: 'white',
          position: 'relative',
          marginTop: '-20%',
          marginLeft: '10%',
        }}
      />
    </Box>

    <Typography
      sx={{ m: MEDIUM_SPACING }}
      variant="h6"
      color="text.secondary"
      style={{ display: 'block' }}
    >
      {props.description}
    </Typography>
  </Box>
);
