/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';

import { Box, CircularProgress, Stack } from '@mui/material';
import { useIntl } from 'react-intl';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { Topic } from '../../api/topicapi';
import { useAuth } from '../../utils/auth/useAuth';
import {
  LIGHT_BORDER,
  SMALL_SPACING,
  THEME_MAIN_COLOR,
  XXL_SPACING,
} from '../../theme';
import { TimeLineActivityItem } from '../my-plan/lesson-plan/TimeLineActivityItem';
import {
  getActivitiesAndNotesFromTopic,
  getHomeworkFromTopic,
} from '../../utils/getActivitiesAndNotesFromTopic';
import {
  LessonItem,
  LinkItem,
  isLinkItem,
} from '../ops/components/types/types';
import { TimeLineNoteItem } from '../my-plan/lesson-plan/TimeLineNoteItem';
import { useOps } from '../../hooks/useOps';
import { IconActionButton } from '../../components/IconActionButton';
import { useTopicMutation } from '../../hooks/useTopicMutation';
import {
  moveItemInActivityOrder,
  removeItemFromActivityOrder,
} from '../../utils/activityOrderUtil';
import { ActivityOrderType, Note } from '../my-plan/lesson-plan/ActivityOrder';
import { ActionMenuItem } from '../my-plan/lesson-plan/ActionMenu';

const LessonItemContainer = (props: {
  lessonItem: LessonItem;
  topic: Topic;
  showContent: boolean;
  section: ActivityOrderType;
  order: number;
}) => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { updateMutation } = useTopicMutation({
    groupId: props.topic.groupId,
  });
  const linkItem = isLinkItem(props.lessonItem);

  const activity = isLinkItem(props.lessonItem) ? props.lessonItem : undefined;
  const note = isLinkItem(props.lessonItem) ? undefined : props.lessonItem;

  const handleRemoveItem = () => {
    updateMutation.mutate({
      ...props.topic,
      activityOrder: removeItemFromActivityOrder(
        props.topic.activityOrder,
        props.section,
        props.order
      ),
    });
  };

  const handleHomeworkSave = () =>
    updateMutation.mutate({
      ...props.topic,
      activityOrder: moveItemInActivityOrder(
        props.topic.activityOrder,
        'homework',
        0,
        props.lessonItem
      ),
    });

  const actionMenuItems: ActionMenuItem[] = [
    {
      key: 'delete',
      onClick: () =>
        confirm({
          description: 'Are you sure you want to remove this item?',
        })
          .then(() => handleRemoveItem())
          .then(() => {
            enqueueSnackbar('Link removed', { variant: 'success' });
          })
          .catch(() => {
            enqueueSnackbar('Failed to remove link', { variant: 'error' });
          }),
      children: intl.formatMessage({
        id: 'noteOrActivity.remove',
      }),
    },
  ];
  if (props.section !== 'homework') {
    actionMenuItems.push({
      key: 'moveToHomework',
      onClick: handleHomeworkSave,
      children: 'Move to Homework',
    });
  }
  if (linkItem) {
    return (
      <TimeLineActivityItem
        {...(activity as LinkItem)}
        allTasks={props.showContent ? props.topic.tasks : undefined}
        actionMenuItems={actionMenuItems}
        isHomework={props.section === 'homework'}
      />
    );
  }
  return (
    <TimeLineNoteItem
      {...(note as Note)}
      actionMenuItems={actionMenuItems}
      isHomework={props.section === 'homework'}
    />
  );
};

export const TopicCard = ({ topic }: { topic: Topic }) => {
  const { user } = useAuth();
  const [showCurriculumContent, setShowCurriculumContent] = useState(false);
  const { ops } = useOps();
  const intl = useIntl();
  if (!ops) {
    return <CircularProgress />;
  }
  const isStudent = user?.role !== 'TEACHER';
  const lessonActivities = getActivitiesAndNotesFromTopic(topic, isStudent);
  const homeworkActivities = getHomeworkFromTopic(topic, isStudent);

  return (
    <Box
      sx={{ p: XXL_SPACING, ml: SMALL_SPACING }}
      style={{
        borderBottom: LIGHT_BORDER,
      }}
      key={topic.topicId}
    >
      <Stack
        direction="row"
        justifyContent="end"
        style={{ marginBottom: '10px' }}
      >
        {topic?.name && <h2 style={{ flexGrow: 1 }}>{topic.name}</h2>}
        <IconActionButton
          size="medium"
          name={!showCurriculumContent ? 'eye' : 'eye-off'}
          color={THEME_MAIN_COLOR}
          onClick={() => setShowCurriculumContent(!showCurriculumContent)}
          tooltip={
            !showCurriculumContent
              ? intl.formatMessage({ id: 'buttons.showCurriculumContents' })
              : intl.formatMessage({ id: 'buttons.hideCurriculumContents' })
          }
        />
      </Stack>
      <Stack gap="1em">
        {lessonActivities.map((li, index) => (
          <LessonItemContainer
            key={`${li.order.type}/${li.order.orderNumber}`}
            topic={topic}
            showContent={showCurriculumContent}
            lessonItem={li}
            section={li.order.type}
            order={li.order.orderNumber}
          />
        ))}
        {homeworkActivities?.length > 0 && <h3>Homework</h3>}
        {homeworkActivities?.map((li, index) => (
          <LessonItemContainer
            key={`${li.order.type}/${li.order.orderNumber}`}
            topic={topic}
            showContent={showCurriculumContent}
            lessonItem={li}
            section={li.order.type}
            order={li.order.orderNumber}
          />
        ))}
      </Stack>
    </Box>
  );
};
