import * as React from 'react';
import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Card,
  CircularProgress,
  Fab,
  Typography,
} from '@mui/material';
import {
  deleteProfilePicture,
  getProfilePicturePutUrl,
  getProfilePictureUrl,
} from '../../api/usersapi';
import { uploadImageToS3 } from '../../utils/uploadImageToS3';
import { QUERY_CONFIG } from '../../utils/queryconfig';
// @ts-ignore
import backup from './backup_2.png';
import { PictureCropDialog } from './PictureCropDialog';
import { FeatherIcon, IconSize } from '../../components/FeatherIcon';
import { FileUpload } from '../../components/FileUpload';
import { DELETE_COLOR } from '../../theme';

interface Props {
  name?: string;
  forwardRef?: any;
}

export const ProfileAvatar = ({ name, forwardRef }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const [imageToBeUploaded, setImageToBeUploaded] = useState<ArrayBuffer>();
  const [loading, setLoading] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageLoadError, setImageLoadError] = useState(false);
  const [showImageCropDialog, setShowImageCropDialog] = useState(false);
  const [src, setSrc] = useState<string | undefined>();
  const inputFile = React.useRef<HTMLInputElement | null>(null);
  const getPictureUrlResponse = useQuery({
    queryKey: ['profile-picture-get-url'],
    queryFn: getProfilePictureUrl,
    ...QUERY_CONFIG,
  });
  const { mutate: uploadImage } = useMutation(getProfilePicturePutUrl, {
    onSuccess: (data) => {
      uploadImageToS3(data.url, imageToBeUploaded!!, 'image/jpeg').then(
        async () => {
          await getPictureUrlResponse.refetch();
          setLoading(false);
          setImageLoadError(false);
          enqueueSnackbar('Image saved successfully', {
            variant: 'success',
          });
          setImageToBeUploaded(undefined);
          setImageLoaded(false);
        }
      );
    },
  });

  const { mutate: deleteImage } = useMutation(deleteProfilePicture, {
    onSuccess: (data) => {
      enqueueSnackbar('Image deleted successfully', {
        variant: 'success',
      });
      queryClient.invalidateQueries(['profile-picture-get-url']);
    },
  });

  if (!getPictureUrlResponse.data || loading) {
    return (
      <Box
        sx={{ m: 7.5 }}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const imageOnErrorHandler = () => {
    setImageLoadError(true);
    setImageLoaded(true);
  };
  return (
    <Box sx={{ m: 7.5 }}>
      <PictureCropDialog
        forwardRef={forwardRef}
        open={showImageCropDialog}
        onClose={() => {
          setShowImageCropDialog(false);
        }}
        src={src!!}
        uploadFile={async (buffer: ArrayBuffer) => {
          setLoading(true);
          setImageToBeUploaded(buffer);
          setShowImageCropDialog(false);
          await new Promise((resolve) => setTimeout(resolve, 1000));
          uploadImage();
        }}
      />
      <Box
        component="label"
        style={{
          backgroundColor: 'white',
          width: '100%',
          height: '250px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <input
          accept="image/*"
          style={{ display: 'none' }}
          type="file"
          ref={inputFile}
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            const fileReader = new FileReader();
            const file = evt.currentTarget.files;
            if (file != null && file[0] != null) {
              fileReader.readAsDataURL(file[0]);
              fileReader.onload = (onLoadEvent) => {
                setSrc(onLoadEvent?.target?.result as string);
                setShowImageCropDialog(true);
              };
            }
          }}
        />
        {(!imageLoaded || !imageLoadError) && (
          <Box style={{ position: 'relative' }}>
            <img
              style={{
                width: '254px',
                height: '254px',
                borderRadius: '50%',
                boxShadow: '0px 3px 6px #00000029',
                border: '3px solid #FFFFFF',
              }}
              src={
                imageLoadError ? backup : getPictureUrlResponse?.data.profile
              }
              alt="profile"
              onError={imageOnErrorHandler}
            />
            <Fab
              sx={{
                backgroundColor: 'white',
                position: 'absolute',
                right: '2px',
                bottom: '2px',
                border: `2px solid ${DELETE_COLOR}`,
              }}
              onClick={() => {
                deleteImage();
              }}
            >
              <FeatherIcon
                size={IconSize['SMALL-MEDIUM']}
                name="trash-2"
                color={DELETE_COLOR}
              />
            </Fab>
          </Box>
        )}
        {imageLoadError && (
          <FileUpload
            setSrc={(source) => {
              setSrc(source);
              setShowImageCropDialog(true);
            }}
            inputFile={inputFile}
          />
        )}
      </Box>
    </Box>
  );
};
