import React, { useState } from 'react';
import { Box, Stack } from '@mui/material';
import { FeatherIcon, IconSize } from '../../../components/FeatherIcon';
import { TimeLineItemTitle } from './TimeLineItemTitle';
import { ActionMenu, ActionMenuItem } from './ActionMenu';
import { TimeLineItemDescription } from './TimeLineItemDescription';

export const TimeLineNoteItem = ({
  name,
  description,
  showToStudents,
  isHighlighted,
  highlightColor,
  actionMenuItems,
  isHomework,
}: {
  name?: string;
  description: string;
  showToStudents: boolean;
  isHighlighted?: boolean;
  highlightColor?: string;
  actionMenuItems?: ActionMenuItem[];
  isHomework?: boolean;
}) => {
  const [actionMenuOpen, setActionMenuOpen] = useState(false);
  return (
    <Stack
      style={{
        backgroundColor: isHomework ? '#FCF9F2' : '#F7F8F9',
        borderRadius: '8px',
        boxShadow: '0px 0px 6px #00000029',
        border: '1px solid #AFBBC4',
        borderLeft: isHighlighted
          ? `${highlightColor} 8px solid`
          : '1px solid #AFBBC4',
      }}
    >
      <Stack
        direction="row"
        gap="1em"
        alignItems={isHighlighted ? undefined : 'start'}
      >
        <Stack direction="column" flexGrow={1}>
          <Stack
            direction="row"
            justifyContent="space-between"
            style={{
              borderBottom: '1px solid #AFBBC4',
              paddingLeft: '18px',
              paddingRight: '18px',
              paddingBottom: '8px',
              paddingTop: '8px',
            }}
          >
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
              }}
            >
              <FeatherIcon
                style={{
                  marginRight: '10px',
                  marginLeft: '-4px',
                }}
                name="clipboard"
                size={isHighlighted ? IconSize['EXTRA-LARGE'] : IconSize.SMALL}
                color={isHomework ? '#C69232' : '#172B4D'}
              />
              <TimeLineItemTitle
                isHighlighted={isHighlighted}
                isHomeWork={isHomework}
              >
                {name} {showToStudents}
              </TimeLineItemTitle>
            </Box>
            {actionMenuItems && (
              <Box style={{ marginRight: '-18px' }}>
                <ActionMenu
                  open={actionMenuOpen}
                  setOpen={(newValue: boolean) => setActionMenuOpen(newValue)}
                  items={actionMenuItems}
                />
              </Box>
            )}
          </Stack>
          <Box
            style={{
              paddingLeft: '18px',
              paddingRight: '18px',
              paddingTop: '2px',
              paddingBottom: '2px',
            }}
          >
            <TimeLineItemDescription
              description={description}
              isHighlighted={isHighlighted}
            />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
