/* eslint-disable react/no-array-index-key */
import { Typography } from '@mui/material';
import { addDays, addMonths } from 'date-fns';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { BackAndNextButtons } from '../BackAndNextButtons';
import { MAIN_BORDER } from './consts';
import { Day } from './Day';
import { FavoriteSubjects } from './FavoriteSubjects';
import { FromToSelector } from './FromToSelector';
import { DaySubject, GenerateTimetable, SubjectsForDays } from './interfaces';
import { VisualHourItem } from './VisualHourItem';

const TODAY = new Date();

const keysToDayLabels: { [key: string]: string } = {
  MON: 'Monday',
  TUE: 'Tuesday',
  WED: 'Wednesday',
  THU: 'Thursday',
  FRI: 'Friday',
  SAT: 'Saturday',
  SUN: 'Sunday',
};

export const TimetableGenerator = ({
  favoriteSubjects,
  previousPhase,
  generateTimetable,
}: {
  favoriteSubjects: string[];
  previousPhase: () => void;
  generateTimetable: (ge: GenerateTimetable) => void;
}) => {
  const [from, setFrom] = useState(addDays(TODAY, 7));
  const [to, setTo] = useState(addMonths(TODAY, 2));
  const [subjectsForDays, setSubjectsForDays] = useState<SubjectsForDays>({
    MON: [],
    TUE: [],
    WED: [],
    THU: [],
    FRI: [],
  });

  const hours = [
    '09:00',
    '10:00',
    '11:00',
    '12:00',
    '13:00',
    '14:00',
    '15:00',
    '16:00',
  ];

  return (
    <>
      <FromToSelector
        from={from}
        to={to}
        setFrom={(d: Date) => setFrom(d)}
        setTo={(d: Date) => setTo(d)}
      />
      <FavoriteSubjects favoriteSubjects={favoriteSubjects} />
      <div style={{ display: 'flex' }}>
        <div>
          <div style={{ width: '75px', position: 'relative' }}>
            <div
              style={{
                width: '75px',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'end',
                borderBottom: MAIN_BORDER,
                height: '80px',
                top: '0px',
              }}
            >
              <Typography variant="body2">08:00</Typography>
            </div>
            {hours.map((hour, index) => (
              <VisualHourItem
                hour={hour}
                key={hour + index}
                top={`${(index + 1) * 60 + 20}px`}
              />
            ))}
          </div>
        </div>
        {Object.entries(subjectsForDays).map(([key, value]) => (
          <Day
            dayLabel={key}
            dayLabelForModal={keysToDayLabels[key]}
            key={`${key} + ${uuidv4()}+generator+${value}`}
            subjects={value as DaySubject[]}
            setSubject={(subjectToAdd: DaySubject) => {
              setSubjectsForDays((current) => ({
                ...current,
                [key]: [...current[key], subjectToAdd],
              }));
            }}
            removeSubject={(subjectToRemove: DaySubject) => {
              setSubjectsForDays((currentSubjects) => ({
                ...currentSubjects,
                [key]: [
                  ...currentSubjects[key].filter(
                    (c) =>
                      !(
                        c.from.minutes === subjectToRemove.from.minutes &&
                        c.from.hour === subjectToRemove.from.hour &&
                        c.to.minutes === subjectToRemove.to.minutes &&
                        c.to.hour === subjectToRemove.to.hour &&
                        c.subject === subjectToRemove.subject
                      )
                  ),
                ],
              }));
            }}
          />
        ))}
      </div>
      <BackAndNextButtons
        back={() => previousPhase()}
        next={() => generateTimetable({ from, to, subjectsForDays })}
        nextDisabled={false}
        nextText="Generate"
      />
    </>
  );
};
