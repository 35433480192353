import { Box, Button, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import ScrollContainer from 'react-indiana-drag-scroll';
import { SmallContentBall, SmallDetailBall } from '../../../components/Balls';
import { BulletpointTimesPlanned } from '../../../components/BulletpointTimesPlanned';
import { ProgressBarWithIcon } from '../../../components/ProgressBarWithIcon';
import {
  BOLD_FONT_WEIGHT,
  DETAIL_COLOR,
  LARGE_SPACING,
  LESS_LIGHT_BORDER,
  MEDIUM_SPACING,
  NOT_SELECTED_DETAIL_BORDER,
  SELECTED_BACKGROUND,
  SELECTED_DETAIL_BORDER,
  SMALL_SPACING,
  THEME_MAIN_COLOR,
  THICKER_LIGHT_BORDER,
  THICKER_SELECTED_DETAIL_BORDER,
  XXL_SPACING,
} from '../../../theme';
import { getContentStats, getObjectiveStats } from '../../../utils/stathelpers';
import {
  ContentItem,
  GradeItem,
  LinkItem,
  Objective,
  TransversalItem,
} from '../../ops/components/types/types';
import { ContentProgressBulletpointStats } from '../types';
import { ContentWithAllBulletpoints, mapGradeItem } from './yearPlanUtils';
import { ObjectiveOrContentWithBorder } from '../../../components/ObjectiveOrContentWithBorder';
import { CommonTabs, TabStyle } from '../../../components/CommonTabs';
import { CommonTab } from '../../../components/CommonTab';
import { ContentBallWrapper } from '../../../components/ContentBallWrapper';
import { ObjectivePopoverIcon } from '../lesson-plan/ObjectivePopover';
import { FeatherIcon } from '../../../components/FeatherIcon';
import { createLinksApiQueryKey } from '../../../api/linksapi';
import * as linksApi from '../../../api/linksapi';
import { QUERY_CONFIG } from '../../../utils/queryconfig';
import { CenterLoader } from '../../../components/CenterLoader';
import {
  CONTENT_SELECTOR_CARD as CARD_Z_INDEX,
  CONTENT_BALL_WRAPPER as BALL_WRAPPER_Z_INDEX,
} from '../../../utils/z-index';

const CARD_WIDTH = '371px';

const BORDER_RADIUS = '14px';

const TAB_HEIGHT = '50px';

const MAIN_TEXT_FONT_COLOR = '#3E3E3E';

const TAB_AND_CARD_HEADER_BACKGROUND = '#F7F8FA 0% 0% no-repeat padding-box';

const PlusBoxButton = styled(Button)`
  max-width: 26px;
  min-width: 26px;
  min-height: 24px;
  max-height: 24px;
  border: 1px dashed #b0b0b0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  transition: border-radius 1s;

  &:hover {
    border-radius: 100%;
  }
`;

const BoxButton = styled(Button)`
  max-width: 26px;
  min-width: 26px;
  min-height: 24px;
  max-height: 24px;
  border: 1px solid #b0b0b0;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  transition: border-radius 1s;

  &:hover {
    border-radius: 100%;
  }
`;

const ContentSelectorCard = ({
  children,
  style,
  smallMode,
}: {
  children: React.ReactElement;
  style?: Record<string, string | number>;
  smallMode: boolean;
}) => (
  <Box
    style={{
      transition: 'width 2s',
      background: 'white',
      border: LESS_LIGHT_BORDER,
      borderRadius: BORDER_RADIUS,
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'column',
      boxShadow: '0px 3px 6px #0000001A',
      ...(!smallMode && { minWidth: CARD_WIDTH, maxWidth: CARD_WIDTH }),
      ...style,
    }}
  >
    {children}
  </Box>
);

const MainCardContent = (props: {
  stats:
    | { completed: number; notCompleted: number; planned: number }
    | undefined;
  smallMode: boolean;
  selected: boolean;
  onClick: () => void;
  currentSelectedBulletpoints: number;
  id: string;
  title: string;
}) => (
  <Stack sx={{ mt: LARGE_SPACING }}>
    {props.stats && (
      <ProgressBarWithIcon
        completed={props.stats?.completed}
        notCompleted={props.stats?.notCompleted}
        planned={props.stats?.planned}
        style={{ paddingRight: XXL_SPACING + 1 }}
      />
    )}
    <Button
      fullWidth
      style={{
        textTransform: 'none',
        display: 'block',
        border:
          !props.smallMode && props.selected
            ? THICKER_SELECTED_DETAIL_BORDER
            : THICKER_LIGHT_BORDER,
        backgroundColor: 'white',
        borderRadius: '6px',
      }}
      onClick={props.onClick}
    >
      <Box style={{ display: 'flex', alignItems: 'center' }}>
        <Typography
          style={{
            display: 'block',
            color: DETAIL_COLOR,
            fontWeight: BOLD_FONT_WEIGHT,
            borderRight: SELECTED_DETAIL_BORDER,
          }}
          sx={{ pr: LARGE_SPACING }}
          variant="body1"
        >
          {props.id}
        </Typography>
        <Typography
          variant="body2"
          style={{
            display: 'block',
            textAlign: 'left',
            color: MAIN_TEXT_FONT_COLOR,
          }}
          sx={{ ml: LARGE_SPACING }}
        >
          {props.title}
        </Typography>
        {props.currentSelectedBulletpoints > 0 && (
          <SmallContentBall style={{ marginLeft: 'auto' }}>
            <Typography
              variant="caption"
              style={{
                color: MAIN_TEXT_FONT_COLOR,
                zIndex: BALL_WRAPPER_Z_INDEX + 1,
              }}
            >
              {props.currentSelectedBulletpoints}
            </Typography>
          </SmallContentBall>
        )}
      </Box>
    </Button>
  </Stack>
);

const getBulletpointIdsFromObjective = (obj: Objective) =>
  [...(obj.contents ?? []), ...(obj.transversals ?? [])].flatMap(
    (contentOrTransversal) =>
      Object.keys(contentOrTransversal.bulletpoints ?? {})
  );

const MainCard = ({
  allContentItems,
  allObjectives,
  bpStats,
  selectedBulletpointIds,
  selectedContentIds,
  setSelectedContentIds,
  selectedObjectiveIds,
  setSelectedObjectiveIds,
  smallMode,
  setOpenTab,
  objectiveMode,
}: {
  allContentItems: ContentWithAllBulletpoints[];
  allObjectives: Objective[];
  bpStats?: ContentProgressBulletpointStats[];
  selectedBulletpointIds?: string[];
  selectedContentIds: string[];
  setSelectedContentIds: (contentIds: string[]) => void;
  selectedObjectiveIds: string[];
  setSelectedObjectiveIds: (objectiveIds: string[]) => void;
  smallMode: boolean;
  setOpenTab: (val: string) => void;
  objectiveMode: boolean;
}) => (
  <Box sx={{ px: LARGE_SPACING, pb: LARGE_SPACING }}>
    {!objectiveMode &&
      allContentItems.map((contentItem) => {
        const contentStats = bpStats
          ? getContentStats(
              contentItem.bulletpoints.map((bp) => bp.bulletpointId),
              bpStats
            )
          : undefined;
        const currentSelectedBulletpoints = contentItem.bulletpoints.filter(
          (bp) => selectedBulletpointIds?.includes(bp.bulletpointId)
        ).length;
        return (
          <MainCardContent
            key={`contentItemSelector${contentItem.contentId}`}
            stats={contentStats}
            smallMode={smallMode}
            selected={selectedContentIds.includes(contentItem.contentId)}
            onClick={() =>
              !smallMode
                ? setSelectedContentIds(
                    selectedContentIds.includes(contentItem.contentId)
                      ? selectedContentIds.filter(
                          (c) => c !== contentItem.contentId
                        )
                      : [...selectedContentIds, contentItem.contentId]
                  )
                : setOpenTab(contentItem.contentId)
            }
            id={contentItem.contentId}
            title={contentItem.title}
            currentSelectedBulletpoints={currentSelectedBulletpoints}
          />
        );
      })}
    {objectiveMode &&
      allObjectives.map((obj: Objective) => {
        const objectiveStats = bpStats
          ? getObjectiveStats(obj, bpStats)
          : undefined;
        const currentSelectedBulletpoints = getBulletpointIdsFromObjective(
          obj
        ).filter((bp) => selectedBulletpointIds?.includes(bp)).length;

        return (
          <MainCardContent
            key={`contentItemSelector${obj.code}`}
            stats={objectiveStats}
            smallMode={smallMode}
            selected={selectedObjectiveIds.includes(obj.code)}
            onClick={() =>
              !smallMode
                ? setSelectedObjectiveIds(
                    selectedObjectiveIds.includes(obj.code)
                      ? selectedObjectiveIds.filter((c) => c !== obj.code)
                      : [...selectedObjectiveIds, obj.code]
                  )
                : setOpenTab(obj.code)
            }
            id={obj.code}
            title={obj.name}
            currentSelectedBulletpoints={currentSelectedBulletpoints}
          />
        );
      })}
  </Box>
);

const ContentOrObjectiveCardButton = (props: {
  selected: boolean;
  onClick?: () => void;
  stat: number | undefined;
  bulletpointDesc: string;
  objective?: Objective;
  bulletpointId: string;
  openLinksForBulletpoint?: (val: string) => void;
  subjectId: string;
  gradeId: string;
}) => {
  const links = useQuery({
    queryKey: [createLinksApiQueryKey(props.subjectId, props.gradeId)],
    queryFn: () =>
      linksApi.getBySubjectAndGradeId(props.subjectId, props.gradeId),
    enabled: !!props.openLinksForBulletpoint,
    ...QUERY_CONFIG,
  });
  const linksLength = ((links?.data as LinkItem[]) ?? []).filter((link) =>
    link.bulletpointIds.includes(props.bulletpointId)
  ).length;
  const hasLinks = linksLength > 0;
  return (
    <Button
      fullWidth
      style={{
        textTransform: 'none',
        display: 'block',
        ...(props.selected
          ? {
              background: SELECTED_BACKGROUND,
              border: THICKER_SELECTED_DETAIL_BORDER,
            }
          : {
              background: 'white',
              border: NOT_SELECTED_DETAIL_BORDER,
            }),
        ...(!props.onClick && { cursor: 'default' }),
        ...(props.openLinksForBulletpoint && {
          border: 'none',
          background: 'none',
        }),
      }}
      sx={{ my: MEDIUM_SPACING }}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
    >
      <Box
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {!!props.openLinksForBulletpoint && !hasLinks && (
          <PlusBoxButton
            onClick={() =>
              props.openLinksForBulletpoint &&
              props.openLinksForBulletpoint(props.bulletpointId)
            }
          >
            <FeatherIcon name="plus" />
          </PlusBoxButton>
        )}
        {!!props.openLinksForBulletpoint && hasLinks && (
          <BoxButton
            onClick={() =>
              props.openLinksForBulletpoint &&
              props.openLinksForBulletpoint(props.bulletpointId)
            }
          >
            <Typography
              variant="body2"
              style={{ color: THEME_MAIN_COLOR, fontWeight: BOLD_FONT_WEIGHT }}
            >
              {linksLength}
            </Typography>
          </BoxButton>
        )}
        <Typography
          variant="caption"
          style={{
            display: 'block',
            textAlign: 'left',
            color: MAIN_TEXT_FONT_COLOR,
            marginRight: 'auto',
          }}
          sx={{ pl: SMALL_SPACING }}
        >
          {props.bulletpointDesc}
        </Typography>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          {props.stat && (
            <BulletpointTimesPlanned
              fontColor="#707070"
              useIcon={false}
              timesPlanned={props.stat}
            />
          )}
          {props.objective && (
            <Box style={{ marginTop: '8px', marginLeft: '4px' }}>
              <ObjectivePopoverIcon
                iconColor="#707070"
                objective={props.objective}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Button>
  );
};

const ContentOrObjectiveCard = ({
  selectableContentItem,
  selectableObjectiveItem,
  gradeItem,
  bpStats,
  selectedBulletpointIds,
  setSelectedBulletpointIds,
  smallMode,
  id,
  title,
  objectiveMode,
  openLinksForBulletpoint,
  subjectCode,
}: {
  selectableContentItem?: ContentWithAllBulletpoints;
  selectableObjectiveItem?: Objective;
  gradeItem: GradeItem;
  bpStats?: ContentProgressBulletpointStats[];
  selectedBulletpointIds?: string[];
  setSelectedBulletpointIds?: (bulletpointIds: string[]) => void;
  smallMode: boolean;
  id: string;
  title: string;
  objectiveMode: boolean;
  openLinksForBulletpoint?: (val: string) => void;
  subjectCode: string;
}) => (
  <Box>
    <Box
      style={{
        display: 'flex',
        background: TAB_AND_CARD_HEADER_BACKGROUND,
        borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`,
      }}
      sx={{ px: LARGE_SPACING, py: XXL_SPACING }}
    >
      <Stack
        style={{
          display: 'flex',
        }}
        sx={{ pl: smallMode ? SMALL_SPACING : XXL_SPACING }}
        direction="row"
      >
        <ObjectiveOrContentWithBorder prSpacing={0} objectiveOrContent={id} />
        <Stack justifyContent="center" direction="column">
          {title}
        </Stack>
      </Stack>
    </Box>
    <Box sx={{ pl: smallMode ? SMALL_SPACING : XXL_SPACING }}>
      <Box sx={{ p: LARGE_SPACING }}>
        {!objectiveMode &&
          selectableContentItem!!.bulletpoints.map((bulletpointItem) => {
            const stat = bpStats?.find(
              (bpStat) => bpStat.bulletpointId === bulletpointItem.bulletpointId
            )?.timesPlanned;
            const objective = gradeItem.objectives?.find((o) =>
              [...(o.transversals ?? []), ...(o.contents ?? [])]
                .flatMap((cOrT) => Object.keys(cOrT.bulletpoints))
                .some((bp) => bp === bulletpointItem.bulletpointId)
            );
            return (
              <ContentOrObjectiveCardButton
                subjectId={subjectCode}
                gradeId={gradeItem.grade.toString()}
                bulletpointId={bulletpointItem.bulletpointId}
                openLinksForBulletpoint={openLinksForBulletpoint}
                key={`contentItemSelectorButtonBulletpoint${bulletpointItem.bulletpointId}`}
                selected={
                  !!(
                    selectedBulletpointIds &&
                    selectedBulletpointIds.includes(
                      bulletpointItem.bulletpointId
                    )
                  )
                }
                onClick={
                  setSelectedBulletpointIds
                    ? () =>
                        setSelectedBulletpointIds(
                          selectedBulletpointIds?.includes(
                            bulletpointItem.bulletpointId
                          )
                            ? selectedBulletpointIds?.filter(
                                (c) => c !== bulletpointItem.bulletpointId
                              )
                            : [
                                ...(selectedBulletpointIds ?? []),
                                bulletpointItem.bulletpointId,
                              ]
                        )
                    : undefined
                }
                stat={stat}
                bulletpointDesc={bulletpointItem.description}
                objective={objective}
              />
            );
          })}
        {objectiveMode &&
          [
            ...(selectableObjectiveItem?.contents ?? []),
            ...(selectableObjectiveItem?.transversals ?? []),
          ].map((contentOrTransversal: ContentItem | TransversalItem) => (
            <Stack
              key={`objectiveItemSelectorWrapper${contentOrTransversal.code}`}
            >
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <ContentBallWrapper contentCode={contentOrTransversal.code} />
                <Typography
                  style={{ marginTop: '8px', marginLeft: '8px' }}
                  variant="body2"
                >
                  {contentOrTransversal.name}
                </Typography>
              </Box>
              <Box sx={{ my: LARGE_SPACING }}>
                {Object.entries(contentOrTransversal.bulletpoints).map(
                  (entry) => {
                    const bulletpointId = entry[0];
                    const bulletpointDesc = entry[1];
                    const stat = bpStats?.find(
                      (bpStat) => bpStat.bulletpointId === bulletpointId
                    )?.timesPlanned;
                    return (
                      <ContentOrObjectiveCardButton
                        subjectId={subjectCode}
                        gradeId={gradeItem.grade.toString()}
                        bulletpointId={bulletpointId}
                        openLinksForBulletpoint={openLinksForBulletpoint}
                        key={`objectiveItemSelectorButtonBulletpoint${bulletpointId}`}
                        selected={
                          !!selectedBulletpointIds?.includes(bulletpointId)
                        }
                        onClick={
                          selectedBulletpointIds && setSelectedBulletpointIds
                            ? () =>
                                setSelectedBulletpointIds(
                                  selectedBulletpointIds.includes(bulletpointId)
                                    ? selectedBulletpointIds.filter(
                                        (c) => c !== bulletpointId
                                      )
                                    : [...selectedBulletpointIds, bulletpointId]
                                )
                            : undefined
                        }
                        stat={stat}
                        bulletpointDesc={bulletpointDesc}
                      />
                    );
                  }
                )}
              </Box>
            </Stack>
          ))}
      </Box>
    </Box>
  </Box>
);

const Tab = ({
  text,
  selected,
  hasContent,
  onClick,
}: {
  text: string;
  selected: boolean;
  hasContent: boolean;
  onClick: () => void;
}) => (
  <Button
    onClick={onClick}
    style={{
      color: '#172B4D',
      textTransform: 'none',
      borderLeft: LESS_LIGHT_BORDER,
      borderTop: LESS_LIGHT_BORDER,
      borderBottom: LESS_LIGHT_BORDER,
      borderBottomLeftRadius: '10px',
      borderTopLeftRadius: '10px',
      maxHeight: '57px',
      width: '59px',
      background: selected
        ? '#FBFBFB 0% 0% no-repeat padding-box'
        : TAB_AND_CARD_HEADER_BACKGROUND,
      boxShadow: '0px 3px 6px #00000029',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      ...(hasContent && { borderRight: `4px solid ${DETAIL_COLOR}` }),
    }}
    sx={{ px: LARGE_SPACING }}
  >
    <Typography
      variant="body2"
      style={{
        ...(selected ? { fontWeight: BOLD_FONT_WEIGHT } : {}),
      }}
      sx={{ py: LARGE_SPACING }}
    >
      {text}
    </Typography>
    {hasContent && (
      <Box style={{ marginTop: '-14px', marginLeft: '6px' }}>
        <SmallDetailBall />
      </Box>
    )}
  </Button>
);

const Tabs = ({
  allContentItems,
  selectedContentIds,
  selectedBulletpointIds,
  setSelectedContentIds,
  selectedObjectiveIds,
  setSelectedObjectiveIds,
  allObjectiveItems,
  setShowAll,
  showAll,
  smallMode = false,
  setOpenTab,
  openTab,
  objectiveMode,
}: {
  allContentItems: ContentWithAllBulletpoints[];
  selectedContentIds: string[];
  selectedBulletpointIds?: string[];
  setSelectedContentIds: (contentIds: string[]) => void;
  setShowAll: () => void;
  selectedObjectiveIds: string[];
  setSelectedObjectiveIds: (objectiveIds: string[]) => void;
  allObjectiveItems: Objective[];
  showAll: boolean;
  smallMode?: boolean;
  setOpenTab: (tab: string) => void;
  openTab: string;
  objectiveMode: boolean;
}) => (
  <Box
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'stretch',
      alignItems: 'stretch',
      marginTop: TAB_HEIGHT,
    }}
  >
    <Tab
      key="contentSelectorTab-all"
      onClick={() => (smallMode ? setOpenTab('All') : setShowAll())}
      text="All"
      selected={smallMode ? openTab === 'All' : showAll}
      hasContent={false}
    />
    {!objectiveMode &&
      allContentItems.map((ac) => {
        const selected = selectedContentIds?.includes(ac.contentId);
        const updateProps = selected
          ? [...selectedContentIds.filter((sc) => sc !== ac.contentId)]
          : [...selectedContentIds, ac.contentId];
        return (
          <Tab
            onClick={() =>
              smallMode
                ? setOpenTab(ac.contentId)
                : setSelectedContentIds(updateProps)
            }
            text={ac.contentId}
            key={`contentSelectorTab-${ac.contentId}`}
            selected={smallMode ? openTab === ac.contentId : selected}
            hasContent={
              !!(
                selectedBulletpointIds &&
                ac.bulletpoints.some((bp) =>
                  selectedBulletpointIds.includes(bp.bulletpointId)
                )
              )
            }
          />
        );
      })}
    {objectiveMode &&
      allObjectiveItems.map((obj) => {
        const selected = selectedObjectiveIds?.includes(obj.code);
        const updateProps = selected
          ? [...selectedObjectiveIds.filter((sc) => sc !== obj.code)]
          : [...selectedObjectiveIds, obj.code];
        return (
          <Tab
            onClick={() =>
              smallMode
                ? setOpenTab(obj.code)
                : setSelectedObjectiveIds(updateProps)
            }
            text={obj.code}
            key={`contentSelectorTab-${obj.code}`}
            selected={smallMode ? openTab === obj.code : selected}
            hasContent={
              !!(
                selectedBulletpointIds &&
                getBulletpointIdsFromObjective(obj).some((bp) =>
                  selectedBulletpointIds.includes(bp)
                )
              )
            }
          />
        );
      })}
  </Box>
);

export const ContentSelector = ({
  gradeItem,
  selectedContentIds,
  setSelectedContentIds,
  setSelectedObjectiveIds,
  selectedObjectiveIds,
  selectedBulletpointIds,
  setSelectedBulletpointIds,
  bpStats,
  smallMode = false,
  openLinksForBulletpoint,
  subjectCode,
  showLoader = false,
}: {
  gradeItem: GradeItem;
  selectedContentIds: string[];
  setSelectedContentIds: (contentIds: string[]) => void;
  selectedObjectiveIds: string[];
  setSelectedObjectiveIds: (objectiveIds: string[]) => void;
  selectedBulletpointIds?: string[];
  setSelectedBulletpointIds?: (bulletpointIds: string[]) => void;
  bpStats?: ContentProgressBulletpointStats[];
  smallMode?: boolean;
  openLinksForBulletpoint?: (val: string) => void;
  subjectCode: string;
  showLoader?: boolean;
}) => {
  const [showAll, setShowAll] = useState(true);
  const [objectiveMode, setObjectiveMode] = useState(false);
  const [openTab, setOpenTab] = useState<string>('All');
  const allContentItems: ContentWithAllBulletpoints[] = mapGradeItem(gradeItem);
  const allObjectives = gradeItem?.objectives ?? [];
  return (
    <ScrollContainer hideScrollbars={false}>
      <Box
        style={{
          display: 'flex',
          overflowX: 'auto',
          width: 'fit-content',
        }}
      >
        {showLoader && <CenterLoader />}
        {!showLoader && (
          <>
            <Tabs
              showAll={showAll}
              setShowAll={() => setShowAll((prev) => !prev)}
              allContentItems={allContentItems}
              selectedContentIds={selectedContentIds}
              selectedBulletpointIds={selectedBulletpointIds}
              setSelectedContentIds={setSelectedContentIds}
              selectedObjectiveIds={selectedObjectiveIds}
              setSelectedObjectiveIds={setSelectedObjectiveIds}
              allObjectiveItems={allObjectives}
              smallMode={smallMode}
              openTab={openTab}
              setOpenTab={(tab: string) => setOpenTab(tab)}
              objectiveMode={objectiveMode}
            />
            {showAll && (!smallMode || openTab === 'All') && (
              <ContentSelectorCard
                smallMode={smallMode}
                style={{
                  zIndex: CARD_Z_INDEX,
                }}
              >
                <Stack sx={{ p: 0, m: 0 }}>
                  <CommonTabs
                    style={{
                      borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`,
                      height: TAB_HEIGHT,
                    }}
                    tabStyle={TabStyle.BACKGROUND}
                  >
                    <CommonTab
                      tabTranslationId="yearplan.contentmodal.contentAreasTab"
                      selected={!objectiveMode}
                      clickTab={() => setObjectiveMode(false)}
                      tabStyle={TabStyle.BACKGROUND}
                      style={{
                        borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`,
                        height: TAB_HEIGHT,
                        ...(objectiveMode && {
                          borderBottom: LESS_LIGHT_BORDER,
                        }),
                        ...(!objectiveMode && {
                          borderRight: LESS_LIGHT_BORDER,
                          background: 'white',
                        }),
                      }}
                    />
                    <CommonTab
                      tabTranslationId="yearplan.contentmodal.objectivesTab"
                      selected={objectiveMode}
                      clickTab={() => setObjectiveMode(true)}
                      tabStyle={TabStyle.BACKGROUND}
                      style={{
                        height: TAB_HEIGHT,
                        ...(!objectiveMode && {
                          borderRadius: `0 ${BORDER_RADIUS} 0 0`,
                          borderBottom: LESS_LIGHT_BORDER,
                        }),
                        ...(objectiveMode && {
                          borderRadius: `${BORDER_RADIUS} ${BORDER_RADIUS} 0 0`,
                          borderLeft: LESS_LIGHT_BORDER,
                          background: 'white',
                        }),
                      }}
                    />
                  </CommonTabs>
                  <MainCard
                    allObjectives={allObjectives}
                    objectiveMode={objectiveMode}
                    allContentItems={allContentItems}
                    bpStats={bpStats}
                    selectedBulletpointIds={selectedBulletpointIds}
                    selectedContentIds={selectedContentIds}
                    setSelectedContentIds={setSelectedContentIds}
                    selectedObjectiveIds={selectedObjectiveIds}
                    setSelectedObjectiveIds={setSelectedObjectiveIds}
                    smallMode={smallMode}
                    setOpenTab={(val: string) => setOpenTab(val)}
                  />
                </Stack>
              </ContentSelectorCard>
            )}
            {!smallMode &&
              !objectiveMode &&
              selectedContentIds
                .map((s) => allContentItems.find((a) => a.contentId === s))
                .map((selectableContentItem, index, arr) => (
                  <ContentSelectorCard
                    smallMode={smallMode}
                    key={`selectableContentCard${
                      selectableContentItem!!.contentId
                    }`}
                    style={{
                      marginLeft: showAll ? '-20px' : '',
                      zIndex: CARD_Z_INDEX - (index + 1),
                      backgroundColor: '#FBFBFB',
                      marginTop: TAB_HEIGHT,
                    }}
                  >
                    <ContentOrObjectiveCard
                      subjectCode={subjectCode}
                      id={selectableContentItem!!.contentId}
                      title={selectableContentItem!!.title}
                      gradeItem={gradeItem}
                      selectableContentItem={selectableContentItem}
                      bpStats={bpStats}
                      selectedBulletpointIds={selectedBulletpointIds}
                      setSelectedBulletpointIds={setSelectedBulletpointIds}
                      smallMode={false}
                      objectiveMode={false}
                      openLinksForBulletpoint={openLinksForBulletpoint}
                    />
                  </ContentSelectorCard>
                ))}
            {!smallMode &&
              objectiveMode &&
              selectedObjectiveIds
                .map((s) => allObjectives.find((a) => a.code === s))
                .map((selectableObjectiveItem, index, arr) => (
                  <ContentSelectorCard
                    smallMode={smallMode}
                    key={`selectableObjectiveCard${
                      selectableObjectiveItem!!.code
                    }`}
                    style={{
                      marginLeft: showAll ? '-20px' : '',
                      zIndex: arr.length - index,
                      backgroundColor: '#FBFBFB',
                      marginTop: TAB_HEIGHT,
                    }}
                  >
                    <ContentOrObjectiveCard
                      gradeItem={gradeItem}
                      subjectCode={subjectCode}
                      id={selectableObjectiveItem!!.code}
                      title={selectableObjectiveItem!!.name}
                      selectableObjectiveItem={selectableObjectiveItem}
                      bpStats={bpStats}
                      selectedBulletpointIds={selectedBulletpointIds}
                      setSelectedBulletpointIds={setSelectedBulletpointIds}
                      smallMode={false}
                      objectiveMode={true}
                      openLinksForBulletpoint={openLinksForBulletpoint}
                    />
                  </ContentSelectorCard>
                ))}
            {smallMode && !objectiveMode && openTab !== 'All' && (
              <ContentSelectorCard
                smallMode={smallMode}
                key={`selectableContentCard${openTab}`}
                style={{
                  zIndex: CARD_Z_INDEX,
                  backgroundColor: '#FBFBFB',
                }}
              >
                <ContentOrObjectiveCard
                  subjectCode={subjectCode}
                  id={
                    allContentItems.find((a) => a.contentId === openTab)!!
                      .contentId
                  }
                  title={
                    allContentItems.find((a) => a.contentId === openTab)!!.title
                  }
                  selectableContentItem={allContentItems.find(
                    (a) => a.contentId === openTab
                  )}
                  gradeItem={gradeItem}
                  bpStats={bpStats}
                  selectedBulletpointIds={selectedBulletpointIds}
                  setSelectedBulletpointIds={setSelectedBulletpointIds}
                  smallMode={true}
                  objectiveMode={false}
                  openLinksForBulletpoint={openLinksForBulletpoint}
                />
              </ContentSelectorCard>
            )}
            {smallMode && objectiveMode && openTab !== 'All' && (
              <ContentSelectorCard
                smallMode={smallMode}
                key={`selectableContentCard${openTab}`}
                style={{
                  zIndex: CARD_Z_INDEX,
                  backgroundColor: '#FBFBFB',
                }}
              >
                <ContentOrObjectiveCard
                  gradeItem={gradeItem}
                  subjectCode={subjectCode}
                  id={allObjectives.find((o) => o.code === openTab)!!.code}
                  title={allObjectives.find((o) => o.code === openTab)!!.name}
                  selectableObjectiveItem={allObjectives.find(
                    (o) => o.code === openTab
                  )}
                  bpStats={bpStats}
                  selectedBulletpointIds={selectedBulletpointIds}
                  setSelectedBulletpointIds={setSelectedBulletpointIds}
                  smallMode={true}
                  objectiveMode={true}
                  openLinksForBulletpoint={openLinksForBulletpoint}
                />
              </ContentSelectorCard>
            )}
          </>
        )}
      </Box>
    </ScrollContainer>
  );
};
