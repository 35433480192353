import { Card, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { getSubjectsByGrade } from '../../utils/ops/getSubjectsByGrade';
import { parseSubjectList } from '../../utils/ops/utils';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import { SubjectItem } from '../ops/components/types/types';
import {
  parseTransversalNumber,
  parseTransversalsMap,
} from './TransversalHelpers';
import { TransversalProgress } from './TransversalProgress';
import { GroupsWithFavoritesAndStats } from './types';

type Props = {
  subjects: SubjectItem[];
  isStudent?: boolean;
  bpStats?: ContentProgressBulletpointStats[];
  groups?: GroupsWithFavoritesAndStats[];
  ops?: any;
};

export const TransversalProgressContainer = ({
  subjects,
  isStudent,
  bpStats,
  groups,
  ops,
}: Props) => {
  const transversals = parseTransversalsMap(subjects);
  return (
    <>
      <Card
        variant="outlined"
        style={{ padding: '25px', border: '0.5px solid' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Transversal Skills</Typography>
            <Divider />
          </Grid>
          {Array.from(transversals.keys())
            .sort(
              (a, b) =>
                parseTransversalNumber(transversals.get(a)!![0].code) -
                parseTransversalNumber(transversals.get(b)!![0].code)
            )
            .map((key) => (
              <TransversalProgress
                grade={key.charAt(key.length - 1)}
                key={key}
                label={key}
                transversals={transversals.get(key)!!}
                bulletpointStats={bpStats}
                isStudent={isStudent}
                groupsWithBulletpointStats={
                  groups
                    ? groups
                        ?.filter((g) =>
                          transversals
                            .get(key)!!
                            ?.some((tr) =>
                              g.favorites.includes(
                                `${tr.subject.code}-${tr.grade.grade}`
                              )
                            )
                        )
                        .map((gr) => ({
                          groupId: gr.groupId!!,
                          groupName: gr.name,
                          stats: gr.stats,
                          transversalsForStats: parseTransversalsMap(
                            getSubjectsByGrade(
                              parseSubjectList(
                                ops,
                                { onlyFavorites: true },
                                gr.favorites
                              )
                            )
                          ).get(key)!!,
                        }))
                    : undefined
                }
              />
            ))}
        </Grid>
      </Card>
    </>
  );
};
