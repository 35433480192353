import React from 'react';
import { Button, Box } from '@mui/material';
import { useIntl } from 'react-intl';
import { Helmet } from 'react-helmet';
import { useBreakpoints } from '../../../hooks/useBreakpoints';
import { OAUTH_LOGIN } from '../../../api/constants';

export const LoginPage = () => {
  const intl = useIntl();
  const { useStickyHeaders } = useBreakpoints();

  return (
    <>
      <Helmet>
        <meta name="description" content="" />
        <title>
          {intl.formatMessage({ id: 'application.title' })} -{' '}
          {intl.formatMessage({ id: 'loginPage.table.title' })}
        </title>
      </Helmet>
      <Box sx={{ my: useStickyHeaders ? 20 : 0 }}>
        <h1 style={{ textAlign: 'center' }}>Log in to Curriculum</h1>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            onClick={() => window.location.replace(`${OAUTH_LOGIN}`)}
            sx={{ p: 5 }}
            style={{ width: '300px' }}
            color="primary"
            variant="contained"
          >
            Proceed
          </Button>
        </div>
      </Box>
    </>
  );
};
