import {
  format,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { Time } from './interfaces';

export const formatTime = (t: Time): string =>
  format(
    setSeconds(setMinutes(setHours(new Date(), t.hour), t.minutes), 0),
    'HH:mm'
  );

export const timeToDate = (t: Time, d?: Date): Date =>
  setSeconds(setMinutes(setHours(d ?? new Date(), t.hour), t.minutes), 0);

export const dateToTime = (d: Date): Time => ({
  hour: getHours(d),
  minutes: getMinutes(d),
});

export const timestampWithoutTimezone = (date: Date) =>
  formatInTimeZone(
    date,
    Intl.DateTimeFormat().resolvedOptions().timeZone,
    'yyyy-MM-dd HH:mm:ss'
  );
