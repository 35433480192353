import { CircularProgress, Grid } from '@mui/material';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import * as usersApi from '../../api/usersapi';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { StickyHeader } from '../my-plan/StickyHeader';
import { getSubjectsByGrade } from '../../utils/ops/getSubjectsByGrade';
import { parseSubjectList } from '../../utils/ops/utils';
import { SubjectProgressContainer } from './SubjectProgressContainer';
import { TransversalProgressContainer } from './TransversalProgressContainer';
import {
  CONTENT_PROGRESS_API_QUERY_KEY,
  getAllStudentStats,
} from '../../api/contentprogressapi';
import { useOps } from '../../hooks/useOps';

const studentQueryFn = () => getAllStudentStats();

export const StudentProgressPage = () => {
  const { data: favoriteSubjects } = useQuery<string[]>(
    ['studentfavorites'],
    async () => usersApi.getStudentFavorites(),
    {
      ...QUERY_CONFIG,
    }
  );
  const { ops } = useOps();
  const { data: bpdata } = useQuery({
    queryKey: [CONTENT_PROGRESS_API_QUERY_KEY],
    queryFn: studentQueryFn,
    ...QUERY_CONFIG,
    enabled: true,
  });

  if (!ops || !bpdata) {
    return <CircularProgress />;
  }

  const subjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: true }, favoriteSubjects)
  );

  return (
    <>
      <StickyHeader container>
        <Breadcrumbs currentPage="Progress" previousPages={[]} />
      </StickyHeader>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubjectProgressContainer
            subjects={subjects}
            bpStats={bpdata ?? []}
          />
        </Grid>
        <Grid item xs={12}>
          <TransversalProgressContainer
            subjects={subjects}
            isStudent={true}
            bpStats={bpdata ?? []}
          />
        </Grid>
      </Grid>
    </>
  );
};
