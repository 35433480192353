/* eslint-disable @typescript-eslint/no-explicit-any */
import { BOOKS_API_URL } from './constants';
import { apiGet, apiPost } from './genericapi';

export interface Book {
  title: string;
  grade: string;
  subject: string;
  bookId: string;
}

export const BOOKS_QUERY_KEY = 'books';

export const getBookBySubjectAndGrade = (
  subject: string,
  grade: string
): Promise<any> => apiGet(`${BOOKS_API_URL}?subject=${subject}&grade=${grade}`);
export const postBook = (payload: Book): Promise<any> =>
  apiPost(BOOKS_API_URL, payload);
