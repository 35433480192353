import { Box, Button, Tooltip } from '@mui/material';
import React from 'react';
import { FeatherIcon, IconSize } from './FeatherIcon';

type IconActionButtonSize = 'medium' | 'large';

const sizes: Record<IconActionButtonSize, IconActionButtonSizeDefinition> = {
  medium: {
    width: '30px',
    height: '30px',
    paddingTop: '5px',
    iconSize: IconSize.SMALL,
  },
  large: {
    width: '54px',
    height: '54px',
    paddingTop: '15px',
    iconSize: IconSize['SMALL-MEDIUM'],
  },
};

type IconActionButtonSizeDefinition = {
  width: string;
  height: string;
  paddingTop: string;
  iconSize: IconSize;
};

export const IconActionButton = (props: {
  onClick: () => void;
  name: string;
  tooltip?: string;
  color: string;
  size: IconActionButtonSize;
  disabled?: boolean;
}) => (
  <Button
    disabled={props.disabled}
    onClick={props.onClick}
    style={{
      padding: 0,
      minWidth: sizes[props.size].width,
      maxWidth: sizes[props.size].width,
      maxHeight: '100%',
    }}
  >
    <Box
      style={{
        border: `1px solid ${props.color}`,
        borderRadius: '100%',
        width: sizes[props.size].width,
        height: sizes[props.size].height,
        paddingTop: sizes[props.size].paddingTop,
        maxWidth: '100%',
        maxHeight: '100%',
      }}
    >
      <Tooltip title={props.tooltip}>
        <div>
          <FeatherIcon
            color={props.color}
            size={sizes[props.size].iconSize}
            name={props.name}
          />
        </div>
      </Tooltip>
    </Box>
  </Button>
);
