import React from 'react';
import { DraggableFavoriteSubject } from './DraggableFavoriteSubject';

export const FavoriteSubjects = ({
  favoriteSubjects,
}: {
  favoriteSubjects: string[];
}) => (
  <div style={{ margin: '2px' }}>
    {favoriteSubjects?.map((fS) => (
      <DraggableFavoriteSubject key={`draggable-${fS}`} favoriteSubject={fS} />
    ))}
  </div>
);
