import { QueryClient } from '@tanstack/react-query';
import { LS_KEY_TOKEN } from './constants';
import { BASE_URL } from '../api/constants';
import { Log } from './debug';
import { getToken } from './auth/session';

export enum MethodEnum {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

export enum HeaderEnum {
  CONTENT_TYPE = 'Content-Type',
  ORIGIN = 'Origin',
  AUTHORIZATION = 'Authorization',
}

export type ApiCallType = {
  url: string;
  method?: MethodEnum;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  payload?: any;
};

const queryClient = new QueryClient();

const clearToken = () => {
  // eslint-disable-next-line no-console
  console.log('Permission denied clearing jwt token');
  localStorage.removeItem(LS_KEY_TOKEN);

  queryClient.invalidateQueries();
  window.location.href = '/login';
};

const call = async ({
  url,
  method = MethodEnum.GET,
  payload,
}: ApiCallType): Promise<Response> => {
  const token = getToken();

  const headers = new Headers();
  headers.append(HeaderEnum.CONTENT_TYPE, 'application/json');
  headers.append(HeaderEnum.ORIGIN, BASE_URL || '');

  if (token) {
    headers.append(HeaderEnum.AUTHORIZATION, `Bearer ${token}`);
  }

  Log.trace(`${method} request to ${url}`, 'http.ts');
  headers.forEach((v: string, k: string) => {
    Log.trace(`${k} = ${v}`, 'http.ts:headers');
  });
  Log.trace(payload, 'http.ts:payload');
  const response = await fetch(url, {
    method,
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'include',
    headers,
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: payload && JSON.stringify(payload),
  });
  if (
    response.status !== 200 &&
    response.status !== 201 &&
    response.status !== 204
  ) {
    Log.error(response, 'http.ts:response');

    if (
      response.status === 403 ||
      response.status === 401 ||
      response.headers.get('x-amzn-errortype') === 'UnauthorizedException' ||
      response.headers.get('x-amzn-errortype') === 'AccessDeniedException'
    ) {
      clearToken();
    }
    queryClient.invalidateQueries();
  }

  return response;
};

export const doGet = async (props: ApiCallType): Promise<Response> =>
  call({
    method: MethodEnum.GET,
    ...props,
  });

export const doPost = async (props: ApiCallType): Promise<Response> =>
  call({
    method: MethodEnum.POST,
    ...props,
  });

export const doPut = async (props: ApiCallType): Promise<Response> =>
  call({
    method: MethodEnum.PUT,
    ...props,
  });

export const doDelete = async (props: ApiCallType): Promise<Response> =>
  call({
    method: MethodEnum.DELETE,
    ...props,
  });

export const doPatch = async (props: ApiCallType): Promise<Response> =>
  call({
    method: MethodEnum.PATCH,
    ...props,
  });
