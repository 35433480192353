import { Button, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const AddStudent = ({
  addStudent,
  existingStudents = [],
}: {
  addStudent: (email: string) => void;
  existingStudents: string[];
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const [email, setEmail] = useState('');

  const displayError = (message: string) => {
    enqueueSnackbar(message, {
      variant: 'error',
    });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <TextField
        style={{ margin: '20px', minWidth: '250px' }}
        variant="outlined"
        value={email}
        onChange={(evt) => setEmail(evt.target.value)}
        placeholder="student@school.com"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          if (!email.match(emailRegex)) {
            displayError(
              intl.formatMessage({
                id: 'groups.student.dialog.error.email',
              })
            );
          } else if (existingStudents?.includes(email)) {
            displayError(
              intl.formatMessage({
                id: 'groups.student.dialog.error.existing',
              })
            );
          } else {
            addStudent(email);
          }
        }}
      >
        Add
      </Button>
    </div>
  );
};
