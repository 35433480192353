/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import { Box, Grid, Typography } from '@mui/material';
import { ArrowForwardIos } from '@mui/icons-material';
import {
  addMinutes,
  differenceInMinutes,
  setHours,
  setMinutes,
} from 'date-fns';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MAIN_BORDER } from './consts';
import { DraggableDaySubjectBox } from './DraggableDaySubjectBox';
import { DropAreaHourBox } from './DropAreaHourBox';
import { DaySubject } from './interfaces';
import { SubjectDialog } from './SubjectDialog';
import { dateToTime, timeToDate } from './utils';
import { VisualHourItem } from './VisualHourItem';

const droppableAreas: { hour: number; minutes: number }[] = [
  { hour: 8, minutes: 0 },
  { hour: 8, minutes: 15 },
  { hour: 8, minutes: 30 },
  { hour: 8, minutes: 45 },
  { hour: 9, minutes: 0 },
  { hour: 9, minutes: 15 },
  { hour: 9, minutes: 30 },
  { hour: 9, minutes: 45 },
  { hour: 10, minutes: 0 },
  { hour: 10, minutes: 15 },
  { hour: 10, minutes: 30 },
  { hour: 10, minutes: 45 },
  { hour: 11, minutes: 0 },
  { hour: 11, minutes: 15 },
  { hour: 11, minutes: 30 },
  { hour: 11, minutes: 45 },
  { hour: 12, minutes: 0 },
  { hour: 12, minutes: 15 },
  { hour: 12, minutes: 30 },
  { hour: 12, minutes: 45 },
  { hour: 13, minutes: 0 },
  { hour: 13, minutes: 15 },
  { hour: 13, minutes: 30 },
  { hour: 13, minutes: 45 },
  { hour: 14, minutes: 0 },
  { hour: 14, minutes: 15 },
  { hour: 14, minutes: 30 },
  { hour: 14, minutes: 45 },
  { hour: 15, minutes: 0 },
  { hour: 15, minutes: 15 },
  { hour: 15, minutes: 30 },
  { hour: 15, minutes: 45 },
  { hour: 16, minutes: 0 },
];

const minutesBetween8andStart = (subject: DaySubject): number =>
  differenceInMinutes(
    timeToDate(subject.from),
    setMinutes(setHours(new Date(), 8), 0)
  );

const minutesBetweenStartAndEnd = (subject: DaySubject): number =>
  differenceInMinutes(timeToDate(subject.to), timeToDate(subject.from));

export const Day = ({
  subjects,
  setSubject,
  removeSubject,
  dayLabel,
  dayLabelForModal,
  ballLabel,
  disabled = false,
  forwardButtonClick,
}: {
  subjects: DaySubject[];
  setSubject: (subject: DaySubject) => void;
  removeSubject: (subject: DaySubject) => void;
  dayLabel: string;
  dayLabelForModal: string;
  disabled?: boolean;
  ballLabel?: string;
  forwardButtonClick?: (e: any) => void;
}) => {
  const [subjectDialogOpen, setSubjectDialogOpen] = useState(false);
  const [editableSubject, setEditableSubject] = useState<
    DaySubject | undefined
  >();
  const closeDialogAndClear = () => {
    setSubjectDialogOpen(false);
    setEditableSubject(undefined);
  };

  const visualTops = [-2, 58, 118, 178, 238, 298, 358, 418];

  return (
    <Grid
      container
      style={{
        borderLeft: MAIN_BORDER,
      }}
    >
      <Grid
        item
        xs={12}
        style={{
          borderBottom: MAIN_BORDER,
          height: '80px',
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            maxHeight: '35px',
            width: '100%',
          }}
        >
          <div>
            <Typography
              variant="body2"
              style={{ marginTop: forwardButtonClick ? '25px' : undefined }}
            >
              {disabled ? <span>{dayLabel}</span> : <b>{dayLabel}</b>}
            </Typography>
          </div>
          {forwardButtonClick && (
            <div style={{ alignSelf: 'flex-end' }}>
              <ArrowForwardIos
                style={{
                  cursor: 'pointer',
                }}
                onClick={(e) => {
                  forwardButtonClick(e);
                }}
                fontSize="small"
              />
            </div>
          )}
        </Grid>
        {ballLabel && (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                borderRadius: '50%',
                backgroundColor: '#A6AEE1',
                width: '34px',
                height: '34px',
                color: '#FFFFFF',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div>
                <Typography style={{ fontSize: '14px' }} variant="caption">
                  {ballLabel}
                </Typography>
              </div>
            </div>
          </Grid>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{ m: 0.5, mb: 15 }}
          style={{
            width: '100%',
            position: 'relative',
          }}
        >
          {editableSubject && (
            <SubjectDialog
              label={dayLabelForModal}
              open={subjectDialogOpen}
              subject={editableSubject}
              onClose={() => closeDialogAndClear()}
              removeSubject={() => {
                removeSubject(editableSubject);
                closeDialogAndClear();
              }}
              setSubject={(subject: DaySubject) => {
                if (!subject.subjectTimingId) {
                  removeSubject(editableSubject);
                }
                setSubject(subject);
                closeDialogAndClear();
              }}
            />
          )}
          {visualTops.map((top, index) => (
            <VisualHourItem top={`${top}px`} key={`${top + index}asd`} />
          ))}
          {droppableAreas.map((obj, index) => (
            <DropAreaHourBox
              disabled={disabled}
              key={`${index} + ${uuidv4()}`}
              setSubject={(
                subject: string,
                duration?: number,
                subjectTimingId?: string
              ) => {
                const startTime = timeToDate({
                  hour: obj.hour,
                  minutes: obj.minutes,
                });
                const endTime = addMinutes(startTime, duration ?? 60);
                setSubject({
                  subject,
                  from: dateToTime(startTime),
                  to: dateToTime(endTime),
                  subjectTimingId,
                });
              }}
            />
          ))}
          {subjects?.map((subject, index) => (
            <DraggableDaySubjectBox
              key={`${index} + ${uuidv4()}`}
              top={minutesBetween8andStart(subject) - 2}
              height={minutesBetweenStartAndEnd(subject)}
              click={() => {
                setEditableSubject(subject);
                setSubjectDialogOpen(true);
              }}
              subject={subject}
              removeSubject={() => removeSubject(subject)}
              setSubject={(ss: DaySubject) => setSubject(ss)}
            />
          ))}
        </Box>
      </Grid>
    </Grid>
  );
};
