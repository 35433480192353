import React, { useState } from 'react';
import { setHours, setMinutes } from 'date-fns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { TextField } from '@mui/material';
import { safeParse } from '../../../../routes/my-plan/utils';

type Props = {
  fieldName: string;
  fieldTitle: string;
  fieldValue: string | Date;
  fieldRequired: boolean;
  setExternalDate?: Function;
  style?: object;
  sideEffect?: Function;
  setTime: (val: string | Date) => void;
};

export const GenericTimePicker = ({
  fieldName,
  fieldTitle,
  fieldValue,
  fieldRequired = false,
  sideEffect,
  style,
  setTime,
  ...rest
}: Props) => {
  const [stateDate, setStateDate] = useState<Date | null>(
    fieldValue ? safeParse(fieldValue) : null
  );

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <TimePicker
        ampm={false}
        inputFormat="HH:mm"
        onChange={(time: unknown) => {
          if (time instanceof Date) {
            if (
              !Number.isNaN(time.getHours()) &&
              !Number.isNaN(time.getMinutes())
            ) {
              const newDate = setMinutes(
                setHours(safeParse(fieldValue), time.getHours()),
                time.getMinutes()
              );
              setTime(newDate);
              setStateDate(newDate);
            }
          }
        }}
        value={stateDate}
        renderInput={(params) => (
          <TextField
            style={{ width: '100%', marginBottom: '10px' }}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
};
