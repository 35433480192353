import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { XXXL_SPACING, LARGE_SPACING } from '../../theme';
// @ts-ignore
import logo from './logo.png';

const CardLink = ({
  title,
  disabled,
  to,
}: {
  title: React.ReactNode;
  disabled?: boolean;
  to: string;
}) => (
  <Grid
    item
    xs={12}
    sm={6}
    md={6}
    lg={6}
    style={disabled ? { opacity: '0.4' } : {}}
  >
    <NavLink to={disabled ? 'curriculum' : to}>
      <Card sx={{ p: 6 }} style={{ display: 'flex' }}>
        <CardActionArea>
          <CardContent style={{ textAlign: 'center' }}>
            <img
              alt="Polar Partners logo"
              style={{ width: '400px', maxWidth: '100%', height: 'auto' }}
              src={logo}
            />
            <Typography variant="h6">{title}</Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </NavLink>
  </Grid>
);

export const CurriculumPage = () => {
  const intl = useIntl();

  return (
    <Grid
      container
      spacing={3}
      justifyContent="center"
      sx={{ px: XXXL_SPACING, mt: LARGE_SPACING }}
    >
      <CardLink
        title={intl.formatMessage({ id: 'curriculumpage.subjects' })}
        to="/curriculum/subjects"
      />
      <CardLink
        disabled={true}
        title={intl.formatMessage({ id: 'curriculumpage.pedagogy' })}
        to="/curriculum/pedagogy"
      />
      <CardLink
        disabled={true}
        title={intl.formatMessage({ id: 'curriculumpage.assessment' })}
        to="/curriculum/assessment"
      />
      <CardLink
        disabled={true}
        title={intl.formatMessage({ id: 'curriculumpage.support' })}
        to="/curriculum/support"
      />
    </Grid>
  );
};
