import React from 'react';
import { Stats } from '../../utils/stathelpers';
import { ProgressBar } from './ProgressBar';

interface Props {
  stats: Stats;
  groupName?: string;
}

export const ProgressBarWrapper = ({ stats, groupName }: Props) => (
  <>
    <ProgressBar
      fill={(stats.completed / stats.all) * 100}
      secondaryFill={((stats.completed + stats.planned) / stats.all) * 100}
      text={`${stats.completed} / ${stats.planned} / ${stats.all}`}
      groupName={groupName}
    />
  </>
);
