import React, { useState, useEffect } from 'react';
import { Drawer as MuiDrawer, IconButton } from '@mui/material';
import { useLocation } from 'react-router-dom';
// @ts-ignore
import BackgroundImage from './leftnavbg2.png';
import { LEFT_NAV_BACKGROUND_COLOR } from '../theme';
import { MainNav } from './MainNav';
import { LEFT_NAV as Z_INDEX } from '../utils/z-index';
import { FeatherIcon, IconSize } from '../components/FeatherIcon';

export const HamburgerNavigation = () => {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Close the hamburger menu when the user navigates to a new page
    setHamburgerOpen(false);
  }, [location]);

  return (
    <>
      <IconButton onClick={() => setHamburgerOpen((oldVal) => !oldVal)}>
        <FeatherIcon name="menu" color="white" size={IconSize.MEDIUM} />
      </IconButton>
      <MuiDrawer
        variant="temporary"
        anchor="left"
        open={hamburgerOpen}
        onClose={() => setHamburgerOpen(false)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            backgroundColor: LEFT_NAV_BACKGROUND_COLOR,
            backgroundImage: `url(${BackgroundImage})`,
            backgroundSize: '100% 50%',
            backgroundRepeat: 'no-repeat',
            backgroundPositionY: 'bottom',
          },
        }}
        style={{
          zIndex: Z_INDEX,
        }}
      >
        <MainNav />
      </MuiDrawer>
    </>
  );
};
