import React from 'react';

import { Typography } from '@mui/material';
import { DETAIL_COLOR, LARGE_SPACING, MEDIUM_SPACING } from '../theme';

export const ObjectiveOrContentWithBorder = ({
  objectiveOrContent,
  prSpacing = LARGE_SPACING,
}: {
  objectiveOrContent: string;
  prSpacing?: number;
}) => (
  <Typography
    variant="h6"
    style={{
      color: DETAIL_COLOR,
      boxShadow: `-1px 0px 0px ${DETAIL_COLOR} inset`,
    }}
    sx={{
      mr: LARGE_SPACING,
      pl: prSpacing,
      pr: LARGE_SPACING,
      py: MEDIUM_SPACING,
    }}
  >
    {objectiveOrContent}
  </Typography>
);
