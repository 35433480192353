import { Card, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import { SubjectItem } from '../ops/components/types/types';
import { SubjectProgress } from './SubjectProgress';
import { GroupsWithFavoritesAndStats } from './types';

type Props = {
  subjects: SubjectItem[];
  role?: string;
  bpStats?: ContentProgressBulletpointStats[];
  groups?: GroupsWithFavoritesAndStats[];
};

const filterBpStatsForSubjectAndGrade = (
  bpStats: ContentProgressBulletpointStats[],
  subject: SubjectItem,
  grade: number
): ContentProgressBulletpointStats[] => {
  const gradeItem = subject?.grades?.find((g) => g.grade === grade);

  const subjectsAllBulletPoints =
    gradeItem?.objectives
      ?.flatMap((o) => [...(o?.contents ?? []), ...(o?.transversals ?? [])])
      ?.flatMap((c) => (c !== undefined ? Object.keys(c?.bulletpoints) : [])) ??
    [];
  return bpStats.filter((bpStat) =>
    subjectsAllBulletPoints?.includes(bpStat.bulletpointId)
  );
};

export const SubjectProgressContainer = ({
  subjects,
  role,
  bpStats,
  groups,
}: Props) => (
  <Card variant="outlined" style={{ padding: '25px', border: '0.5px solid' }}>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">Subjects</Typography>
        <Divider />
      </Grid>
      {subjects.map((subject) =>
        subject.grades?.map((grade) => (
          <SubjectProgress
            key={subject.code + grade.grade}
            grade={grade.grade.toString()}
            subject={subject}
            role={role}
            bpStats={
              bpStats
                ? filterBpStatsForSubjectAndGrade(bpStats, subject, grade.grade)
                : undefined
            }
            bpStatsByGroup={groups
              ?.filter((g) =>
                g.favorites.includes(`${subject.code}-${grade.grade}`)
              )
              .map((gr) => ({
                groupId: gr.groupId!!,
                groupName: gr.name,
                stats: filterBpStatsForSubjectAndGrade(
                  gr.stats,
                  subject,
                  grade.grade
                ),
              }))}
          />
        ))
      )}
    </Grid>
  </Card>
);
