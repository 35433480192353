import { Typography } from '@mui/material';
import React from 'react';
import { SimpleDatePicker } from '../../../components/SimpleDatePicker';

export const FromToSelector = ({
  from,
  to,
  setFrom,
  setTo,
}: {
  from: Date;
  to: Date;
  setFrom: (from: Date) => void;
  setTo: (to: Date) => void;
}) => (
  <div style={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
    <SimpleDatePicker date={from} setDate={(d: Date) => setFrom(d)} />
    <Typography variant="body1" style={{ margin: '4px' }}>
      to
    </Typography>
    <SimpleDatePicker date={to} setDate={(d: Date) => setTo(d)} />
  </div>
);
