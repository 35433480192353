import { Box, Grid } from '@mui/material';
import * as React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { updateGroupSelection } from '../../api/groupapi';
import { selectedSubject } from '../../App/state';
import { getSubjectStatsByContentProgressBulletpointStats } from '../../utils/stathelpers';
import { parseNumber } from '../../utils/string';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import { GradeItem, SubjectItem } from '../ops/components/types/types';
import { ProgressHeaderBar } from './ProgressHeaderBar';
import { ProgressBarWrapper } from './ProgressBarWrapper';

type Props = {
  subject: SubjectItem;
  grade: string;
  role?: string;
  bpStats?: ContentProgressBulletpointStats[];
  bpStatsByGroup?: {
    groupId: string;
    groupName: string;
    stats: ContentProgressBulletpointStats[];
  }[];
};

const NUMBER_OF_TEACHING_WEEKS_IN_YEAR = 38;

const countSubjectBulletpoints = (
  subject: SubjectItem,
  grade: string
): number | undefined => {
  const foundGrade: GradeItem | undefined = subject.grades?.find(
    (g) => g.grade === parseInt(grade, 10)
  );
  if (!foundGrade) {
    return undefined;
  }
  return foundGrade.objectives
    ?.flatMap((o) => [...(o.contents ?? []), ...(o.transversals ?? [])] ?? [])
    .flatMap((c) => Object.values(c.bulletpoints)).length;
};

const countExpectedPace = (
  subject: SubjectItem,
  grade: string
): string | undefined => {
  const bulletpoints = countSubjectBulletpoints(subject, grade);
  return bulletpoints
    ? (bulletpoints / NUMBER_OF_TEACHING_WEEKS_IN_YEAR).toFixed(2)
    : undefined;
};

export const SubjectProgress = ({
  subject,
  grade,
  role,
  bpStats,
  bpStatsByGroup,
}: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [_, setSelectedSubj] = useRecoilState(selectedSubject);
  const { mutate: updateSelectedGroup } = useMutation(updateGroupSelection, {
    onSuccess: () => {
      setSelectedSubj({
        subjectId: subject.code,
        gradeId: parseNumber(grade)!!,
      });
      navigate(`/progress/subject/${subject.code}/${grade}`);
      queryClient.invalidateQueries();
    },
  });
  const gradeItem = subject.grades?.find(
    (_grade) => _grade.grade.toString() === grade
  );

  if (!gradeItem) {
    return <div />;
  }

  const expectedPace = countExpectedPace(subject, grade);

  const clickBox = (groupId?: string) => {
    if (role !== 'STUDENT' && groupId) {
      updateSelectedGroup(groupId);
    }
  };
  return (
    <Grid item xs={12} sm={6} lg={4}>
      <ProgressHeaderBar
        key={`${subject.code} ${grade} -headerbar`}
        text={`${subject.code} ${grade}`}
        tooltipText={`Expected amount of tasks per week ${expectedPace}`}
      />
      {bpStatsByGroup &&
        bpStatsByGroup.map((bp) => (
          <Box
            key={`${bp.groupName + bp.stats.length}statt`}
            style={role !== 'STUDENT' ? { cursor: 'pointer' } : {}}
            onClick={() => clickBox(bp.groupId)}
          >
            <ProgressBarWrapper
              stats={getSubjectStatsByContentProgressBulletpointStats(
                gradeItem.objectives!!,
                bp.stats
              )}
              groupName={bp.groupName}
            />
          </Box>
        ))}
      {bpStats && (
        <ProgressBarWrapper
          stats={getSubjectStatsByContentProgressBulletpointStats(
            gradeItem.objectives!!,
            bpStats
          )}
        />
      )}
    </Grid>
  );
};
