import { useEffect } from 'react';
import { useSnackbar, VariantType } from 'notistack';
import { useIntl } from 'react-intl';
import { useQueryClient } from '@tanstack/react-query';
import useWebSocket from 'react-use-websocket';
import { getToken } from '../utils/auth/session';

export const Websocket = () => {
  const intl = useIntl();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const socketUrl = `${process.env.REACT_APP_WS_URL}?auth=${getToken()}`;
  const { lastMessage } = useWebSocket(socketUrl, {
    onOpen: () => {},
    shouldReconnect: (closeEvent) => true,
  });
  useEffect(() => {
    if (lastMessage !== null) {
      const parsedFirstTime = JSON.parse(lastMessage.data);
      const data = JSON.parse(parsedFirstTime);
      data.queryKeys.forEach((key: string) => {
        queryClient.invalidateQueries([key]);
      });
      data.translations.forEach(
        (translation: { key: string; variant: string }) => {
          enqueueSnackbar(intl.formatMessage({ id: translation.key }), {
            variant: translation.variant as VariantType,
          });
        }
      );
    }
  }, [lastMessage, enqueueSnackbar, intl, queryClient]);
  return null;
};
