import { Stack } from '@mui/material';
import React from 'react';
import { SubjectItem } from '../../ops/components/types/types';
import {
  getAmountOfLessonsForSubjects,
  SelectedGroupSubject,
} from './yearPlanUtils';
import { YearTimeLine } from './YearTimeLine';

export const YearTimeLines = ({
  subjects,
  selectedGroupSubjects,
}: {
  subjects: SubjectItem[];
  selectedGroupSubjects: SelectedGroupSubject[];
}) => {
  // replace with api call at some point
  const amountOfLessons = getAmountOfLessonsForSubjects();
  return (
    <Stack
      direction="column"
      flexGrow={1}
      style={{
        minWidth: 1,
        minHeight: 1,
        maxHeight: '100%',
        overflowY: 'auto',
        margin: '20px 0 20px 20px',
      }}
    >
      {selectedGroupSubjects
        .filter((sgs) => sgs.isFavorite)
        .map((selectedGroupSubject) => (
          <YearTimeLine
            key={`yearTimeLine${selectedGroupSubject.group.groupId}${selectedGroupSubject.subject}${selectedGroupSubject.year}${selectedGroupSubject.grade}`}
            amountOfLessons={
              amountOfLessons?.find(
                (lesson) =>
                  lesson.subjectCode === selectedGroupSubject.subject &&
                  lesson.grade === selectedGroupSubject.grade
              )?.amountOfLessons ?? 99
            }
            subject={
              subjects.find((s) => s.code === selectedGroupSubject.subject)!!
            }
            grade={selectedGroupSubject.grade.toString()}
            groupName={selectedGroupSubject.group.name}
            groupId={selectedGroupSubject.group.groupId!!}
            year={selectedGroupSubject.year}
          />
        ))}
    </Stack>
  );
};
