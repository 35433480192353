import { OPS_API_URL, OPS_BUCKET_URL } from './constants';

export const get = async <T>(id?: string): Promise<T> => {
  const response = await fetch(OPS_API_URL(id || 'generic'));
  return response.json();
};

export const getOpsBucket = async <T>(id?: string): Promise<T> => {
  const response = await fetch(OPS_BUCKET_URL);
  const text = await response.text();
  const parser = new DOMParser();
  const xmlDOM = parser.parseFromString(text, 'text/xml');
  const values: any = await xmlDOM.getElementsByTagName('Key');
  let contents: any = [];
  for (let i = 0; i < values.length; i++) {
    contents = [values.item(i).textContent, ...contents];
  }
  return contents;
};
