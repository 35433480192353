import React from 'react';
import { Box, Stack } from '@mui/material';

export const LabeledValueBig = (props: { label: string; value: string }) => (
  <Stack>
    <Box fontSize={18} lineHeight={1}>
      {props.label}
    </Box>
    <Box fontSize={33} lineHeight={1}>
      {props.value}
    </Box>
  </Stack>
);
