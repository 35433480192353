import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { TextField } from '@mui/material';

export const SimpleDatePicker = ({
  date,
  setDate,
}: {
  date: Date;
  setDate: (from: Date) => void;
}) => (
  <div style={{ margin: '8px' }}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        inputFormat="dd.MM.yyyy"
        onChange={(newDate: any) => {
          setDate(newDate as Date);
        }}
        value={date}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  </div>
);
