import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FeatherIcon, IconSize } from '../../../components/FeatherIcon';
import { LinkItem, SubjectItem } from '../../ops/components/types/types';
import { activityTypeToIcon } from '../../../components/ActivityChip';
import { ContentProgress } from '../types';
import { findBulletpointDescFromSubjects } from '../../../utils/ops/findBulletpointDescFromSubjects';
import { getSubjectsByGrade } from '../../../utils/ops/getSubjectsByGrade';
import { parseSubjectList } from '../../../utils/ops/utils';
import { BOLD_FONT_WEIGHT, LARGE_SPACING } from '../../../theme';
import { TimeLineItemTitle } from './TimeLineItemTitle';
import { ActionMenu, ActionMenuItem } from './ActionMenu';
import { useOps } from '../../../hooks/useOps';
import { TimeLineItemDescription } from './TimeLineItemDescription';
// @ts-ignore
import Image from './bgimage.svg';
import { ObjectivePopoverIcon } from './ObjectivePopover';
import { getObjectiveAndContent } from '../../../utils/taskGradeUtils';
import { parseNumber } from '../../../utils/string';

const BulletPoints = (props: {
  allTasks?: ContentProgress[];
  subjects: SubjectItem[];
  linkId: string;
}) => (
  <Stack
    direction="column"
    style={{
      backgroundImage: `url(${Image})`,
      background:
        "transparent url('img/Intersection 13.png') 0% 0% no-repeat padding-box;",
      backgroundColor: 'rgba(36, 95, 115, 0.33)',
      borderRadius: '10px 10px 0px 0px',
      borderTop: '1px solid rgba(21, 70, 87, 0.33)',
      borderRight: '1px solid rgba(21, 70, 87, 0.33)',
      borderLeft: '1px solid rgba(21, 70, 87, 0.33)',
      margin: 0,
      boxShadow: '0px 0px 6px #00000029',
      marginBottom: '-6px',
    }}
    sx={{ p: LARGE_SPACING }}
    gap="0.5em"
  >
    {props.allTasks
      ?.filter(
        (t) =>
          t.allAvailableLinks.find((l) => l.linkId === props.linkId) !==
          undefined
      )
      .map((t) => {
        const { objective, content } = getObjectiveAndContent(
          // @ts-ignore
          props.subjects
            .find((s) => s.code === t.subjectId)!!
            .grades.find((g) => g.grade === parseNumber(t.gradeId))!!,
          t
        );

        return (
          <Stack key={t.contentProgressId} direction="row">
            <Box
              style={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #A8ADB4',
                borderRadius: '20px',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  sx={{ ml: LARGE_SPACING, mr: LARGE_SPACING }}
                  variant="caption"
                >
                  {findBulletpointDescFromSubjects(t, props.subjects)}
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: '-6px',
                }}
                sx={{ mr: LARGE_SPACING }}
              >
                <ObjectivePopoverIcon
                  iconColor="#8B8F96"
                  objective={objective}
                  content={content}
                />
              </Box>
            </Box>
          </Stack>
        );
      })}
  </Stack>
);

export const TimelineActivityItemDndPlaceholder = () => (
  <div
    style={{
      width: '100%',
      maxWidth: '800px',
      height: '170px',
      border: '1px dashed #b0b0b0',
      marginTop: '1em',
    }}
  />
);

export const TimeLineActivityItem = ({
  linkId,
  activityType,
  description,
  title,
  bookTitle,
  page,
  link,
  allTasks,
  isHighlighted,
  highlightColor,
  actionMenuItems,
  imageUrl,
  isHomework,
}: LinkItem & {
  allTasks?: ContentProgress[];
  isHighlighted?: boolean;
  highlightColor?: string;
  actionMenuItems?: ActionMenuItem[];
  isHomework?: boolean;
}) => {
  const [imageError, setImageError] = useState(false);
  const icon = activityTypeToIcon(activityType);
  const { ops } = useOps();

  const subjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: false })
  );

  const [actionMenuOpen, setActionMenuOpen] = useState(false);

  return (
    <Box>
      {allTasks?.length && (
        <BulletPoints allTasks={allTasks} subjects={subjects} linkId={linkId} />
      )}
      <Stack
        style={{
          backgroundColor: isHomework ? '#FCF9F2' : '#F7F8F9',
          borderRadius: '8px',
          boxShadow: '0px 0px 6px #00000029',
          border: '1px solid #AFBBC4',
          borderLeft: isHighlighted
            ? `${highlightColor} 8px solid`
            : '1px solid #AFBBC4',
        }}
      >
        <Stack
          direction="row"
          gap="1em"
          alignItems={isHighlighted ? undefined : 'start'}
        >
          <Stack direction="column" flexGrow={1}>
            <Stack
              direction="row"
              justifyContent="space-between"
              style={{
                borderBottom: '1px solid #AFBBC4',
                paddingLeft: '18px',
                paddingRight: '18px',
                paddingBottom: '15px',
                paddingTop: '15px',
              }}
            >
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <FeatherIcon
                  style={{
                    marginRight: '10px',
                    marginLeft: '-4px',
                  }}
                  name={icon}
                  size={
                    isHighlighted ? IconSize['EXTRA-LARGE'] : IconSize.SMALL
                  }
                  color={isHomework ? '#C69232' : '#172B4D'}
                />
                <TimeLineItemTitle
                  isHighlighted={isHighlighted}
                  isHomeWork={isHomework}
                >
                  {activityType === 'BOOK' ? bookTitle : title}
                </TimeLineItemTitle>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                {activityType === 'BOOK' && page && (
                  <Box
                    style={{
                      border: '1px solid #0000001F',
                      borderRadius: '5px',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant={isHighlighted ? 'body1' : 'caption'}
                      sx={{ px: '5px' }}
                      style={{
                        color: '#00000089',
                        borderRight: '1px solid #00000089',
                      }}
                    >
                      Page
                    </Typography>
                    <Box
                      style={{
                        backgroundColor: 'white',
                        borderRadius: '0 5px 5px 0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        paddingLeft: '6px',
                        paddingRight: '6px',
                      }}
                    >
                      <Typography
                        variant={isHighlighted ? 'body1' : 'caption'}
                        style={{ fontWeight: BOLD_FONT_WEIGHT }}
                      >
                        {page}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {activityType === 'LINK' && link && (
                  <Button
                    variant="text"
                    size="large"
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      border: '1px solid #0000001F',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '20px',
                      padding: '5px',
                      minHeight: 0,
                      minWidth: 0,
                      backgroundColor: 'white',
                    }}
                  >
                    <img
                      style={{
                        height: isHighlighted ? '36px' : '25px',
                        width: isHighlighted ? '36px' : '25px',
                      }}
                      alt={link}
                      src={`https://www.google.com/s2/favicons?domain=${link.substring(
                        link.lastIndexOf('.', link.lastIndexOf('.') - 1) + 1
                      )}&sz=128`}
                    />
                  </Button>
                )}
                {actionMenuItems && (
                  <Box style={{ marginRight: '-18px', zIndex: 50000 }}>
                    <ActionMenu
                      open={actionMenuOpen}
                      setOpen={(newValue: boolean) =>
                        setActionMenuOpen(newValue)
                      }
                      items={actionMenuItems}
                    />
                  </Box>
                )}
              </Box>
            </Stack>
            <Box
              style={{
                paddingLeft: '18px',
              }}
            >
              <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box
                  style={{
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    paddingRight: '18px',
                  }}
                >
                  <TimeLineItemDescription
                    description={description}
                    isHighlighted={isHighlighted}
                  />
                </Box>
                {imageUrl && !imageError && (
                  <Box
                    style={{
                      display: 'flex',
                      overflow: 'hidden',
                      position: 'relative',
                      width: '134px',
                    }}
                  >
                    <img
                      style={{
                        marginBottom: 'auto',
                        marginTop: 'auto',
                        position: 'relative',
                        width: '134px',
                        height: '134px',
                        borderRadius: '0px 0px 8px 0px',
                        zIndex: 2,
                        boxShadow: 'inset 3px 0px 6px #00000029',
                      }}
                      src={imageUrl}
                      alt="activity"
                      onError={() => setImageError(true)}
                    />
                    <img
                      style={{
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        borderRadius: '0px 8px 8px 0px',
                        zIndex: 0,
                        opacity: 0.37,
                        top: 0,
                        right: 0,
                        filter: 'blur(4px)',
                        boxShadow: 'inset 3px 0px 6px #00000029',
                      }}
                      src={imageUrl}
                      alt="activity"
                      onError={() => setImageError(true)}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
