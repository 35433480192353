import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as adminApi from '../../api/adminapi';
import { StyledDialog } from '../../components/StyledDialog';
import { DialogTransition } from '../../components/DialogTransition';
import { BOLD_FONT_WEIGHT, LARGE_SPACING } from '../../theme';

export const TeacherDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const [email, setEmail] = useState<string>();
  const [ops, setOps] = useState<string>();
  const queryClient = useQueryClient();
  const { mutate: saveTeacher } = useMutation(adminApi.postTeacher, {
    onSuccess: () => {
      queryClient.invalidateQueries(['teachers']);
    },
  });

  return (
    <StyledDialog
      TransitionComponent={DialogTransition}
      open={open}
      // @ts-ignore
      onClose={onClose}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ minWidth: '800px', maxWidth: '1600px' }}
    >
      <DialogTitle color="primary">Add email and ops for teacher</DialogTitle>
      <DialogContent style={{ minWidth: '300px' }}>
        <Grid container spacing={2} sx={{ mt: LARGE_SPACING }}>
          <Typography
            variant="caption"
            style={{ fontWeight: BOLD_FONT_WEIGHT }}
          >
            Email value should match the email the user is going to log in with
          </Typography>
          <Grid item xs={12}>
            <FormControl style={{ minWidth: '300px' }}>
              <TextField
                label="Email"
                variant="outlined"
                onChange={(evt) => setEmail(evt.target.value)}
                fullWidth
                required
              />
            </FormControl>
          </Grid>
          <Typography
            variant="caption"
            style={{ fontWeight: BOLD_FONT_WEIGHT }}
          >
            Make sure that ops value matches s3 ops value. If you have for
            example espoo1.json in S3 bucket, then the ops value here should be
            espoo1
          </Typography>
          <Grid item xs={12}>
            <FormControl style={{ minWidth: '300px' }}>
              <TextField
                label="Ops"
                variant="outlined"
                onChange={(evt) => setOps(evt.target.value)}
                fullWidth
                required
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close window
        </Button>
        <Button
          disabled={!ops || !email}
          onClick={async () => {
            await saveTeacher({ email: email!!, ops: ops!! });
            onClose();
          }}
          color="primary"
        >
          Add teacher
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};
