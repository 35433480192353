import { isBefore } from 'date-fns';
import * as React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { AuthContext } from './AuthContext';
import { removeToken } from './session';
import { RoleType } from '../../generics/types/roleType';

/**
 * Do all login related actions / data access through this hook.
 *
 * This hook works alongside AuthContext which holds the relevat login info.
 * Add global login state to AuthContext, do not store it in this hook
 */

export const useAuth = () => {
  const queryClient = useQueryClient();
  const { token } = React.useContext(AuthContext);

  const logout = () => {
    removeToken();
    queryClient.invalidateQueries();
    window.location.href = `${process.env.REACT_APP_BASE_URL}/login`;
  };

  const roles = token?.roles || [];
  return {
    isLoggedIn:
      token && token.exp
        ? isBefore(new Date(), new Date(parseInt(`${token?.exp}000`, 10)))
        : false,
    user: token,
    role: token?.role,
    roles,
    logout,
    // @ts-ignore
    hasRole: (role: RoleType) => roles.includes(role),
    admin: token?.superAdmin === 'true',
    name: token?.name,
    email: token?.email,
    cognitoId: token?.sub,
    superAdmin: token?.superAdmin === 'true',
    firstName: token?.firstName,
    lastName: token?.lastName,
  };
};
