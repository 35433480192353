/* eslint-disable @typescript-eslint/indent */
import React, { useRef, useState } from 'react';
import { Box, Button, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import {
  contentContentDialogOpen,
  contentRedirectAfterSaveUrl,
  contentState,
} from '../../../App/state';
import { LessonTimeline } from './LessonTimeline';
import { ActivityOrder, ActivityOrderType } from './ActivityOrder';
import { LessonItem, isLinkItem } from '../../ops/components/types/types';
import { DONE_BUTTON_COLOR, XXL_SPACING } from '../../../theme';
import { LESSON_PLAN_BUTTONS as BUTTON_Z_INDEX } from '../../../utils/z-index';
import { ContentMissing } from '../../../components/ContentMissing';
import { useTopicMutation } from '../../../hooks/useTopicMutation';
import {
  moveItemInActivityOrder,
  removeItemFromActivityOrder,
} from '../../../utils/activityOrderUtil';

export const LessonPlanComponent = ({ onClose }: { onClose: () => void }) => {
  const navigate = useNavigate();
  const [redirectAfterSaveUrl, setRedirectAfterSaveUrl] = useRecoilState(
    contentRedirectAfterSaveUrl
  );
  const { enqueueSnackbar } = useSnackbar();
  const [_, setContentDialogOpen] = useRecoilState(contentContentDialogOpen);
  const [contentViewState, setContentViewState] = useRecoilState(contentState);
  const topic = contentViewState?.topic;
  const [topicName, setTopicName] = useState(topic?.name ?? '');
  const [topicDescription, setTopicDescription] = useState(
    topic?.description ?? ''
  );

  const close = () => {
    onClose();
    setContentDialogOpen(false);
  };

  const { updateMutation } = useTopicMutation({
    groupId: topic?.groupId,
    onSuccess: () => {
      enqueueSnackbar('Saved successfully', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Something went wrong when trying to save', {
        variant: 'error',
      });
    },
  });

  React.useEffect(() => {
    setTopicName(topic?.name ?? '');
    setTopicDescription(topic?.description ?? '');
  }, [topic]);
  const activityOrderRef = useRef<ActivityOrder | undefined>(
    topic?.activityOrder
  );

  if (!topic || topic.tasks?.length < 1) {
    return (
      <ContentMissing
        title="No content"
        description="Please add content to lesson before creating a lesson plan."
      />
    );
  }

  return (
    <Stack spacing={1} style={{ minHeight: '100vh' }}>
      <LessonTimeline
        topic={topic}
        setActivityOrNoteToOrder={async (
          orderSection: ActivityOrderType,
          orderNumber: number,
          lessonItem: LessonItem
        ) => {
          if (!activityOrderRef.current) {
            return;
          }
          const activityOrderToBeSaved = moveItemInActivityOrder(
            activityOrderRef.current,
            orderSection,
            orderNumber,
            lessonItem
          );
          const activity = isLinkItem(lessonItem) ? lessonItem : undefined;
          activityOrderRef.current = activityOrderToBeSaved;
          setContentViewState(
            (curVal) =>
              ({
                ...curVal,
                topic: {
                  ...curVal?.topic,
                  activityOrder: activityOrderToBeSaved,
                  tasks: curVal?.topic.tasks.map((t) => ({
                    ...t,
                    allAvailableLinks: activity?.bulletpointIds.includes(
                      t.bulletpointId
                    )
                      ? [
                          ...t.allAvailableLinks.filter(
                            (taal) => taal.linkId !== activity?.linkId
                          ),
                          activity,
                        ]
                      : t.allAvailableLinks,
                  })),
                },
              } as any)
          );
          await updateMutation.mutateAsync({
            ...topic,
            name: topicName,
            description: topicDescription,
            tasks: topic.tasks.map((t) => ({
              ...t,
              timetableId: 'not persisted currently',
            })),
            activityOrder: activityOrderToBeSaved,
          });
        }}
        deleteItemFromOrder={async (
          orderSection: ActivityOrderType,
          orderNumber
        ) => {
          if (!activityOrderRef.current) {
            return;
          }
          const newActivityOrder = removeItemFromActivityOrder(
            activityOrderRef.current,
            orderSection,
            orderNumber
          );
          activityOrderRef.current = newActivityOrder;
          setContentViewState(
            (curVal) =>
              ({
                ...curVal,
                topic: {
                  ...curVal?.topic,
                  activityOrder: newActivityOrder,
                },
              } as any)
          );
          await updateMutation.mutateAsync({
            ...{
              subjectId: topic.subjectId,
              gradeId: topic.gradeId,
              name: topicName,
              description: topicDescription,
              lessonNumber: topic.lessonNumber,
              year: topic.year,
              groupId: topic.groupId,
              tasks: topic.tasks.map((t) => ({
                contentId: t.contentId,
                bulletpointId: t.bulletpointId,
                subjectId: t.subjectId,
                gradeId: t.gradeId,
                objectiveId: t.objectiveId,
                timetableId: 'not persisted currently',
              })),
            },
            topicId: topic.topicId,
            activityOrder: newActivityOrder,
          });
        }}
      />
      <Box
        style={{
          backgroundColor: '#F2F3F5',
          position: 'sticky',
          bottom: 0,
          marginTop: 'auto',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          height: '72px',
          minHeight: '72px',
          boxShadow: '0px -3px 4px #00000017',
          zIndex: BUTTON_Z_INDEX,
        }}
      >
        <Button
          style={{ height: '36px', borderRadius: '30px' }}
          onClick={() => {
            close();
          }}
          sx={{ mr: XXL_SPACING }}
          variant="outlined"
        >
          Close
        </Button>
        {redirectAfterSaveUrl && (
          <Button
            style={{
              height: '36px',
              borderRadius: '30px',
              backgroundColor: DONE_BUTTON_COLOR,
            }}
            sx={{ mr: XXL_SPACING }}
            variant="contained"
            onClick={async () => {
              close();
              navigate(redirectAfterSaveUrl);
              setRedirectAfterSaveUrl(undefined);
            }}
          >
            Done
          </Button>
        )}
      </Box>
    </Stack>
  );
};
