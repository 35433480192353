import { ContentProgress } from '../../routes/my-plan/types';
import {
  ContentItem,
  Objective,
  SubjectItem,
  TransversalItem,
} from '../../routes/ops/components/types/types';
import { parseNumber } from '../string';

export const findBulletpointDescFromSubjects = (
  cp: ContentProgress,
  subjects: SubjectItem[]
): string => {
  if (!subjects) return '';
  // @ts-ignore
  const { contents, transversals } = subjects
    .find((s) => s.code === cp.subjectId)!!
    .grades.find((g) => g.grade === parseNumber(cp.gradeId))!!
    .objectives.find((o) => o.code === cp.objectiveId);
  const combined = [...contents, ...transversals];
  const content = combined.find((c) => cp.contentId === c.code);
  return content.bulletpoints[cp.bulletpointId];
};

const safeParse = (grade: number | string): number =>
  typeof grade === 'string' ? parseNumber(grade)!! : grade;

export const parseBulletpointId = (
  bulletpointId: string
): { objectiveId: string; contentId: string; grade: number } => {
  const splitted = bulletpointId.split('-');
  return {
    objectiveId: splitted[5],
    contentId: splitted[6],
    grade: parseNumber(splitted[4])!!,
  };
};

export const findBulletpointDescFromSubject = (
  bulletpoint: string,
  grade: number | string,
  subject: SubjectItem
): string | undefined => {
  const objectiveAndContent = parseBulletpointId(bulletpoint);
  const objective = subject.grades
    ?.find((g) => g.grade === safeParse(grade))
    ?.objectives?.find((o) => o.code === objectiveAndContent.objectiveId)!!;
  const combined = [
    ...(objective?.contents ?? []),
    ...(objective?.transversals ?? []),
  ];
  const content = combined.find(
    (c) => objectiveAndContent.contentId === c.code
  );
  return content?.bulletpoints[bulletpoint];
};

export const findContentOrTransversalForBulletpointId = (
  bulletpointId: string,
  subject: SubjectItem
): ContentItem | TransversalItem => {
  const parsedBulletpointInfo = parseBulletpointId(bulletpointId);
  // @ts-ignore
  const { contents, transversals } = subject?.grades
    ?.find((g) => g.grade === parsedBulletpointInfo.grade)!!
    .objectives.find(
      (o) => o.code === parsedBulletpointInfo.objectiveId
    ) as Objective;
  const combined = [...(contents ?? []), ...(transversals ?? [])];
  return combined.find((c) => c.code === parsedBulletpointInfo.contentId)!!;
};
