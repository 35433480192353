/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { Box, Grid } from '@mui/material';
import { format, parseISO } from 'date-fns';
import {
  EnhancedSubjectTimings,
  SubjectTiming,
} from '../../../api/timetablesapi';
import { DETAIL_COLOR, XXXL_SPACING } from '../../../theme';
import { useSubjectColor } from '../../../hooks/useSubjectColor';
import { SubjectColorBox } from '../../../components/SubjectColorBox';
import { LabeledValueBig } from '../../../components/HeaderInfo';
import { useBreakpoints } from '../../../hooks/useBreakpoints';

const getFormattedDate = (isoString: string) => {
  const parsed = parseISO(isoString);
  return format(parsed, 'EEEE, MMM dd');
};

const getFormattedTime = (isoString: string) => {
  const parsed = parseISO(isoString);
  return format(parsed, 'HH:mm');
};

export const LessonPreviewHeader = ({
  subject,
  subjectTiming,
  grade,
  groupName,
}: {
  subject: string;
  subjectTiming?: EnhancedSubjectTimings | SubjectTiming;
  grade: string;
  groupName: string;
}) => {
  const { isDesktop } = useBreakpoints();
  const { subjectColor } = useSubjectColor(subject);

  const lessonDate = subjectTiming?.startAt
    ? getFormattedDate(subjectTiming.startAt)
    : '';
  const lessonHours =
    subjectTiming?.startAt && subjectTiming?.endAt
      ? `${getFormattedTime(subjectTiming.startAt)} - ${getFormattedTime(
          subjectTiming.endAt
        )}`
      : '';

  const justifyHeaderContent = isDesktop ? 'center' : 'start';

  return (
    <SubjectColorBox subjectColor={subjectColor}>
      <Grid
        container
        style={{
          minHeight: '144px',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Grid
          item
          xs={12}
          md={4}
          style={{ display: 'flex', justifyContent: justifyHeaderContent }}
        >
          <Box>
            <LabeledValueBig label={lessonDate} value={lessonHours} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{
            ...(isDesktop && {
              borderLeft: `${DETAIL_COLOR} 1px solid`,
              borderRight: `${DETAIL_COLOR} 1px solid`,
            }),
            display: 'flex',
            justifyContent: justifyHeaderContent,
            minHeight: '81px',
            alignItems: 'center',
          }}
        >
          <LabeledValueBig label="Group" value={groupName} />
        </Grid>
        <Grid
          item
          xs={12}
          md
          style={{
            display: 'flex',
            justifyContent: justifyHeaderContent,
          }}
          sx={{ ...(isDesktop && { ml: XXXL_SPACING + XXXL_SPACING }) }}
        >
          <LabeledValueBig label={`${grade}. Grade`} value={subject} />
        </Grid>
      </Grid>
    </SubjectColorBox>
  );
};
