import { Box, CircularProgress, Fab } from '@mui/material';
import React, { useState } from 'react';
import { DELETE_COLOR, THEME_MAIN_COLOR } from '../theme';
import { FeatherIcon, IconSize } from './FeatherIcon';
import { PictureCropDialog } from '../routes/profile/PictureCropDialog';
import { FileUpload } from './FileUpload';

export const ActivityImageLoader = ({
  linkId,
  imageUrl,
  uploadActivityImage,
  deleteImage,
}: {
  linkId?: string;
  imageUrl?: string;
  uploadActivityImage: (
    buffer: any,
    callbackFn: () => void,
    errorCallbackFn: () => void,
    linkId?: string
  ) => void;
  deleteImage: (callbackFn: () => void, linkId?: string) => void;
}) => {
  const [showImageCropDialog, setShowImageCropDialog] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageLoadError, setImageLoadError] = useState(false);
  const [src, setSrc] = useState<string | undefined>();
  const inputFile = React.useRef<HTMLInputElement | null>(null);
  const [random, setRandom] = React.useState(Math.random());
  const [loading, setLoading] = React.useState(false);

  const imageOnErrorHandler = () => {
    setImageLoadError(true);
    setImageLoaded(true);
  };
  return (
    <>
      <PictureCropDialog
        cropWidth={600}
        cropHeight={600}
        lockCrop={false}
        circularCrop={false}
        aspect={1}
        loading={loading}
        open={showImageCropDialog}
        onClose={() => {
          setShowImageCropDialog(false);
          setLoading(false);
        }}
        src={src!!}
        uploadFile={async (buffer: ArrayBuffer) => {
          setLoading(true);
          uploadActivityImage(
            buffer,
            () => {
              setRandom(Math.random());
              setImageLoadError(false);
              setShowImageCropDialog(false);
              setLoading(false);
            },
            () => {
              setShowImageCropDialog(false);
              setLoading(false);
            },
            linkId
          );
        }}
      />
      <input
        type="file"
        id="file"
        accept="image/*"
        style={{ display: 'none' }}
        ref={inputFile}
        onChange={(evt: any) => {
          if (evt.target.files[0]) {
            const fileReader = new FileReader();
            const file = evt.currentTarget.files;
            if (file != null) {
              fileReader.readAsDataURL(file[0]);
              fileReader.onload = (onLoadEvent) => {
                setSrc(onLoadEvent?.target?.result as string);
                setLoading(false);
                setShowImageCropDialog(true);
              };
            }
          }
        }}
      />
      {!imageLoadError && (
        <Box
          style={{
            width: '50%',
            position: 'relative',
          }}
        >
          {!imageLoaded && <CircularProgress />}
          <img
            onLoad={() => setImageLoaded(true)}
            style={{
              width: '100%',
              height: '100%',
              borderRadius: '8px',
              ...(imageLoaded ? {} : { display: 'none' }),
            }}
            src={
              // eslint-disable-next-line no-nested-ternary
              linkId ? `${imageUrl}?n=${random}` : !imageUrl ? 'fake' : imageUrl
            }
            alt="activity"
            onError={imageOnErrorHandler}
          />
          {imageLoaded && (
            <Fab
              sx={{
                backgroundColor: 'white',
                position: 'absolute',
                right: '2px',
                bottom: '2px',
                border: `2px solid ${DELETE_COLOR}`,
              }}
              onClick={() => {
                deleteImage(() => {
                  setImageLoadError(true);
                  setRandom(Math.random());
                  setImageLoaded(false);
                }, linkId);
              }}
            >
              <FeatherIcon
                size={IconSize['SMALL-MEDIUM']}
                name="trash-2"
                color={DELETE_COLOR}
              />
            </Fab>
          )}
        </Box>
      )}
      {imageLoadError && (
        <FileUpload
          setSrc={(source) => {
            setSrc(source);
            setShowImageCropDialog(true);
          }}
          inputFile={inputFile}
        />
      )}
    </>
  );
};
