import React from 'react';
import { Route, useLocation, Navigate, Routes } from 'react-router-dom';
import { CurriculumPage } from '../routes/curriculum/CurriculumPage';
import { CurriculumSubjectsPage } from '../routes/curriculum/subjects/CurriculumSubjectsPage';
import { useAuth } from '../utils/auth/useAuth';
import { AdminPage } from '../routes/admin/AdminPage';
import { ProgressPage } from '../routes/progress/ProgressPage';
import { TransversalProgressPage } from '../routes/progress/TransversalProgressPage';
import { GroupsPage } from '../routes/groups/GroupsPage';
import { StudentProgressPage } from '../routes/progress/StudentProgressPage';
import { StudentTransversalProgressPage } from '../routes/progress/StudentTransversalProgressPage';
import { LoginPage } from '../routes/login/components/LoginPage';
import { HomePage } from '../routes/home/HomePage';
import { SubjectProgressPage } from '../routes/progress/SubjectProgressPage';
import { YearPlanPage } from '../routes/my-plan/year-plan/YearPlanPage';
import { DeleteData } from '../routes/delete-data/DeleteData';

const RedirectToLoginIfLoggedIn = ({ children }: { children: JSX.Element }) => {
  const { isLoggedIn } = useAuth();

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }
  return children;
};

const RequireAuth = ({
  children,
  admin,
}: {
  children: JSX.Element;
  admin: boolean;
}) => {
  const location = useLocation();
  const { isLoggedIn, admin: isAdmin } = useAuth();

  if (!isLoggedIn && !location.hash.includes('callback')) {
    return <Navigate to="/login" />;
  }

  if (admin && !isAdmin) {
    // eslint-disable-next-line no-alert
    alert('Not authorized to view this page');
    return <Navigate to="/" />;
  }
  return children;
};

export const RootRoute = () => {
  const { isLoggedIn } = useAuth();

  return (
    <Routes>
      <Route
        path="/"
        element={
          isLoggedIn ? <Navigate to="/home" /> : <Navigate to="/login" />
        }
      />
      <Route
        path="/admin"
        element={
          <RequireAuth admin={true}>
            <AdminPage />
          </RequireAuth>
        }
      />
      <Route
        path="/home"
        element={
          <RequireAuth admin={false}>
            <HomePage />
          </RequireAuth>
        }
      />
      <Route
        path="/curriculum"
        element={
          <RequireAuth admin={false}>
            <CurriculumPage />
          </RequireAuth>
        }
      />
      <Route
        path="/my-plan/year-plan"
        element={
          <RequireAuth admin={false}>
            <YearPlanPage />
          </RequireAuth>
        }
      />
      <Route
        path="/progress"
        element={
          <RequireAuth admin={false}>
            <ProgressPage />
          </RequireAuth>
        }
      />
      <Route
        path="/student/progress"
        element={
          <RequireAuth admin={false}>
            <StudentProgressPage />
          </RequireAuth>
        }
      />
      <Route
        path="/groups"
        element={
          <RequireAuth admin={false}>
            <GroupsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/progress/subject/:subject/:grade"
        element={
          <RequireAuth admin={false}>
            <SubjectProgressPage />
          </RequireAuth>
        }
      />
      <Route
        path="/progress/transversal/:transversalCode/:transversal"
        element={
          <RequireAuth admin={false}>
            <TransversalProgressPage />
          </RequireAuth>
        }
      />
      <Route
        path="/student/progress/transversal/:transversalCode/:transversal"
        element={
          <RequireAuth admin={false}>
            <StudentTransversalProgressPage />
          </RequireAuth>
        }
      />
      <Route
        path="/curriculum/subjects"
        element={
          <RequireAuth admin={false}>
            <CurriculumSubjectsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/logout"
        element={
          <Navigate
            to={
              process.env.REACT_APP_BASE_URL
                ? `${process.env.REACT_APP_BASE_URL}/login`
                : ''
            }
          />
        }
      />
      <Route
        path="/login"
        element={
          <RedirectToLoginIfLoggedIn>
            <LoginPage />
          </RedirectToLoginIfLoggedIn>
        }
      />
      <Route
        path="/delete-my-data"
        element={
          <RequireAuth admin={false}>
            <DeleteData />
          </RequireAuth>
        }
      />
    </Routes>
  );
};
