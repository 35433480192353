/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/indent */
import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { ContentProgressBulletpointStats } from '../routes/my-plan/types';
import {
  ContentItem,
  TransversalItem,
} from '../routes/ops/components/types/types';
import { BulletpointBall } from './Balls';
import { resolveContentStyle } from './StatStyles';
import { DARK_COMPLETED_COLOR } from '../theme';
import { ContentBallWrapper } from './ContentBallWrapper';
import {
  isBulletpointCompleted,
  isContentCompletedNew,
} from '../utils/stathelpers';
import { FeatherIcon, IconSize } from './FeatherIcon';
import { BulletpointTimesPlanned } from './BulletpointTimesPlanned';

export const ContentOrTransversalView = ({
  item,
  allStats,
}: {
  item: TransversalItem | ContentItem;
  allStats?: ContentProgressBulletpointStats[];
}) => {
  const descriptions = Object.keys(item.bulletpoints || {}).map((key) => ({
    code: key,
    // @ts-ignore
    description: item.bulletpoints[key],
  }));

  const contentCompletionStatus = isContentCompletedNew(
    descriptions.map((desc) => desc.code),
    allStats
  );

  const showHalfFill =
    contentCompletionStatus !== null && !contentCompletionStatus;

  return (
    <>
      <Grid item xs={12}>
        <Box
          style={{ display: 'flex', alignItems: 'center', marginLeft: '-16px' }}
        >
          <ContentBallWrapper
            statStyle={resolveContentStyle(
              descriptions.map((desc) => desc.code),
              allStats
            )}
            contentCode={item.code}
            alignRight={true}
            showHalfFill={showHalfFill}
          />
          <Typography
            variant="body2"
            style={{
              marginLeft: '16px',
              marginTop: '8px',
            }}
          >
            {item.name}
          </Typography>
        </Box>
        <ul style={{ padding: '0 0 0 36px', margin: '0px' }}>
          {descriptions.map((desc) => (
            <Box
              sx={{ p: 1 }}
              key={desc.code}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {isBulletpointCompleted(
                allStats?.find((ast) => ast.bulletpointId === desc.code)
              ) ? (
                <FeatherIcon
                  name="check"
                  size={IconSize.SMALL}
                  style={{ marginRight: '4px' }}
                  color={DARK_COMPLETED_COLOR}
                />
              ) : (
                <BulletpointBall
                  style={{
                    marginLeft: '4px',
                  }}
                />
              )}
              <li
                style={{
                  listStyle: 'none',
                  padding: '4px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                <Box>
                  <Typography variant="caption">{desc.description}</Typography>
                </Box>
                {allStats?.find((ast) => ast.bulletpointId === desc.code)
                  ?.timesPlanned && (
                  <BulletpointTimesPlanned
                    timesPlanned={
                      allStats?.find((ast) => ast.bulletpointId === desc.code)
                        ?.timesPlanned!!
                    }
                  />
                )}
              </li>
            </Box>
          ))}
        </ul>
      </Grid>
    </>
  );
};
