import { Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Group, inviteStudentsToGroup } from '../../../api/groupapi';
import { GroupStudentsHeader } from '../headers/GroupStudentsHeader';
import { AddStudent } from './AddStudent';

enum StudentStatus {
  ACCEPTED,
  INVITED,
}

const StudentRow = ({
  email,
  status,
}: {
  email: string;
  status: StudentStatus;
}) => (
  <tr style={{ color: status === StudentStatus.ACCEPTED ? 'grey' : 'orange' }}>
    <td style={{ padding: '5px 30px 5px 0px' }}>{email}</td>
    <td style={{ padding: '5px 30px 5px 30px' }}>
      {status === StudentStatus.ACCEPTED ? 'Added to group' : 'Invitation sent'}
    </td>
  </tr>
);

export const Students = ({
  group,
  updateStudents,
}: {
  group: Group;
  updateStudents: (students: string[]) => void;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const error = () => {
    enqueueSnackbar('Something went wrong when trying to invite students', {
      variant: 'error',
    });
  };

  const { mutate: inviteStudents } = useMutation(inviteStudentsToGroup, {
    onError: () => {
      error();
    },
    onSuccess: (data, variables) => {
      if (data.message === 'Something went wrong') {
        error();
      } else {
        queryClient.invalidateQueries();
        updateStudents([...(group?.students || []), ...variables?.emails]);
        enqueueSnackbar('Queued student inviting successfully', {
          variant: 'success',
        });
      }
    },
  });

  return (
    <>
      <GroupStudentsHeader
        checked={
          !!group?.existingStudentEmails?.length || !!group?.students?.length
        }
      />
      <Grid container>
        <Grid item sm={12}>
          <AddStudent
            addStudent={(email: string) => {
              inviteStudents({
                groupId: group.groupId,
                emails: [email],
              });
            }}
            existingStudents={[
              ...(group?.students ?? []),
              ...(group?.existingStudentEmails ?? []),
            ]}
          />
        </Grid>
        <Grid item sm={12}>
          <table style={{ margin: '20px' }}>
            <tr style={{ borderBottom: '1px solid' }}>
              <th>Email</th>
              <th>Status</th>
            </tr>
            {group?.existingStudentEmails?.map((existing) => (
              <StudentRow
                key={`existing${existing}`}
                email={existing}
                status={StudentStatus.ACCEPTED}
              />
            ))}
            {group?.students
              ?.filter((st) => !group?.existingStudentEmails?.includes(st))
              .map((student) => (
                <StudentRow
                  key={`existing${student}`}
                  email={student}
                  status={StudentStatus.INVITED}
                />
              ))}
          </table>
        </Grid>
      </Grid>
    </>
  );
};
