import { Button } from '@mui/material';
import React from 'react';

export const ScheduleActionButton = ({
  onClick,
  buttonRef,
  children,
}: {
  onClick: () => void;
  buttonRef?: any;
  children: React.ReactNode;
}) => (
  <Button ref={buttonRef} onClick={onClick} sx={{ p: 3 }}>
    {children}
  </Button>
);
