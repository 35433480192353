import { Box, Grid } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProgressBarWrapper } from './ProgressBarWrapper';
import { getTransversalStats } from '../../utils/stathelpers';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import {
  TransversalItem,
  TransversalItemWithInfo,
} from '../ops/components/types/types';
import { ProgressHeaderBar } from './ProgressHeaderBar';
import { updateGroupSelection } from '../../api/groupapi';

type Props = {
  grade: string;
  transversals: TransversalItem[];
  bulletpointStats?: ContentProgressBulletpointStats[];
  simpleMode?: boolean;
  isStudent?: boolean;
  groupsWithBulletpointStats?: {
    groupId: string;
    groupName: string;
    stats: ContentProgressBulletpointStats[];
    transversalsForStats: TransversalItemWithInfo[];
  }[];
  label: string;
};

export const TransversalProgress = ({
  grade,
  transversals,
  bulletpointStats,
  simpleMode = false,
  isStudent,
  groupsWithBulletpointStats,
  label,
}: Props) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const REDIRECT_URL = `/progress/transversal/${transversals[0].code}/${transversals[0].name} ${grade}`;
  const { mutate: updateSelectedGroup } = useMutation(updateGroupSelection, {
    onSuccess: () => {
      queryClient.invalidateQueries();
      navigate(REDIRECT_URL);
    },
  });
  const clickBox = (groupId?: string) => {
    if (groupId) {
      updateSelectedGroup(groupId);
    } else {
      navigate(isStudent ? `/student${REDIRECT_URL}` : REDIRECT_URL);
    }
  };
  return (
    <Grid item xs={12} sm={6} lg={4}>
      <ProgressHeaderBar
        key={`${transversals[0].code} ${transversals[0].name} -headerBar`}
        text={`${transversals[0].code} ${label}`}
      />
      {bulletpointStats && (
        <Box
          onClick={!simpleMode ? () => clickBox() : () => {}}
          style={!simpleMode ? { cursor: 'pointer' } : {}}
        >
          <ProgressBarWrapper
            stats={getTransversalStats(transversals, bulletpointStats)}
          />
        </Box>
      )}
      {groupsWithBulletpointStats &&
        groupsWithBulletpointStats.map((gp) => (
          <Box
            key={`transversalmapper${gp.groupName}${gp.stats.length}key`}
            onClick={!simpleMode ? () => clickBox(gp.groupId) : () => {}}
            style={!simpleMode ? { cursor: 'pointer' } : {}}
          >
            <ProgressBarWrapper
              groupName={gp.groupName}
              stats={getTransversalStats(gp.transversalsForStats, gp.stats)}
            />
          </Box>
        ))}
    </Grid>
  );
};
