import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import React, { useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { Topic } from '../../../api/topicapi';
import {
  contentContentDialogOpen,
  ContentViewState,
  yearPlanDialogActiveTab,
} from '../../../App/state';
import { CenterLoader } from '../../../components/CenterLoader';
import { QUERY_CONFIG } from '../../../utils/queryconfig';
import { YearPlanDialog } from './YearPlanDialog';
import { ContentSelectorContent } from './ContentSelectorContent';
import { createScrollableId } from './utils';
import {
  CONTENT_PROGRESS_API_QUERY_KEY,
  getAllStats,
} from '../../../api/contentprogressapi';
import { ContentProgressBulletpointStats } from '../types';
import { getSubjectsByGrade } from '../../../utils/ops/getSubjectsByGrade';
import { parseSubjectList } from '../../../utils/ops/utils';
import { useOps } from '../../../hooks/useOps';
import { useTimetables } from '../../../hooks/useTimetables';
import { useTopics } from '../../../hooks/useTopics';
import { LessonHeader } from './LessonHeader';

export const ContentPanelContentAndDialog = ({
  content,
  setContent,
}: {
  content: ContentViewState;
  setContent: (content: ContentViewState | undefined) => void;
}) => {
  const { groupId } = content;
  const [activeTab, setActiveTab] = useRecoilState(yearPlanDialogActiveTab);
  const [dialogOpen, setDialogOpen] = useRecoilState(contentContentDialogOpen);
  const [contentDialogLoader, setContentDialogLoader] = useState(false);
  const { topics, findMatchingTopic } = useTopics(groupId);
  const { data: bpdata } = useQuery({
    queryKey: [CONTENT_PROGRESS_API_QUERY_KEY, { groupId }],
    queryFn: () => getAllStats(groupId, content.year),
    ...QUERY_CONFIG,
    enabled: !!groupId,
  });
  const { ops } = useOps();
  const { findMatchingTimetable } = useTimetables(groupId);
  const stateRef = useRef<Topic>();
  const existingTopic = content.topic;
  stateRef.current = existingTopic;
  const subjectItem = useMemo(
    () =>
      ops
        ? getSubjectsByGrade(
            parseSubjectList(ops, { onlyFavorites: false })
          ).find((si) => si.code === content.subject)
        : undefined,
    [ops, content]
  );
  const subjectTiming = findMatchingTimetable({
    year: content.year,
    lessonNumber: content.lessonNumber,
    grade: content.grade,
    subject: content.subject,
  });

  if (!topics || !bpdata || !ops) {
    return <CenterLoader />;
  }
  const bpStats: ContentProgressBulletpointStats[] = bpdata.length
    ? bpdata
    : [];
  const scrollToContent = (ln: number) => {
    const section = document.querySelector(
      `#${createScrollableId(ln, content.year, content.grade, content.subject)}`
    );
    section?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
  };
  const topicMatch = {
    year: content.year,
    groupId: content.groupId,
    subject: content.subject,
    lessonNumber: content.lessonNumber,
  };
  const curTopic = findMatchingTopic(topicMatch);
  const prevTopic = findMatchingTopic({
    ...topicMatch,
    lessonNumber: content.lessonNumber - 1,
  });
  const nextTopic = findMatchingTopic({
    ...topicMatch,
    lessonNumber: content.lessonNumber + 1,
  });

  const loader = !subjectItem;

  if (loader) {
    return <CenterLoader />;
  }

  return (
    <Stack style={{ height: `100%`, overflowY: 'auto' }}>
      <LessonHeader
        subject={subjectItem}
        subjectTiming={subjectTiming}
        switchMode={() => {
          setActiveTab('Content');
          setDialogOpen(true);
        }}
        smallMode={true}
        amountOfLessons={content.amountOfLessons}
        nextLesson={() => {
          scrollToContent(content.lessonNumber + 1);
          setContent({
            ...content,
            lessonNumber: content.lessonNumber + 1,
            topic: nextTopic,
          });
        }}
        previousLesson={() => {
          scrollToContent(content.lessonNumber - 1);
          setContent({
            ...content,
            lessonNumber: content.lessonNumber - 1,
            topic: prevTopic,
          });
        }}
        lessonNumber={content.lessonNumber}
        groupId={groupId}
        groupName={content.groupName}
        year={content.year}
        grade={content.grade}
      />
      <ContentSelectorContent
        smallMode={true}
        close={() => {
          setContent(undefined);
        }}
        grade={content.grade}
        subject={subjectItem}
        groupId={content.groupId}
        lessonNumber={content.lessonNumber}
        year={content.year}
        bpStats={bpStats}
        existingTopic={existingTopic}
      />
      <YearPlanDialog
        subjectTiming={subjectTiming}
        loader={contentDialogLoader}
        open={dialogOpen}
        switchMode={() => setDialogOpen(false)}
        onClose={() => {
          setDialogOpen(false);
          setContent(undefined);
        }}
        grade={content.grade}
        subject={subjectItem}
        groupName={content.groupName}
        groupId={content.groupId}
        amountOfLessons={content.amountOfLessons}
        lessonNumber={content.lessonNumber}
        year={content.year}
        bpStats={bpStats}
        previousLesson={() => {
          scrollToContent(content.lessonNumber - 1);
          setContent({
            ...content,
            lessonNumber: content.lessonNumber - 1,
            topic: prevTopic,
          });
        }}
        nextLesson={() => {
          scrollToContent(content.lessonNumber + 1);
          setContent({
            ...content,
            lessonNumber: content.lessonNumber + 1,
            topic: nextTopic,
          });
        }}
        existingTopic={existingTopic}
        changeTabToLessonPlan={async () => {
          setContentDialogLoader(true);
          setContentDialogLoader(false);
          setActiveTab('Lesson');
        }}
        changeTabToContent={async () => {
          setContent({
            ...content,
            lessonNumber: content.lessonNumber,
            topic: curTopic,
          });
          setActiveTab('Content');
        }}
        activeTab={activeTab}
      />
    </Stack>
  );
};
