// We want to return 1.9 - 31.8 of current year

import { getYear, isBefore, parseISO } from 'date-fns';

// new date month is 0 indexed. we return current period of 1.9-31.8
export const getCurrentSchoolYear = (): { start: Date; end: Date } => {
  const currentDate = new Date();
  const currentYear = getYear(currentDate);
  let start;
  let end;
  if (isBefore(currentDate, new Date(currentYear, 8, 1))) {
    start = new Date(currentYear - 1, 8, 1);
    end = new Date(currentYear, 7, 31);
  } else {
    start = new Date(currentYear, 8, 1);
    end = new Date(currentYear + 1, 7, 31);
  }
  return { start, end };
};

export const getEndOfSchoolYear = (year: number) =>
  parseISO(`${year}-08-31T23:59:59.000Z`);
export const getStartofSchoolYear = (year: number) =>
  parseISO(`${year}-09-01T00:00:00.000Z`);

export const getStartAndEndBySchoolYear = (
  year: number
): { start: Date; end: Date } => ({
  start: getStartofSchoolYear(year),
  end: getEndOfSchoolYear(year + 1),
});
