import React from 'react';
import { Box, Typography } from '@mui/material';
import { MEDIUM_SPACING, SMALL_SPACING } from '../../../theme';
import { FeatherIcon, IconSize } from '../../../components/FeatherIcon';

export const BulletpointChip = ({
  bulletpointDesc,
  onClick,
  blockMode = false,
  smallMode = false,
}: {
  bulletpointDesc: string;
  blockMode?: boolean;
  onClick?: () => void;
  smallMode: boolean;
}) => (
  <Box
    style={{
      display: blockMode ? 'block' : 'inline-block',
      backgroundColor: '#FBFBFB',
      border: '1px solid #C7C7C7',
      alignItems: 'center',
      marginTop: '1px',
      borderRadius: '16px',
    }}
    sx={{
      mx: SMALL_SPACING,
      my: blockMode ? SMALL_SPACING : MEDIUM_SPACING,
      px: MEDIUM_SPACING,
    }}
  >
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="body2"
        style={{
          color: 'black',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {bulletpointDesc}
      </Typography>
      {onClick && (
        <FeatherIcon
          name="x-circle"
          size={IconSize.SMALL}
          color="grey"
          style={{ marginLeft: smallMode ? 'auto' : '4px', cursor: 'pointer' }}
          onClick={onClick}
        />
      )}
    </Box>
  </Box>
);
