/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Typography } from '@mui/material';
import { addMinutes, differenceInMinutes, subMinutes } from 'date-fns';
import React, { useState } from 'react';
import { useDrag } from 'react-dnd';
import { DaySubject } from './interfaces';
import { dateToTime, formatTime, timeToDate } from './utils';
import 'react-resizable/css/styles.css';

const { Resizable } = require('react-resizable');

const roundTimeQuarterHour = (date: Date): Date => {
  const timeToReturn = new Date(date);

  timeToReturn.setMilliseconds(
    Math.round(timeToReturn.getMilliseconds() / 1000) * 1000
  );
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(Math.round(timeToReturn.getMinutes() / 15) * 15);
  return timeToReturn;
};

export const DraggableDaySubjectBox = ({
  click,
  subject,
  top,
  height,
  removeSubject,
  setSubject,
}: {
  click: () => void;
  subject: DaySubject;
  top: number;
  height: number;
  removeSubject: () => void;
  setSubject: (ss: DaySubject) => void;
}) => {
  const [boxHeight, setBoxHeight] = useState(height);
  const [topPosition, setTopPosition] = useState(top);
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'subject',
    item: {
      id: subject.subject,
      subjectTimingId: subject.subjectTimingId,
      duration: differenceInMinutes(
        timeToDate(subject.to),
        timeToDate(subject.from)
      ),
    },
    end: async (item, monitor) => {
      if (monitor.didDrop() && !subject.subjectTimingId) {
        removeSubject();
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));
  React.useEffect(() => {
    setBoxHeight(height);
  }, [height]);
  React.useEffect(() => {
    setTopPosition(top);
  }, [top]);
  return (
    <Resizable
      height={boxHeight}
      width={100}
      resizeHandles={['n', 's']}
      axis="y"
      onResizeStop={(event: any, props: any) => {
        if (!subject.subjectTimingId) {
          removeSubject();
        }
        const { handle } = props;
        if (handle === 'n') {
          const date = timeToDate(subject.from);
          const newFromTime = subMinutes(date, props.size.height - height);
          setSubject({
            ...subject,
            from: dateToTime(roundTimeQuarterHour(newFromTime)),
          });
        } else {
          const date = timeToDate(subject.to);
          const newFromTime = addMinutes(date, props.size.height - height);
          setSubject({
            ...subject,
            to: dateToTime(roundTimeQuarterHour(newFromTime)),
          });
        }
      }}
      onResize={(event: any, props: any) => {
        if (props.handle === 'n') {
          setTopPosition((curr) => curr - (props.size.height - boxHeight));
        }
        setBoxHeight(props.size.height);
      }}
    >
      <div
        style={{
          opacity: isDragging ? 0.5 : 1,
          position: 'absolute',
          height: `${boxHeight}px`,
          top: `${topPosition}px`,
          width: '100%',
          left: '-2px',
          border: '1px solid',
          borderRadius: '8px',
          cursor: 'pointer',
          overflow: 'hidden',
          backgroundColor: '#FFFFFF',
        }}
      >
        <div
          onClick={click}
          ref={drag}
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'right',
              justifyContent: 'right',
            }}
          >
            <Typography variant="caption" style={{ alignSelf: 'flex-end' }}>
              {formatTime(subject.from)}-{formatTime(subject.to)}
            </Typography>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '1px',
            }}
          >
            <Typography variant="body2">
              {subject.subject.split('-')[0]}
            </Typography>
          </div>
        </div>
      </div>
    </Resizable>
  );
};
