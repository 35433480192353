import { endOfWeek, getISOWeek, startOfWeek } from 'date-fns';
import { ScheduleWeek } from '../generics/types/scheduleWeek';

export type Week = {
  startDate: Date;
  endDate: Date;
  weekNumber: number;
  label: string;
};

export const DAYFORMAT = 'dd/MM/yyyy';

export const getWeekFromDay = (day: Date): ScheduleWeek => ({
  startAt: startOfWeek(day, { weekStartsOn: 1 }),
  endAt: endOfWeek(day, { weekStartsOn: 1 }),
  weekNumber: getISOWeek(day),
});
