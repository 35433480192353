import React from 'react';
import { Box, Typography } from '@mui/material';

export const TimeLineItemDescription = (props: {
  description: string;
  isHighlighted?: boolean;
}) => (
  <Box style={{ marginTop: '5px' }}>
    <Typography
      variant="caption"
      style={{
        whiteSpace: 'break-spaces',
        fontSize: props.isHighlighted ? '48px' : '0.95rem',
        lineHeight: props.isHighlighted ? '55px' : '0',
        marginTop: props.isHighlighted ? '20px' : '5px',
        overflowWrap: 'anywhere',
      }}
    >
      {props.description}
    </Typography>
  </Box>
);
