import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deAttachLink, createLinksApiQueryKey } from '../api/linksapi';
import { SCHEDULE_API_KEY } from '../api/scheduleapi';

export const useRemoveLink = (params: {
  subjectCode: string;
  gradeId: string;
  onSuccess?: () => void;
  onError?: () => void;
}) => {
  const queryClient = useQueryClient();
  const linksApiQueryKey = createLinksApiQueryKey(
    params.subjectCode,
    params.gradeId
  );
  const { mutate: removeLink } = useMutation(deAttachLink, {
    onSuccess: () => {
      queryClient.invalidateQueries([linksApiQueryKey]);
      queryClient.invalidateQueries({
        queryKey: [SCHEDULE_API_KEY],
      });
      if (params.onSuccess) {
        params.onSuccess();
      }
    },
    onError: () => {
      if (params.onError) {
        params.onError();
      }
    },
  });

  return { removeLink };
};
