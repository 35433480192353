import { Box, Card, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRecoilState } from 'recoil';
import { addOrPutGroup } from '../../api/groupapi';
import { groupEditorGroup } from '../../App/state';
import { PanelOrDrawer } from '../../components/PanelOrDrawer';
import { useFavoriteSubjects } from '../../hooks/useFavoriteSubjects';
import { LARGE_SPACING } from '../../theme';
import { getSubjectsByGrade } from '../../utils/ops/getSubjectsByGrade';
import { parseSubjectList } from '../../utils/ops/utils';
import { parseNumber } from '../../utils/string';
import { SubjectItem } from '../ops/components/types/types';
import { NameAndGrade } from './nameandgrade/NameAndGrade';
import { GroupPanelOrDrawerContent } from './panel/GroupPanelOrDrawerContent';
import { Phase } from './phase';
import { Students } from './students/Students';
import { GroupSubjects } from './subjects/GroupSubjects';
import { Timetables } from './timetable/Timetables';
import { ViewGroups } from './viewgroups/ViewGroups';
import { CenterLoader } from '../../components/CenterLoader';
import { useOps } from '../../hooks/useOps';
import { useAuth } from '../../utils/auth/useAuth';

const getSubjects = (ops: any): SubjectItem[] =>
  getSubjectsByGrade(parseSubjectList(ops, { onlyFavorites: false }));

const getAvailableGrades = (subjects: SubjectItem[]): string[] => [
  ...new Set(
    subjects
      ?.flatMap((s) => s.grades)
      .map((g) => g?.grade)
      .filter((x): x is number => x !== null && x !== undefined)
      .map((v) => v.toString())
  ),
];

const getSubjectsThatHaveGrade = (subjects: SubjectItem[], grade?: string) => {
  if (!subjects.length || !grade) {
    return [];
  }
  return [
    ...new Set(
      subjects?.filter(
        (s) =>
          !!s.grades?.filter((g) => g?.grade === parseNumber(grade)!!)?.length
      )
    ),
  ];
};

export const GroupsPage = () => {
  const { user } = useAuth();
  const [phase, setPhase] = useState(Phase.VIEW_GROUPS);
  const [group, setGroup] = useRecoilState(groupEditorGroup);
  const { favoriteSubjects } = useFavoriteSubjects(group?.groupId);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();
  const updateGroupInState = (
    name?: string,
    grade?: string,
    groupId?: string,
    students?: string[]
  ) => {
    setGroup({
      ...group,
      name: name || (group?.name ? group?.name : ''),
      grade: grade ?? group?.grade,
      students: students ?? group?.students ?? [],
      school: group?.school ?? user!!.ops!!,
      subjects: group?.subjects ?? [],
      selected: group?.selected ?? false,
      groupId: groupId ?? group?.groupId,
    });
  };

  const { mutate: addOrUpdateGroup } = useMutation(addOrPutGroup, {
    onSuccess: (data) => {
      updateGroupInState(undefined, undefined, data.groupId);
      queryClient.invalidateQueries();
      enqueueSnackbar('Group saved succesfully', {
        variant: 'success',
      });
      setPhase(phase + 1);
    },
    onError: () => {
      enqueueSnackbar('Something went wrong when trying to save group', {
        variant: 'error',
      });
    },
  });
  const { ops } = useOps();

  if (!ops) {
    return <CenterLoader />;
  }

  const subjects = getSubjects(ops);
  const availableGrades = getAvailableGrades(subjects);
  const subjectsForFavorites = getSubjectsThatHaveGrade(subjects, group?.grade);
  return (
    <>
      <Box style={{ display: 'flex' }}>
        <PanelOrDrawer showPanelControls={false}>
          <GroupPanelOrDrawerContent
            setPhase={(p: Phase) => setPhase(p)}
            phase={phase}
            name={group?.name ?? ''}
            grade={group?.grade}
            availableGrades={availableGrades}
            enableSubjectLink={!!(group?.name && group?.grade)}
            enableTimeTableLink={
              !!(favoriteSubjects && favoriteSubjects?.length > 0)
            }
            subjectsForFavorites={subjectsForFavorites}
            groupId={group?.groupId}
            existingStudentEmails={group?.existingStudentEmails?.length ?? 0}
            allStudentEmails={group?.students?.length ?? 0}
          />
        </PanelOrDrawer>
        <Box style={{ width: '100%' }} sx={{ p: LARGE_SPACING }}>
          {(phase === Phase.VIEW_GROUPS || phase === Phase.VIEW_GROUP) && (
            <div style={{ margin: '20px' }}>
              <ViewGroups setPhase={(p: Phase) => setPhase(p)} />
            </div>
          )}
          {phase !== Phase.VIEW_GROUPS && phase !== Phase.VIEW_GROUP && (
            <Card
              sx={{ p: 5 }}
              style={{ marginTop: '10px', marginLeft: '10px' }}
            >
              <Typography
                variant="h5"
                style={{ color: 'grey', marginBottom: '10px' }}
              >
                {group?.groupId ? 'Edit group' : 'Add new group'}
              </Typography>
              {phase === Phase.NAME && (
                <NameAndGrade
                  name={group?.name ?? ''}
                  setName={(n: string) => updateGroupInState(n)}
                  grade={group?.grade}
                  setGrade={(g: string) => updateGroupInState(undefined, g)}
                  availableGrades={availableGrades}
                  saveAndGoToNextPhase={() => addOrUpdateGroup(group)}
                />
              )}
              {phase === Phase.SUBJECTS && (
                <GroupSubjects
                  grade={group?.grade}
                  groupId={group?.groupId}
                  subjects={subjectsForFavorites}
                  nextPhase={() => setPhase(Phase.TIMETABLE)}
                  previousPhase={() => setPhase(Phase.NAME)}
                />
              )}
              {phase === Phase.TIMETABLE && (
                <Timetables
                  groupId={group?.groupId}
                  favoriteSubjects={
                    favoriteSubjects?.filter(
                      (fs) => fs.split('-')[1] === group?.grade
                    ) ?? []
                  }
                  previousPhase={() => setPhase(Phase.SUBJECTS)}
                  nextPhase={() => setPhase(Phase.STUDENTS)}
                />
              )}
              {phase === Phase.STUDENTS && (
                <Students
                  group={group!!}
                  updateStudents={(students: string[]) =>
                    updateGroupInState(
                      undefined,
                      undefined,
                      undefined,
                      students
                    )
                  }
                />
              )}
            </Card>
          )}
        </Box>
      </Box>
    </>
  );
};
