import { SCHEDULE_API_URL, SCHEDULE_STUDENT_API_URL } from './constants';
import { apiGet } from './genericapi';
import { TimeTable } from './timetablesapi';
import { Topic } from './topicapi';

interface GroupSchedule {
  groupId: string;
  name: string;
  timetables: TimeTable[];
  topics: Topic[];
}

export type ScheduleResponse = GroupSchedule[];

export const getSchedule = (): Promise<any> => apiGet(SCHEDULE_API_URL);

export const getStudentSchedule = (): Promise<any> =>
  apiGet(SCHEDULE_STUDENT_API_URL);

export const SCHEDULE_API_KEY = 'schedule';
