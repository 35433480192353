import { Box, Typography } from '@mui/material';
import React from 'react';
import { ConnectDragSource } from 'react-dnd';
import { LinkItem } from '../routes/ops/components/types/types';
import {
  MEDIUM_SPACING,
  SMALL_SPACING,
  THEME_MAIN_TEXT_COLOR,
  XXL_SPACING,
} from '../theme';
import { FeatherIcon, IconSize } from './FeatherIcon';

export const activityTypeToIcon = (type: string | undefined): string => {
  switch (type) {
    case 'BOOK':
      return 'book';
    case 'FUNCTIONAL':
      return 'sun';
    case 'LINK':
    default:
      return 'link';
  }
};

export const ActivityChip = ({
  activity,
  isSelected,
  dragRef,
  style,
}: {
  activity: LinkItem;
  isSelected: boolean;
  dragRef?: ConnectDragSource;
  style?: { [key: string]: string | number };
}) => (
  <Box
    ref={dragRef}
    key={activity.linkId}
    style={{
      backgroundColor: isSelected ? '#245F73' : '#F2F3F5',
      borderRadius: '16px',
      border: '1px solid #E4E4E5',
      height: '30px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      display: 'inline-block',
      ...style,
    }}
    sx={{ mx: SMALL_SPACING, p: SMALL_SPACING }}
  >
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        margin: 'auto',
        width: '100%',
        height: '100%',
      }}
    >
      <FeatherIcon
        color={isSelected ? '#FFFFFF' : THEME_MAIN_TEXT_COLOR}
        name={activityTypeToIcon(activity.activityType)}
        size={IconSize['EXTRA-SMALL']}
        style={{ marginLeft: XXL_SPACING }}
      />
      <Typography
        variant="caption"
        style={{ color: isSelected ? '#FFFFFF' : THEME_MAIN_TEXT_COLOR }}
        sx={{ mx: MEDIUM_SPACING }}
      >
        {activity.activityType !== 'BOOK' && (activity.title || '')}
        {activity.activityType === 'BOOK' &&
          `${activity.bookTitle || ''};${activity.page}`}
      </Typography>
    </Box>
  </Box>
);
