import React from 'react';

import { Box, Typography } from '@mui/material';
import { SMALL_SPACING } from '../theme';
import { FeatherIcon, IconSize } from './FeatherIcon';

const THIS_LIGHT_COLOR = '#C2C2C2';

const Indicator = ({ color = THIS_LIGHT_COLOR }: { color?: string }) => (
  <Box
    style={{
      borderRadius: 3,
      backgroundColor: color,
      width: '100%',
      minWidth: '1px',
      height: '6px',
    }}
    sx={{ m: SMALL_SPACING }}
  />
);

export const ProgressBarWithIcon = ({
  completed,
  planned,
  notCompleted,
  style = {},
}: {
  completed: number;
  planned: number;
  notCompleted: number;
  style?: Record<string, string | number>;
}) => (
  <Box
    sx={{ mx: SMALL_SPACING }}
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      ...style,
    }}
  >
    <Box style={{ display: 'flex', width: '100%' }}>
      {[...Array(completed)].map((number, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Indicator
          key={`${number + index}completed${Math.random()}`}
          color="#6FC575"
        />
      ))}
      {[...Array(planned)].map((number, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Indicator
          key={`${number + index}planned${Math.random()}`}
          color="#E3E34A"
        />
      ))}
      {[...Array(notCompleted)].map((number, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Indicator
          key={`${number + index}notCompleted${Math.random()}`}
          color={THIS_LIGHT_COLOR}
        />
      ))}
    </Box>
    <Box
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        justifySelf: 'flex-end',
      }}
    >
      <Typography
        variant="caption"
        sx={{ mr: SMALL_SPACING }}
        style={{ color: THIS_LIGHT_COLOR }}
      >
        {completed}/{completed + notCompleted + planned}
      </Typography>
      <FeatherIcon
        size={IconSize['EXTRA-SMALL']}
        name="award"
        color={THIS_LIGHT_COLOR}
        fill={true}
      />
    </Box>
  </Box>
);
