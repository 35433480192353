import {
  addMinutes,
  getHours,
  getMinutes,
  isValid,
  setHours,
  setMinutes,
} from 'date-fns';
import { timestampWithoutTimezone } from './timetable/utils';
import { Group } from '../../api/groupapi';
import { SubjectTiming, TimeTable } from '../../api/timetablesapi';
import { parseNumber } from '../../utils/string';
import { SubjectItem } from '../ops/components/types/types';
import { Time } from './timetable/interfaces';
import { subjectsForDaysToSubjectTiming } from './timetable/Timetables';

export interface Row {
  startDate: string;
  endDate: string;
  weekDay: string;
  time: string;
  length: string;
  grade: string;
  subject: string;
  teacher: string;
  groupName: string;
}

const throwIfFalsy = (value?: string): void => {
  if (!value) {
    throw new Error('EMPTY');
  }
};

const stringToDate = (dateString?: string): Date => {
  const [day, month, year] = dateString!!.split('.');
  return new Date([month, day, year].join('.'));
};

const validateDate = (date?: string): void => {
  throwIfFalsy(date);
  if (!isValid(stringToDate(date))) {
    throw new Error(date);
  }
};

const validateWeekday = (weekday?: string): void => {
  throwIfFalsy(weekday);
  if (
    ![
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday',
    ].includes(weekday!!)
  ) {
    throw new Error(weekday);
  }
};

const validateLength = (length?: string): void => {
  throwIfFalsy(length);
  const parsed = parseNumber(length!!);
  if (!parsed || parsed < 0 || parsed > 360) {
    throw new Error(length);
  }
};

const validateTime = (time?: string): void => {
  throwIfFalsy(time);
  const splitted = time!!.split(':');
  const parsedFirstNumber = parseNumber(splitted[0]);
  if (!parsedFirstNumber || parsedFirstNumber > 24) {
    throw new Error(time);
  }
  splitted.forEach((number) => {
    const parsedNumber = parseNumber(number);
    if (
      parsedNumber === undefined ||
      parsedNumber === null ||
      parsedNumber < 0 ||
      parsedNumber > 59
    ) {
      throw new Error(time);
    }
  });
};

export const subjectAndGradeToFavorite = (
  subject: string,
  grade: string
): string =>
  `${subject
    .split(' ')
    .map((word) => word.replace(/^./, word[0].toUpperCase()))
    .join(' ')}-${grade}`;

const validateGrade = (grade: string): void => {
  throwIfFalsy(grade);
  const parsed = parseNumber(grade!!);
  if (!parsed || parsed < 1 || parsed > 6) {
    throw new Error(grade);
  }
};

const validateSubject = (
  allSubjectItems: SubjectItem[],
  grade: string,
  subject?: string
): void => {
  throwIfFalsy(subject);
  if (
    !allSubjectItems.some(
      (subjectItem) =>
        !!subjectItem.grades?.find(
          (g) =>
            g.grade === parseNumber(grade) &&
            subjectItem.code.toLowerCase() === subject?.toLowerCase()
        )
    )
  ) {
    throw new Error(subject);
  }
};

const validate = (
  data: any[],
  allSubjectItems: SubjectItem[]
): string | undefined => {
  try {
    data.forEach((d) => {
      validateDate(d.startDate);
      validateDate(d.endDate);
      validateWeekday(d.weekDay);
      validateTime(d.time);
      // eslint-disable-next-line dot-notation
      validateLength(d['length']);
      validateGrade(d.grade);
      validateSubject(allSubjectItems, d.grade, d.subject);
      throwIfFalsy(d.groupName);
    });
  } catch (e: any) {
    return e.message;
  }
  return undefined;
};

export const validateAndFormatGroup = (
  data: any[],
  allSubjectItems: SubjectItem[]
): { errorValue: string } | Row[] => {
  const validationError = validate(data, allSubjectItems);
  if (validationError) {
    return { errorValue: validationError };
  }
  const rows: Row[] = data;
  return rows;
};

// Time is like "08:00:00", duration like "45"
const timeAndDurationToFromAndTo = (
  time: string,
  duration: string
): { from: Time; to: Time } => {
  const timeSplitted = time.split(':');
  const toTimeAsDate: Date = addMinutes(
    setHours(
      setMinutes(new Date(), parseNumber(timeSplitted[1])!!),
      parseNumber(timeSplitted[0])!!
    ),
    parseNumber(duration)!!
  );
  return {
    from: {
      hour: parseNumber(timeSplitted[0])!!,
      minutes: timeSplitted[1] === '00' ? 0 : parseNumber(timeSplitted[1])!!,
    },
    to: {
      hour: getHours(toTimeAsDate),
      minutes: getMinutes(toTimeAsDate),
    },
  };
};

const getTimesFromRow = (row: Row): SubjectTiming[] =>
  subjectsForDaysToSubjectTiming(
    stringToDate(row.startDate),
    stringToDate(row.endDate),
    {
      [row.weekDay.toUpperCase().substring(0, 3)]: [
        {
          subject: subjectAndGradeToFavorite(row.subject, row.grade),
          // eslint-disable-next-line dot-notation
          ...timeAndDurationToFromAndTo(row.time, row['length']),
        },
      ],
    }
  );

const getTimetablesForGroup = (rowsForGroup: Row[]): TimeTable[] =>
  rowsForGroup
    .reduce((arr: TimeTable[], curr: Row) => {
      const foundTimetable = arr.find(
        (a) => a.from === curr.startDate && a.to === curr.endDate
      );
      if (foundTimetable) {
        return [
          ...arr.filter(
            (a) => !(a.from === curr.startDate && a.to === curr.endDate)
          ),
          {
            ...foundTimetable,
            times: [...foundTimetable.times, ...getTimesFromRow(curr)],
          },
        ];
      }
      return [
        ...arr,
        {
          from: curr.startDate,
          to: curr.endDate,
          title: 'no-title',
          times: getTimesFromRow(curr),
        },
      ];
    }, [] as TimeTable[])
    .map((tt) => ({
      ...tt,
      from: timestampWithoutTimezone(stringToDate(tt.from)),
      to: timestampWithoutTimezone(stringToDate(tt.to)),
    }));

const rowToGroupWithFavorites = (
  row: Row,
  ops?: string
): GroupWithFavorites => ({
  group: {
    name: row.groupName,
    grade: row.grade,
    school: ops ?? 'GEORGIA',
    students: [],
    subjects: [],
    selected: false,
  },
  favorites: [subjectAndGradeToFavorite(row.subject, row.grade)],
});

export const rowToGroupsAndTimetables = (
  data: Row[],
  ops?: string
): GroupWithFavoritesAndTimetables[] => {
  const parsed: GroupWithFavorites[] = data.reduce(
    (arr: GroupWithFavorites[], current: Row) => {
      const foundGroup = arr.find(
        (existingGroup) => existingGroup.group.name === current.groupName
      );
      if (foundGroup) {
        return [
          ...arr.filter((eg) => eg.group.name !== current.groupName),
          {
            group: foundGroup.group,
            favorites: [
              ...new Set([
                ...foundGroup.favorites,
                subjectAndGradeToFavorite(current.subject, current.grade),
              ]),
            ],
          },
        ];
      }
      return [...arr, rowToGroupWithFavorites(current, ops)];
    },
    [] as GroupWithFavorites[]
  );
  return parsed.map((p) => ({
    ...p,
    timetables: getTimetablesForGroup(
      data.filter((d) => d.groupName === p.group.name)
    ),
  }));
};

export type GroupWithFavorites = {
  group: Group;
  favorites: string[];
};

export type GroupWithFavoritesAndTimetables = {
  timetables: TimeTable[];
} & GroupWithFavorites;
