import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useConfirm } from 'material-ui-confirm';
import { DataGrid } from '@mui/x-data-grid';
import { getInvitedTeachers } from '../../api/adminapi';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { TeacherDialog } from './TeacherDialog';
import * as adminApi from '../../api/adminapi';
import { SMALL_SPACING } from '../../theme';

const columns = [
  { field: 'email', headerName: 'Email', type: 'string', flex: 1 },
  { field: 'ops', headerName: 'Ops', type: 'string', flex: 1 },
];

interface Teacher {
  email: string;
  ops: string;
}

export const InvitedTeachersTable = () => {
  const confirm = useConfirm();
  const queryClient = useQueryClient();
  const [teacherDialogOpen, setTeacherDialogOpen] = useState(false);
  const invitedTeachers = useQuery(['teachers'], () => getInvitedTeachers(), {
    ...QUERY_CONFIG,
  });

  const { mutate: deleteEmail } = useMutation(adminApi.deleteTeacher, {
    onSuccess: () => {
      queryClient.invalidateQueries(['teachers']);
    },
  });

  if (!invitedTeachers.data) {
    return <CircularProgress />;
  }
  const invitedTeachersArr: Teacher[] = invitedTeachers?.data.length
    ? invitedTeachers.data
    : [];

  return (
    <Stack>
      <Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          sx={{ mb: SMALL_SPACING }}
        >
          <Typography variant="h6">Invited Teachers</Typography>{' '}
          <Box>
            <Button
              style={{ float: 'right' }}
              color="primary"
              variant="contained"
              onClick={() => setTeacherDialogOpen(true)}
            >
              Add new teacher
            </Button>
          </Box>
        </Box>
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid
            rows={invitedTeachersArr.map((u) => ({
              email: u.email,
              ops: u.ops,
              id: Math.random()
                .toString(36)
                .replace(/[^a-z]+/g, '')
                .substr(0, 5),
            }))}
            columns={columns}
            pageSize={50}
            onRowClick={(evt) => {
              confirm({
                title: 'Are you sure you want to delete user',
                description: `${evt.row.email}`,
              })
                .then(() => {
                  deleteEmail(evt.row.email!!);
                })
                .catch(() => {});
            }}
          />
          <TeacherDialog
            open={teacherDialogOpen}
            onClose={() => setTeacherDialogOpen(false)}
          />
        </div>
      </Box>
    </Stack>
  );
};
