import { useState, useMemo } from 'react';

export function useArrayState<Type>(initialValue: Type[]) {
  const [values, setValues] = useState<Type[]>(initialValue);

  const includes = (value: Type) => values.includes(value);

  const add = (value: Type) => {
    setValues((oldVals) => {
      if (oldVals.includes(value)) {
        return oldVals;
      }
      return [...oldVals, value];
    });
  };

  const remove = (value: Type) => {
    setValues((oldVals) => oldVals.filter((v) => v !== value));
  };

  const toggle = (value: Type) => {
    setValues((oldVals) => {
      if (oldVals.includes(value)) {
        return oldVals.filter((v) => v !== value);
      }
      return [...oldVals, value];
    });
  };

  const clear = () => {
    setValues([]);
  };

  return useMemo(
    () => ({ values, includes, add, remove, toggle, clear }),
    [values]
  );
}
