/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Box, Button } from '@mui/material';
import React from 'react';
import { useRecoilState } from 'recoil';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { useConfirm } from 'material-ui-confirm';
import { groupEditorGroup } from '../../../App/state';
import { GroupTimetableHeader } from '../headers/GroupTimetableHeader';
import { NameAndGrade } from '../nameandgrade/NameAndGrade';
import { Phase } from '../phase';
import { GroupSubjects } from '../subjects/GroupSubjects';
import { SubjectItem } from '../../ops/components/types/types';
import { TimetableSelector } from '../timetable/TimetableSelector';
import { StudentsPanelView } from '../students/StudentsPanelView';
import { deleteGroup } from '../../../api/groupapi';
import { useTimetables } from '../../../hooks/useTimetables';

const returnStyle = (showCursor: boolean) => ({
  padding: '8px',
  cursor: showCursor ? 'pointer' : 'not-allowed',
});

export const GroupPanelOrDrawerContent = ({
  setPhase,
  phase,
  name,
  grade,
  availableGrades,
  enableSubjectLink,
  enableTimeTableLink,
  subjectsForFavorites,
  groupId,
  existingStudentEmails,
  allStudentEmails,
}: {
  setPhase: (p: Phase) => void;
  phase: Phase;
  name: string;
  availableGrades: string[];
  grade?: string;
  enableSubjectLink: boolean;
  enableTimeTableLink: boolean;
  subjectsForFavorites: SubjectItem[];
  groupId?: string;
  existingStudentEmails: number;
  allStudentEmails: number;
}) => {
  const queryClient = useQueryClient();
  const intl = useIntl();
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const [_, setGroup] = useRecoilState(groupEditorGroup);
  const { timetables } = useTimetables(groupId);

  const clearGroup = () => {
    setGroup(undefined);
    setPhase(Phase.VIEW_GROUPS);
  };

  const { mutate: deleteGroupById } = useMutation(deleteGroup, {
    onSuccess: () => {
      clearGroup();
      queryClient.invalidateQueries();
      enqueueSnackbar(
        intl.formatMessage({ id: 'notifications.group.deleted' }),
        {
          variant: 'success',
        }
      );
    },
    onError: () => {
      enqueueSnackbar(
        intl.formatMessage({ id: 'notifications.genericError' }),
        {
          variant: 'error',
        }
      );
    },
  });

  return (
    <Box sx={{ p: 5 }}>
      {phase !== Phase.VIEW_GROUPS && (
        <>
          {phase === Phase.VIEW_GROUP && (
            <Button
              variant="contained"
              color="primary"
              size="small"
              style={{ float: 'right' }}
              onClick={() => {
                setPhase(Phase.NAME);
              }}
            >
              Edit
            </Button>
          )}
          <div onClick={() => setPhase(Phase.NAME)} style={returnStyle(true)}>
            <NameAndGrade
              panelMode={true}
              name={name}
              grade={grade}
              availableGrades={availableGrades}
            />
          </div>
          <div
            onClick={() => enableSubjectLink && setPhase(Phase.SUBJECTS)}
            style={returnStyle(enableSubjectLink)}
          >
            <GroupSubjects
              grade={grade}
              groupId={groupId}
              subjects={subjectsForFavorites}
              panelMode={true}
            />
          </div>
          <div style={returnStyle(false)}>
            <GroupTimetableHeader checked={timetables?.length} />
            <TimetableSelector
              timetables={timetables}
              enableNewTimetableLink={enableTimeTableLink}
              moveToTimetablesPage={() => setPhase(Phase.TIMETABLE)}
            />
          </div>
          <div
            onClick={() => setPhase(Phase.STUDENTS)}
            style={returnStyle(timetables?.length)}
          >
            <StudentsPanelView
              existingStudentEmails={existingStudentEmails}
              allStudentEmails={allStudentEmails}
            />
          </div>
          <div
            style={{
              marginTop: '15px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              size="small"
              variant="contained"
              style={{ backgroundColor: 'crimson', color: '#FFFFFF' }}
              onClick={() =>
                confirm({
                  title: intl.formatMessage({
                    id: 'groups.delete.confirm',
                  }),
                  confirmationButtonProps: {
                    color: 'primary',
                    variant: 'contained',
                  },
                  cancellationButtonProps: {
                    color: 'secondary',
                    variant: 'contained',
                  },
                }).then(() => {
                  deleteGroupById(groupId!!);
                })
              }
            >
              {intl.formatMessage({ id: 'button.delete' })}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => clearGroup()}
            >
              {intl.formatMessage({
                id:
                  phase === Phase.STUDENTS &&
                  (!!existingStudentEmails || !!allStudentEmails)
                    ? 'button.done'
                    : 'button.cancel',
              })}
            </Button>
          </div>
        </>
      )}
    </Box>
  );
};
