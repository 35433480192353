/* eslint-disable @typescript-eslint/indent */
import { CircularProgress, Grid } from '@mui/material';
import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import {
  CONTENT_PROGRESS_API_QUERY_KEY,
  getAllStudentStats,
} from '../../api/contentprogressapi';
import { QUERY_CONFIG } from '../../utils/queryconfig';
import { subjectShortForm } from '../../utils/subjectShortForm';
import { ContentProgressBulletpointStats } from '../my-plan/types';
import { TransversalItemWithInfo } from '../ops/components/types/types';
import { getSubjectsByGrade } from '../../utils/ops/getSubjectsByGrade';
import { parseSubjectList } from '../../utils/ops/utils';
import { TransversalProgressCard } from './TransversalProgressCard';
import { parseTransversalsMap } from './TransversalHelpers';
import { TransversalProgressHeader } from './TransversalProgressHeader';
import { getStudentFavorites } from '../../api/usersapi';
import { CenterLoader } from '../../components/CenterLoader';
import { useOps } from '../../hooks/useOps';

// @ts-ignore
const queryFn = (obj: unknown) => getAllStudentStats(obj.groupId);

export const StudentTransversalProgressPage = () => {
  const { transversal, transversalCode } = useParams();
  const { data: favoriteSubjects } = useQuery<string[]>(
    ['studentfavorites'],
    async () => getStudentFavorites(),
    {
      ...QUERY_CONFIG,
    }
  );
  const { ops } = useOps();
  const { data: bpdata } = useQuery({
    queryKey: [CONTENT_PROGRESS_API_QUERY_KEY],
    queryFn,
    ...QUERY_CONFIG,
    enabled: true,
  });

  if (!ops || !bpdata) {
    return <CircularProgress />;
  }

  const subjects = getSubjectsByGrade(
    parseSubjectList(ops, { onlyFavorites: true }, favoriteSubjects)
  );
  const bpStats: ContentProgressBulletpointStats[] = bpdata.length
    ? bpdata
    : [];
  const transversals = parseTransversalsMap(subjects);

  const currentTransversals = transversals.get(
    Array.from(transversals.keys()).find((key) => key === transversal)!!
  );

  const groupedBySubj = new Map<string, TransversalItemWithInfo[]>();

  currentTransversals?.forEach((ct) => {
    const subjectCode = subjectShortForm(
      ct.subject.abbreviation,
      ct.grade.grade
    );
    const existing = groupedBySubj.get(subjectCode);
    if (existing) {
      groupedBySubj.set(subjectCode, [...existing, ct]);
    } else {
      groupedBySubj.set(subjectCode, [ct]);
    }
  });

  if (!transversal || !transversalCode) {
    return <CenterLoader />;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TransversalProgressHeader
            transversal={transversal}
            transversalCode={transversalCode}
            transversalItems={currentTransversals!!}
            grade={currentTransversals!![0].grade.grade.toString()}
            bulletpointStats={bpStats}
            isStudent={true}
          />
        </Grid>
        {Array.from(groupedBySubj.keys()).map((subj) => (
          <TransversalProgressCard
            key={subj}
            bulletpointStats={bpStats}
            transversals={groupedBySubj.get(subj)!!}
            subjects={subjects}
            isStudent={true}
          />
        ))}
      </Grid>
    </>
  );
};
