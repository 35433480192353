import { Box, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { THEME_MAIN_COLOR } from '../../theme';
import { FeatherIcon, IconSize } from '../../components/FeatherIcon';

export const ProgressHeaderBar = ({
  text,
  tooltipText,
}: {
  text: string;
  tooltipText?: string;
}) => (
  <div
    style={{
      backgroundColor: THEME_MAIN_COLOR,
      width: '100%',
      color: '#FFFFFF',
      padding: '5px',
      borderRadius: '3px',
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <Typography variant="body1">{text}</Typography>
    {tooltipText && (
      <Tooltip title={tooltipText}>
        <Box>
          <FeatherIcon size={IconSize.SMALL} name="info" color="white" />
        </Box>
      </Tooltip>
    )}
  </div>
);
