import styled from 'styled-components';
import { DETAIL_COLOR } from '../theme';

const Ball = (
  height: string,
  width: string,
  color?: string,
  backgroundColor: string = 'white'
) => styled.div`
  border: 1px solid ${color || ((p) => p.theme.palette.text.secondary)};
  color: ${color || ((p) => p.theme.palette.text.secondary)};
  border-color: ${color || ((p) => p.theme.palette.text.secondary)};
  border-radius: 50%;
  background-color: ${backgroundColor};
  min-height: ${height};
  min-width: ${width};
  max-height: ${height};
  max-width: ${width};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

export const SmallLinkBall = Ball('24px', '24px');

export const ContentBall = Ball('34px', '34px', DETAIL_COLOR);

export const SmallDetailBall = Ball('8px', '8px', DETAIL_COLOR, DETAIL_COLOR);

export const SmallContentBall = Ball(
  '23px',
  '23px',
  DETAIL_COLOR,
  DETAIL_COLOR
);

export const BulletpointBall = Ball('4px', '4px', DETAIL_COLOR, DETAIL_COLOR);
