import { ADMIN_API_URL } from './constants';
import { apiDeleteWithBody, apiGet, apiPatch, apiPost } from './genericapi';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getUsers = (): Promise<any> => apiGet(`${ADMIN_API_URL}/users`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getInvitedTeachers = (): Promise<any> =>
  apiGet(`${ADMIN_API_URL}/teachers`);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postTeacher = (payload: {
  email: string;
  ops: string;
}): Promise<any> => apiPost(`${ADMIN_API_URL}/teachers/`, payload);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deleteTeacher = (email: string): Promise<any> =>
  apiDeleteWithBody(`${ADMIN_API_URL}/teachers/`, {
    email,
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateOps = (payload: any): Promise<any> =>
  apiPatch(`${ADMIN_API_URL}/users/${payload.userId}/ops`, {
    ops: payload.ops,
  });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateAdmin = (payload: any): Promise<any> =>
  apiPatch(`${ADMIN_API_URL}/users/${payload.userId}/admin`, payload.admin);
