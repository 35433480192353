import * as React from 'react';
import {
  Grid,
  Typography,
  Card,
  Box,
  ButtonBase,
  useTheme,
} from '@mui/material';
import { SimpleSubject } from '../my-plan/helpers';
import { THEME_ACCENT_COLOR } from '../../theme';

/**
 * These components are used to render selectable subjects
 */

type Props = {
  favorite?: boolean;
  title: React.ReactNode;
  disabledSubjects?: SimpleSubject[];
  subject: string;
  grade: number;
  onClick: () => void;
};

const selectedStyle = {
  backgroundColor: THEME_ACCENT_COLOR,
  color: '#FFFFFF',
};

const resolveStyle = (
  subject: string,
  grade: number,
  disabledSubjects?: SimpleSubject[]
) => {
  if (!disabledSubjects) {
    return {};
  }
  return disabledSubjects?.find(
    (subj) =>
      subj.subjectId.toLowerCase() === subject.toLowerCase() &&
      subj.gradeId === grade
  )
    ? {}
    : selectedStyle;
};

export const SubjectGridItem = ({
  favorite,
  title,
  onClick,
  disabledSubjects,
  subject,
  grade,
}: Props) => {
  const theme = useTheme();

  return (
    <Grid item xs={4} sm={3} md={2} lg={1}>
      <Card
        onClick={onClick}
        variant="outlined"
        style={{ width: '100%', overflow: 'hidden', borderRadius: '15%' }}
      >
        <ButtonBase style={{ width: '100%' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            height={{ xs: '60px', sm: '100px' }}
            p={2}
            bgcolor={favorite ? theme.palette.primary.main : 'inherit'}
            color={favorite ? theme.palette.primary.contrastText : 'inherit'}
            style={resolveStyle(subject, grade, disabledSubjects)}
            width="100%"
          >
            <Typography
              style={{ whiteSpace: 'nowrap' }}
              variant="caption"
              align="center"
            >
              {title}
            </Typography>
          </Box>
        </ButtonBase>
      </Card>
    </Grid>
  );
};
