import React from 'react';

export const YEAR_PLAN_CARD_HEIGHT = 250;
export const YEAR_PLAN_CARD_HEIGHT_IN_HEADER = 100;
export const YEAR_PLAN_CARD_HEIGHT_IN_HEADER_SELECTED = 150;
export const YEAR_PLAN_CARD_WIDTH = 172;
export const YEAR_PLAN_CARD_SELECTED_WIDTH = 332;
export const DAY_INDICATOR_WIDTH = 10;
export const YEAR_PLAN_CARD_BORDER = '1px solid lightgrey';

export const DottedLine = () => (
  <hr
    style={{
      border: 'none',
      borderTop: '1px dashed black',
      width: 'inherit',
    }}
  />
);
