import { Box, BoxProps } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { SubjectColorDefinition } from '../hooks/useSubjectColor';
import { LESSON_PLAN_HEADER } from '../utils/z-index';

export const SubjectColorBox = styled(
  ({
    subjectColor,
    ...otherProps
  }: BoxProps & { subjectColor: SubjectColorDefinition }) => (
    <Box {...otherProps} />
  )
)`
  border-left: 13px solid ${(props) => props.subjectColor.primaryColor};
  background-color: ${(props) => props.subjectColor.secondaryColor};
  padding: 20px;
  z-index: ${LESSON_PLAN_HEADER};
`;
