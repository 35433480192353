import {
  Autocomplete,
  CircularProgress,
  createFilterOptions,
  FormControl,
  TextField,
} from '@mui/material';
import * as React from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { Book, BOOKS_QUERY_KEY, postBook } from '../api/booksapi';

const filter = createFilterOptions<Book>();

export const BookSelector = ({
  grade,
  subject,
  books,
  setSelectedBook,
  selectedBook,
  disablePortal = false,
}: {
  grade: string;
  subject: string;
  setSelectedBook: (book: Book | undefined) => void;
  books: Book[];
  selectedBook?: Book;
  disablePortal?: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();
  const addBookMutation = useMutation(postBook, {});
  const intl = useIntl();
  return (
    <FormControl style={{ minWidth: '40%' }}>
      <Autocomplete
        style={{ backgroundColor: 'white' }}
        disablePortal={disablePortal}
        value={selectedBook || null}
        onChange={(event: any, newValue: any) => {
          if (!newValue?.bookId && newValue) {
            setLoading(true);
            addBookMutation
              .mutateAsync({
                title: newValue?.inputValue ?? newValue,
                subject,
                grade,
              } as Book)
              .then((data) => {
                queryClient.invalidateQueries([
                  BOOKS_QUERY_KEY,
                  { grade, subject },
                ]);
                setSelectedBook({
                  bookId: data.bookId,
                  title: newValue.inputValue ?? newValue,
                  subject,
                  grade,
                });
                enqueueSnackbar('New book saved successfully', {
                  variant: 'success',
                });
                setLoading(false);
              })
              .catch(() => {
                enqueueSnackbar('Something went wrong when saving new book', {
                  variant: 'error',
                });
                setSelectedBook(undefined);
                setLoading(false);
              });
          } else if (newValue) {
            setSelectedBook({
              bookId: newValue.bookId,
              title: newValue.title,
              subject,
              grade,
            });
          } else {
            setSelectedBook(undefined);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const { inputValue } = params;
          const isExisting = options.some(
            (option) => inputValue === option.title
          );
          if (inputValue !== '' && !isExisting) {
            filtered.push({
              inputValue,
              title: `${intl.formatMessage({
                id: 'bookSelector.addNewBook.prefix',
              })} "${inputValue}"`,
            } as any as Book);
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        id={`bookSelector${Math.random()}`}
        options={books}
        getOptionLabel={(option: any) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.title;
        }}
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        sx={{ maxWidth: 400, minWidth: 100 }}
        freeSolo
        renderInput={(params) => (
          <>
            {!loading && (
              <TextField
                {...params}
                label={intl.formatMessage({ id: 'bookSelector.label' })}
              />
            )}
            {loading && <CircularProgress />}
          </>
        )}
      />
    </FormControl>
  );
};
