import { Stack } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent, {
  timelineContentClasses,
} from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { useIntl } from 'react-intl';
import { CenterLoader } from '../../../components/CenterLoader';
import { useAuth } from '../../../utils/auth/useAuth';
import { TimeLineActivityItem } from './TimeLineActivityItem';
import { isLinkItem } from '../../ops/components/types/types';
import {
  EnhancedLessonItem,
  getActivitiesAndNotesFromTopic,
} from '../../../utils/getActivitiesAndNotesFromTopic';
import { TimeLineNoteItem } from './TimeLineNoteItem';
import { useOps } from '../../../hooks/useOps';
import { Topic } from '../../../api/topicapi';
import { useTopicMutation } from '../../../hooks/useTopicMutation';
import {
  moveItemInActivityOrder,
  removeItemFromActivityOrder,
} from '../../../utils/activityOrderUtil';
import { contentState } from '../../../App/state';

const TIMELINE_DOT_CONNECTOR_STYLE = { backgroundColor: 'black' };

export const LessonPlanStaticTimeline = ({
  topic,
  highlightColor,
  showCurriculumContent,
}: {
  topic: Topic;
  highlightColor: string;
  showCurriculumContent: boolean;
}) => {
  const confirm = useConfirm();
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();
  const { user } = useAuth();
  const { ops } = useOps();
  const [highlighted, setHighlighted] = useState<string | null>(null);
  const [noteHighlighted, setHighlightedNote] = useState<string | null>(null);
  const { updateMutation } = useTopicMutation({
    groupId: topic.groupId,
  });
  const [_, setContentViewState] = useRecoilState(contentState);

  if (!ops) {
    return <CenterLoader />;
  }

  const itemClick = (id: string) => {
    setHighlighted(highlighted === id ? null : id);
  };

  const noteClick = (id: string) => {
    setHighlightedNote(noteHighlighted === id ? null : id);
  };

  const linksAndNotes = getActivitiesAndNotesFromTopic(
    topic,
    user?.role !== 'TEACHER'
  );

  const buildActionMenu = (linkOrNote: EnhancedLessonItem, isLink: boolean) => [
    {
      key: 'moveToHomework',
      children: 'Move to Homework',
      onClick: (evt: any) => {
        evt.stopPropagation();
        const newActivityOrder = moveItemInActivityOrder(
          topic.activityOrder,
          'homework',
          0,
          linkOrNote
        );
        updateMutation.mutateAsync({
          ...topic,
          activityOrder: newActivityOrder,
        });
        setContentViewState(
          (curVal) =>
            ({
              ...curVal,
              topic: {
                ...curVal?.topic,
                activityOrder: newActivityOrder,
              },
            } as any)
        );
      },
    },
    {
      key: 'delete',
      onClick: (evt: any) => {
        evt.stopPropagation();
        confirm({
          description: 'Are you sure you want to delete this item?',
        })
          .then(() => {
            const newActivityOrder = removeItemFromActivityOrder(
              topic.activityOrder,
              linkOrNote.order.type,
              linkOrNote.order.orderNumber
            );
            updateMutation.mutateAsync({
              ...topic,
              activityOrder: newActivityOrder,
            });
            setContentViewState(
              (curVal) =>
                ({
                  ...curVal,
                  topic: {
                    ...curVal?.topic,
                    activityOrder: newActivityOrder,
                  },
                } as any)
            );
          })
          .then(() => {
            enqueueSnackbar('Link removed', { variant: 'success' });
          })
          .catch(() => {
            enqueueSnackbar('Failed to remove link', { variant: 'error' });
          });
      },
      children: intl.formatMessage({
        id: 'noteOrActivity.remove',
      }),
    },
  ];

  return (
    <Stack>
      <Timeline
        sx={{
          [`& .${timelineContentClasses.root}`]: {
            flex: 100,
          },
        }}
      >
        {linksAndNotes.map((linkorNote) => {
          if (isLinkItem(linkorNote)) {
            const isHighlighted = highlighted === linkorNote.linkId;
            const isReduced =
              (noteHighlighted !== null || highlighted !== null) &&
              !isHighlighted;
            return (
              <TimelineItem key={linkorNote.linkId}>
                <TimelineSeparator>
                  <TimelineDot style={TIMELINE_DOT_CONNECTOR_STYLE} />
                  <TimelineConnector style={TIMELINE_DOT_CONNECTOR_STYLE} />
                </TimelineSeparator>
                <TimelineContent
                  key="content-initiation"
                  style={{
                    fontFamily: 'RobotoRegular',
                    cursor: 'pointer',
                    opacity: isReduced ? 0.25 : 1,
                  }}
                  onClick={() => itemClick(linkorNote.linkId)}
                >
                  <TimeLineActivityItem
                    {...linkorNote}
                    allTasks={showCurriculumContent ? topic.tasks : undefined}
                    isHighlighted={isHighlighted}
                    highlightColor={highlightColor}
                    actionMenuItems={buildActionMenu(linkorNote, true)}
                  />
                </TimelineContent>
              </TimelineItem>
            );
          }
          const noteData =
            linkorNote.name +
            linkorNote.description +
            linkorNote.showToStudents;
          const isHighlighted = noteHighlighted === noteData;
          const isReduced =
            (noteHighlighted !== null || highlighted !== null) &&
            !isHighlighted;
          return (
            <TimelineItem key={noteData}>
              <TimelineSeparator>
                <TimelineDot style={TIMELINE_DOT_CONNECTOR_STYLE} />
                <TimelineConnector style={TIMELINE_DOT_CONNECTOR_STYLE} />
              </TimelineSeparator>
              <TimelineContent
                key="content-initiation"
                style={{
                  fontFamily: 'RobotoRegular',
                  cursor: 'pointer',
                  opacity: isReduced ? 0.25 : 1,
                }}
                onClick={() => noteClick(noteData)}
              >
                <TimeLineNoteItem
                  name={linkorNote.name}
                  description={linkorNote.description}
                  showToStudents={linkorNote.showToStudents}
                  isHighlighted={isHighlighted}
                  highlightColor={highlightColor}
                  actionMenuItems={buildActionMenu(linkorNote, false)}
                />
              </TimelineContent>
            </TimelineItem>
          );
        })}
      </Timeline>
    </Stack>
  );
};
