import { Typography } from '@mui/material';
import React from 'react';

export const GroupStudentsSummary = ({
  existingStudentEmails,
  allStudentEmails,
}: {
  existingStudentEmails: number;
  allStudentEmails: number;
}) => (
  <>
    <Typography variant="body2" style={{ color: 'grey' }}>
      {existingStudentEmails} students added
    </Typography>
    <Typography variant="body2" style={{ color: 'orange' }}>
      {allStudentEmails - existingStudentEmails} students invited
    </Typography>
  </>
);
