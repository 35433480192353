import { Box, Typography } from '@mui/material';
import React from 'react';
import { subjectShortForm } from '../../utils/subjectShortForm';

interface Props {
  subjectAbbreviation: string;
  grade: string | number;
  onClick?: () => void;
}

export const SubjectCardHeaderBox = ({
  subjectAbbreviation,
  grade,
  onClick,
}: Props) => (
  <Box
    bgcolor="primary.main"
    color="white"
    p={2}
    style={{
      display: 'flex',
      justifyContent: 'center',
      borderRadius: '5%',
      alignItems: 'center',
    }}
    onClick={onClick}
  >
    <Typography style={{ whiteSpace: 'nowrap' }} variant="body2">
      {subjectShortForm(subjectAbbreviation, grade)}
    </Typography>
  </Box>
);
