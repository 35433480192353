import { Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';
import { THEME_MAIN_COLOR } from '../../../theme';

const Ball = (color = 'lightgrey') => styled.div`
  border: 1px solid ${color};
  background-color: ${color};
  border-radius: 50%;
  min-height: 24px;
  min-width: 24px;
  max-height: 24px;
  max-width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const HeaderBall = Ball();

const BlueHeaderBall = Ball(THEME_MAIN_COLOR);

export const Header = ({
  header,
  number,
  checkMark,
}: {
  header: string;
  number: string;
  checkMark: boolean;
}) => (
  <div style={{ display: 'flex', alignItems: 'center' }}>
    {!checkMark ? (
      <HeaderBall>
        <Typography variant="body2" style={{ color: '#FFFFFF' }}>
          {number}
        </Typography>
      </HeaderBall>
    ) : (
      <BlueHeaderBall>
        <Check style={{ fontSize: '16px', color: '#FFFFFF' }} />
      </BlueHeaderBall>
    )}

    <Typography variant="body2">
      <b>{header}</b>
    </Typography>
  </div>
);
