import React from 'react';
import { Box, Stack } from '@mui/material';
import { format, parseISO } from 'date-fns';

import { useBreakpoints } from '../hooks/useBreakpoints';

export const LessonInfo = (props: {
  startAt?: Date | string;
  endAt?: Date | string;
  group: string;
  subject: string;
}) => {
  let day = 'Date to be set';
  let hours = 'Timetable not set';

  if (typeof props.startAt === 'string') {
    day = format(parseISO(props.startAt), 'EEEE, MMM dd');
  } else if (props.startAt instanceof Date) {
    day = format(props.startAt, 'EEEE, MMM dd');
  }

  if (props.startAt instanceof Date && props.endAt instanceof Date) {
    hours = `${format(props.startAt, 'HH:mm')} - ${format(
      props.endAt,
      'HH:mm'
    )}`;
  } else if (
    typeof props.startAt === 'string' &&
    typeof props.endAt === 'string'
  ) {
    hours = `${format(parseISO(props.startAt), 'HH:mm')} - ${format(
      parseISO(props.endAt),
      'HH:mm'
    )}`;
  }

  const { isPhone, usePanels } = useBreakpoints();
  return (
    <Stack gap="1em" fontSize={24} lineHeight={1.25}>
      <Stack
        style={{
          flexFlow: !usePanels ? 'row' : '',
          columnGap: !usePanels ? '1em' : '',
        }}
      >
        <Box fontFamily="RobotoRegular">{day}</Box>
        <Box fontFamily="RobotoMedium">{hours}</Box>
      </Stack>
      <Stack
        style={{
          flexFlow: !usePanels ? 'row' : '',
          columnGap: !usePanels ? '1em' : '',
        }}
      >
        <Box fontFamily="RobotoRegular">{props.group}</Box>
        <Box fontFamily="RobotoMedium">{props.subject}</Box>
      </Stack>
    </Stack>
  );
};
